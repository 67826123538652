.custom-legend-item {
    display: flex;
    /* align-items: center; */
    margin-bottom: 21px;
    justify-content: space-between;
    grid-gap: 10px;
  }
  .custom-legend-item:last-child{
    margin-bottom: 0;
  }
  
  .custom-legend-marker {
    width: 17px;
    min-width: 17px;
    height: 17px;
    display: inline-block;
  }
  
  .custom-legend-text,
  .custom-llegend-value {
    color: #282828;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 15.86px;
  }
  .outer-piechart-report {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    grid-gap: 20px;
  }
  .custom-legend-title{
    color: #282828;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.86px;
    margin-bottom: 14px;
  }
  .custom-legend-value {
    display: flex;
    /* align-items: center; */
    grid-gap: 16px;
}

