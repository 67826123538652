.graph-main-outer-time tspan {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #282828;
    text-transform: capitalize;
}

.graph-main-outer-time line.recharts-cartesian-axis-line {
    display: none;
}

.graph-main-outer-time line.recharts-cartesian-axis-tick-line {
    display: none;
}

.graph-main-outer-time .chart-container {
    border-bottom: 0.5px solid #D8DDE1;
}

.graph-main-outer-time .top-inner-graph {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.graph-main-outer-time {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 14px 22px 18px 18px;
    width: 100%;
    height: 100%;
}

.graph-main-outer-time .graph-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #454F59;
    padding-left: 6px;
}

.graph-main-outer-time g.recharts-cartesian-grid-vertical line {
    display: none;
}

.graph-main-outer-time .recharts-wrapper {
    width: 100% !important;
    max-width: 100% !important;

}

.graph-main-outer-time svg.recharts-surface {
    width: 100% !important;
    max-width: 100% !important;


}

g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis g.recharts-layer.recharts-cartesian-axis-tick tspan {
    fill: #282828;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 12.2px;
}

g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis g.recharts-layer.recharts-cartesian-axis-tick tspan {
    fill: #6A7988;
    text-align: right;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 300;
    line-height: 12.2px;
}
@media screen and (min-width: 1100px) {
    g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis g.recharts-layer.recharts-cartesian-axis-tick tspan {
        font-size: 12px;
    }
    
    g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis g.recharts-layer.recharts-cartesian-axis-tick tspan {
        font-size: 12px;
    }
}
@media screen and (max-width: 991px) {
    .graph-main-outer-time .graph-overview {
        display: block;
        padding-top: 20px;


    }

    .graph-main-outer-time .overview-left {
        width: 100%;
    }

    /* .content-outer {
    padding-right: 15px;
    padding-left: 15px;
    width: 100vw;
    margin-top: 50px;

} */
    .graph-main-outer-time {
        width: 100%;
    }

    .graph-main-outer-time .main-menu-bottom ul li a {
        /* display: none; */
    }

    .graph-main-outer-time {
        margin-top: 16px;
    }
}