.latest-project-outer{
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 22px 25px 22px;
}
.table-head-outer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
}
.table-head{
    color: #454F59;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
}
button.view-all-button{
    color: #405BFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
    background: none;
    padding: 0;
}
.p-datatable .p-datatable-thead>tr>th:last-child{
    padding-right: 0;
}
tbody.p-datatable-tbody>tr td:last-child{
    padding-right: 0;
}
.p-datatable .p-datatable-thead>tr>th{
    padding: 11px 0 12px;
    white-space: nowrap;
}
button.cta-button {
    border-radius: 8px;
    background: #6479F8;
    color: #FFF;
    font-size: 15px;
    font-weight: 300;
    line-height: 122%;
    letter-spacing: 1px;
    padding: 6px 15px 7px 14px;
}
tbody.p-datatable-tbody>tr td{
    padding: 23px 0 27px;
    white-space: nowrap;
}

.billable-button {
    border-radius: 100px;
    border: 0.2px solid #516D4D;
    background: #D5F3D8;
    color: #70AD69;
    font-size: 14px;
    font-weight: 500;
    line-height:18px;
    letter-spacing: 0.4px;
    padding: 7px 12px;
    width: fit-content;
}

.billable-button.clicked {
    border-radius: 100px;
    border: 0.2px solid #454F59;
    background: #E8E8E8;
    color: #78828D;
}

.billable-button.clicked::before {
    content: "NO";
}

.billable-button::before {
    content: "Yes";
}

.status-paid {
    border-radius: 100px;
    border: 0.2px solid #516D4D;
    background: #D5F3D8;
    color: #70AD69;
    font-size: 14px;
    font-weight: 500;
    line-height:18px;
    letter-spacing: 0.4px;
    padding: 7px 12px;
    width: fit-content;
  }
  
  .status-pending {
    border-radius: 100px;
    border: 0.2px solid #695437;
    background: #FDDFB5;
    color: #E39C4F;
    font-size: 14px;
    font-weight: 500;
    line-height:18px;
    letter-spacing: 0.4px;
    padding: 7px 12px;
    width: fit-content;
  }
  .latest-project-outer tbody.p-datatable-tbody>tr td:nth-child(4),
  .latest-project-outer tbody.p-datatable-tbody>tr td:nth-child(5){
    font-weight: 300 !important;
  }


@media screen and (max-width: 1115px) {
    .recent-invoice-inner {
        width: max-content;
    }
    .p-column-header-content {
        width: max-content;
    }
    .recent-invoices {
        overflow: scroll;
    }

    .p-datatable .p-datatable-thead>tr>th {
        width: 163px !important;
    }

    .recent-invoices {
        margin-bottom: 47px;
    }

    tbody.p-datatable-tbody>tr:last-child td {
        border-bottom: none;
    }

    .recent-invoice-inner {
        padding: 27px 16px;
    }
    .p-datatable-wrapper{
        overflow: auto;
    }
    .p-column-header-content {
        width: max-content;
    }
}

@media screen and (max-width: 1300px) {

    .recent-invoices {
        overflow: auto;
    }
}