.number-of-input {
    display: flex;
    align-items: center;
}
.tracker-setting-outer {
    display: flex;
    align-items: center;
    grid-gap: 18px;
}
.number-of-input input {
    border-radius: 7px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    height: 43px;
    width: 58px;
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    color: #282828;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    margin-right: 20px;
    outline: none;
}
.seo-input.api-token-copy textarea {
    padding-top: 30px !important;
    /* height: max-content !important; */
    padding-bottom: 30px !important;
    width: 100% !important;
}
.seo-input.api-token-copy {
    position: relative;
}
.seo-input.api-token-copy button {
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 8px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    padding: 2px 5px !important;
}
.number-of-input .day-count{
    margin-right: 11px;
    color: #405BFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.number-of-input .at {
    margin-right: 12px;
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.number-of-input .lock-time-hour{
    color: #405BFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.security-outer.noti-outer.seo-settings .security-inner.noti-enable {
    flex-wrap: wrap;
    border-bottom: none;
    margin-bottom: 42px;
    padding-bottom: 0;
}
.security-outer.noti-outer.seo-settings .security-inner.noti-enable.noti-head-noti {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 24px;
    margin-bottom: 32px;
}
.security-outer.noti-outer.seo-settings .security-inner.noti-enable:last-child{
    margin-bottom: 0;
}
.security-outer.noti-outer.seo-settings span.custom-switch-description,
.security-outer.noti-outer.seo-settings .seo-input{
    width: 100%;
}
.general-setting-option .security-outer.noti-outer {
    padding-top: 24px;
    padding-bottom: 26px;
}
.general-setting-option .security-outer.noti-outer.tracker-settings{
    padding-bottom: 37px;
}
.general-setting-option .security-outer.noti-outer.seo-settings{
    padding-bottom: 47px;
}
@media screen and (max-width: 850px) {
    .security-outer.noti-outer.tracker-settings .security-inner.noti-enable {
        flex-wrap: wrap;
    }
    .tracker-setting-outer {
        flex-wrap: wrap;
        width: 100%;
    }
}
@media screen and (max-width: 535px) {
    .security-outer.noti-outer.tracker-settings .custom-dropdown,
    .security-outer.noti-outer.tracker-settings .custom-dropdown-user-new-outer.custom-dropdown-select,
    .security-outer.noti-outer.tracker-settings .custom-dropdown-user-new-outer.custom-dropdown-select input{
        width: 100% !important;
    }
}