.time-project-table {
    margin-top: 44px;
    max-width: 100000px;
    overflow: auto;
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
}

.project-tba-show {
    /* margin-top: -40px !important; */
}
.show-count {
    display: flex;
    justify-content: flex-end;
}
.time-project-table table {
    width: 100%;
    border-collapse: collapse;
    max-width: 100000px;
    overflow: auto;
    white-space: nowrap;
}

.time-project-table th,
.time-project-table td {
    padding: 20px;
    text-align: left;
    white-space: nowrap;
}

.time-project-table th {
    background-color: #f5f6f8;
    font-weight: 500;
    font-size: 17px;
    line-height: 20.74px;
    color: #6A7988;
    padding-top: 11px;
    padding-bottom: 12px;
}

.time-project-table thead th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    padding-left: 22px;
}

.time-project-table tr td:first-child {
    padding-left: 22px;
}

.time-project-table tr td:last-child {
    padding-right: 25px;
    /* float: right; */
}

.time-project-table thead th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    padding-right: 8px;
}

.time-project-table td {
    border-bottom: 0.5px solid #d8dde1;
    padding-top: 19px;
    padding-bottom: 22px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #282828;
    position: relative;
}

.time-project-table img {
    vertical-align: middle;
    margin-left: 5px;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.time-project-table tbody tr a {
    font-weight: 300;
    font-size: 15px;
    line-height: 18.3px;
    letter-spacing: 1px;
    color: #FFFFFF;
    width: 76px;
    height: 31px;
    background: #6479F8;
    border-radius: 8px;
    float: right;
}

.time-project-table tbody tr a {
    font-weight: 300;
    font-size: 15px;
    line-height: 18.3px;
    letter-spacing: 1px;
    color: #FFFFFF;
    width: 76px;
    height: 31px;
    background: #6479F8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

tbody tr td .yes,
.billable {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #7CC273;
    background: #D5F3D8;
    border: 0.2px solid #7CC273;
    border-radius: 100px;
    width: 45px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
}
.invoice-filter-inner-section.no-flter-all .custom-dropdown1,
.invoice-filter-inner-section.no-flter-all input {
    font-weight: 300;
}
.invoice-filter-inner-section.no-flter-all input#date-range {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}
.invoice-filter-inner-section.no-flter-all input#date-range::placeholder {
    text-transform: capitalize;
    color: #78828D;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    letter-spacing: 0.5px;
    margin-top: 23px;
}

tbody tr td .no,
.non-billable {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #96A8BA;
    background: #EAEAEA;
    border: 0.2px solid #6A7988;
    border-radius: 100px;
    width: 45px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
}

.pagination {
    padding-top: 28px;
    padding-bottom: 42px;
}

.pagination ul {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0;
}

.pagination li a,
li.pagination-item {
    cursor: pointer;
    border: 1px solid #6A7988;
    color: #6A7988;
    padding: 5px 10px;
    margin: 0 5.5px;
    border-radius: 7px;
}

.pagination li.active a,
li.pagination-item.active {
    background-color: #6479F8;
    color: #fff;
}

.back a,
.next a,
li.pagination-item.back,
li.pagination-item.next {
    border: 0 !important;
    font-weight: 300;
    font-size: 15px;
    line-height: 18.3px;
    letter-spacing: 1px;
    color: #6479F8 !important;
    position: relative;
}

.back:before {
    content: "";
    position: absolute;
    background-image: url('../../../images/icons/right-ar.svg');
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
    left: -6px;
    top: 10px;
    transform: rotate(180deg);
}

.next:after {
    content: "";
    position: absolute;
    background-image: url('../../../images/icons/right-ar.svg');
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
    right: -6px;
    top: 10px;
}

.pagination li.pagination-item.ellipsis a {
    border: 0 !important;
    padding-bottom: 0px;
}

.project-detail-modal p.form-input {
    border: none !important;
    text-align: left !important;
    padding: 0 !important;
    height: auto !important;
    margin-bottom: 7px !important;
    color: #282828 !important;
    font-size: 19px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 23.18px !important;
    letter-spacing: 0.5px !important;
}

.project-detail-modal .form-field-client {
    display: flex;
    flex-direction: column;
}

.project-detail-modal .form-field-client p.form-textarea,
.modal-sprint-detail p.form-textarea {
    text-align: left !important;
    color: #282828;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    margin-bottom: 7px;
}

.project-detail-modal p.form-input.form-input-sprint {
    border-radius: 10px !important;
    border: 0.2px solid rgba(106, 121, 136, 0.70) !important;
    background: #FFF !important;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    height: 44px !important;
    display: flex;
    align-items: center;
    padding-left: 23px !important;
    padding-right: 25px !important;
    position: relative !important;
    cursor: pointer;
    margin-bottom: 10px Im !important;
}

.project-detail-modal p.form-input.form-input-sprint:last-child {
    margin-bottom: 0px !important;
}

.project-detail-modal p.form-input.form-input-sprint::after {
    content: "";
    background: url('../../../images/icons/right-blue-arrow.svg') no-repeat;
    width: 7px;
    height: 12px;
    position: absolute;
    right: 16px;
    background-size: contain;
}

.modal-client-head.back-to-projec-top {
    text-align: left;
}

.back-to-project button {
    display: flex !important;
    align-items: center !important;
    color: #282828 !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 122% !important;
    letter-spacing: 0.5px !important;
    grid-gap: 14px !important;
    margin-bottom: 34px !important;
    background: transparent !important;
    border: none !important;
    text-decoration: none !important;
}

.back-to-project button:hover {
    opacity: 0.8;
}

.back-to-project button img {
    transform: rotate(180deg);
}

button.submit-client.sprint-detail-editbuttton {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 11.76px;
    margin-top: 44px !important;
}

.modal-sprint-detail input.rs-picker-toggle-textbox {
    color: #282828;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 122%;
    letter-spacing: 0.5px;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal .sprint-btn-outer{
    margin-top: 0 !important;
}
.sprint-btn-outer {
    display: flex;
    grid-gap: 14px;
    margin-top: 40px;
}

.sprint-btn-outer button {
    margin-top: 0 !important;
}

.sprint-btn-outer button.submit-client.cancel-sprint {
    border-radius: 6px !important;
    border: 0.2px solid #282828;
    background: #F5F6F8 !important;
    color: #282828 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 17.08px !important;
    letter-spacing: 0.5px !important;
    grid-gap: 10px;
}

.project-detail-modal .form-field-client.radio-sec {
    display: flex;
    align-items: center;
    grid-gap: 14px;
    flex-direction: row !important;
    margin-top: 43px;
}

.css-13cymwt-control:hover {
    border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
    box-shadow: none !important;
    border-color: rgba(106, 121, 136, 0.7) !important;
}

.project-detail-modal .form-field-client-outer .form-field-client textarea {
    height: 111px;
}

.form-textarea.project-manager-outer {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    margin-top: 10px;
}

@media only screen and (max-width: 540px) and (min-width: 220px) {}

@media only screen and (max-width: 767px) {
    .pagination ul {
        justify-content: center;
    }
}

/* pagination  */
/* .pagination {
    display: flex;
    justify-content: end;
    list-style: none;
    padding: 0;
   padding-top: 27px;
   padding-bottom: 57px;
  }
  
  .pagination li {
    display: inline-block;
    margin-right: 4px;
  }
  ul.pagination.maininteampag .next:after {
    display: none;
}
li.previous a{
    border: none;
}
.pagination li.pagination-item.active {
    color: #fff;
}
.pagination li.back {
    color: #6479F8;
    font-weight: 300;
    font-size: 15px;
}
.maininteampag li.next {
    color: #6479F8;
    font-weight: 300;
    font-size: 15px;
} */