.time-client-client .time-client-top {
display: flex;
justify-content: space-between;
align-items: center;
padding-top: 38px;
padding-bottom: 30px;
}

.time-client-client .button-sec-invoice {
display: flex;
padding-top: 0;
margin-bottom: 0px;
grid-gap: 22px;
}

.time-client-client .search-container input {
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background: url('../../../images/icons/search-icon.svg') no-repeat;
background-position: left 15px center;
cursor: pointer;
padding-left: 58px;
background-color: #EAEBEC;
border-radius: 10px;
width: 318px;
height: 45px;
border: none;
font-weight: 300;
font-size: 19px;
line-height: 23.18px;
letter-spacing: 0.5px;
color: #6A7988;
padding-right: 15px;
}

.time-client-client {
padding-left: 58px;
padding-right: 54px;
}

.time-client-client a.new-client,
.button-sec-invoice a.new-client{
min-width: 141px;
height: 44px;
background: #6479F8;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: center;
font-weight: 500;
font-size: 14px;
line-height: 17.08px;
letter-spacing: 0.5px;
color: #FFFFFF;
grid-gap: 9px;
padding: 10px;
}

.modal-client-head {
text-align: center;
width: 100%;
}

.modal-client-head h2 {
font-weight: 500;
font-size: 22px;
line-height: 22.44px;
color: #282828;
text-align: center !important;
display: block;
margin-bottom: 36px;
}

.form-field-client-outer .form-field-client {
margin-bottom: 20px;
}

.form-field-client-outer .form-field-client .form-label {
font-weight: 300;
font-size: 17px;
line-height: 20.74px;
color: #6A7988;
margin-bottom: 9px;
display: inline-block;
}

.form-field-client-outer .form-field-client .form-input,
.form-field-client-outer .form-field-client .form-select {
height: 43px;
}

.form-field-client-outer .form-field-client .form-input,
.form-field-client-outer .form-field-client .form-select,
.form-field-client-outer .form-field-client textarea {
background: #FFFFFF;
border: 0.2px solid rgba(106, 121, 136, 0.7);
border-radius: 7px;
font-weight: 300;
font-size: 19px;
line-height: 23.18px;
letter-spacing: 0.5px;
color: #282828;
padding: 7px 12px;
width: 100%;
box-sizing: border-box;
white-space: nowrap;
overflow-x: auto;
display: flex;
align-items: center;
}
.form-field-client-outer .form-field-client textarea {
padding: 8px 12px !important;
outline: none;
}
.form-field-client-outer .form-field-client textarea:focus{
border-color: #4a90e2;
}
.form-field-client-outer .form-field-client .form-select {
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background: url('../../../images//icons/down-arrow.svg') no-repeat;
background-position: right 24px center;
cursor: pointer;
padding-right: 30px;
}

.form-field-client-outer .form-field-client textarea {
height: 80px;
}

.form-field-client-outer .form-field-client .form-input:focus,
.form-field-client-outer .form-field-client .form-select:focus {
outline: none;
border-color: #4a90e2;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal {
width: auto !important;
height: fit-content !important;
max-height: 68% !important;
/* overflow: auto; */
padding-top: 35px;
padding-left: 24px !important;
padding-right: 24px !important;
}

/* .form-field-client-outer {
max-height: 60vh;
overflow: auto;
} */
/* .ReactModal__Content.ReactModal__Content--after-open.client-modal .modal{
padding-left: 24px !important;
  padding-right: 24px !important;
} */
.ReactModal__Content.ReactModal__Content--after-open.client-modal .tracker-detail-dropdown .custom-dropdown,
.form-field-client-outer .cursor .custom-dropdown,
.custom-dropdown-new {
/* ... your existing styles */
scroll-behavior: smooth;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal::-webkit-scrollbar,
.form-field-client-outer::-webkit-scrollbar,
.tracker-detail-dropdown .custom-dropdown::-webkit-scrollbar,
.form-field-client-outer .cursor .custom-dropdown::-webkit-scrollbar,
.custom-dropdown-new::-webkit-scrollbar {
width: 2px;
/* transition: width 1s ease; */
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal::-webkit-scrollbar-thumb,
.form-field-client-outer::-webkit-scrollbar-thumb,
.tracker-detail-dropdown .custom-dropdown::-webkit-scrollbar-thumb,
.form-field-client-outer .cursor .custom-dropdown::-webkit-scrollbar-thumb,
.custom-dropdown-new::-webkit-scrollbar-thumb {
border: 4px solid rgba(0, 0, 0, 0);
background-clip: padding-box;
border-radius: 9999px;
background-color: #AAAAAA;
transition: background-color 0.3s ease;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal::-webkit-scrollbar,
.form-field-client-outer::-webkit-scrollbar,
.tracker-detail-dropdown .custom-dropdown::-webkit-scrollbar,
.form-field-client-outer .cursor .custom-dropdown::-webkit-scrollbar,
.custom-dropdown-new::-webkit-scrollbar {
width: 12px;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal::-webkit-scrollbar-thumb,
.form-field-client-outer::-webkit-scrollbar-thumb,
.tracker-detail-dropdown .custom-dropdown::-webkit-scrollbar-thumb,
.form-field-client-outer .cursor .custom-dropdown::-webkit-scrollbar-thumb,
.custom-dropdown-new::-webkit-scrollbar-thumb {
border: 4px solid rgba(0, 0, 0, 0);
background-clip: padding-box;
padding-right: 0px;
border-radius: 9999px;
background-color: #AAAAAA;
}

.file-input-wrapper {
position: relative;
display: flex;
flex-wrap: wrap;
border: 0.2px solid rgba(106, 121, 136, 0.7);
border-radius: 7px;
}

.file-input-wrapper input[type="file"] {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0;
cursor: pointer;
}

.file-input-label {
font-weight: 300;
font-size: 14px;
line-height: 17.08px;
letter-spacing: 0.5px;
color: #282828;
border-top-right-radius: 4px;
border-bottom-right-radius: 4px;
background: #F3F3F5;
border-radius: 0px 7px 7px 0px;
cursor: pointer;
width: 103px;
display: flex;
grid-gap: 11.5px;
align-items: center;
justify-content: center;
height: 43px;
}

.file-input-label:hover {
background-color: #d5d5d5;
}

.file-input-wrapper input.form-input.form-input-file {
width: calc(100% - 103px) !important;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border: 0;
}

.form-field-client {
width: 100%;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal button {
width: 100%;
background: #6479F8;
border-radius: 6px;
font-weight: 500;
font-size: 14px;
line-height: 17.08px;
letter-spacing: 0.5px;
color: #FFFFFF;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
grid-gap: 10px;
}

button.submit-client {
margin-top: 13px !important;
}

.radio-container {
display: flex;
gap: 10px;
}

.radio-option {
display: flex;
align-items: center;
}

.radio-option input[type="radio"] {
display: none;
}

.radio-option label {
font-weight: 500;
font-size: 14px;
line-height: 17.08px;
display: flex;
align-items: center;
justify-content: center;
letter-spacing: 0.5px;
background: #FFFFFF;
box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
border-radius: 10px;
width: 73px;
height: 44px;
cursor: pointer;
}

.radio-option label img {
margin-left: 5px;
}

/* Additional Styles (Optional) */

.radio-option.yes input[type="radio"]:checked + label {
border-radius: 8px;
border: 1px solid #70AD69;
background: #D5F3D8;
color: #7CC273;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17.08px;
letter-spacing: 0.5px;
}


.radio-option.no input[type="radio"]:checked + label {
border-radius: 8px;
border: 1px solid #6A7988;
background: rgba(106, 121, 136, 0.30);
}


.radio-option.yes label {
color: #7CC273;
grid-gap: 5px;
border: 1px solid #7CC273;
}

.radio-option.no label {
color: #6A7988;
grid-gap: 5px;
border: 1px solid #6A7988;
}

.form-field-client.radio-sec {
display: flex;
align-items: center;
grid-gap: 14px;
}

.form-field-client.radio-sec label.form-label {
margin-bottom: 0px;
}

.project-calender {
width: 1000%;
}

.project-calender .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default,
.project-calender .rs-picker-toggle.rs-btn.rs-btn-default {
width: 100% !important;
}

.project-calender svg.rs-picker-toggle-caret.rs-icon {
left: auto !important;
right: 11px !important;
}

.project-calender .rs-picker-toggle.rs-btn.rs-btn-default {
width: 100% !important;
}

.project-calender input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
padding-left: 12px !important;
}

.project-calender .rs-picker-toggle-textbox {
padding-left: 12px !important;
}

.project-calender .rdrDateRangePickerWrapper {
position: relative;
}

.project-calender .rdrDateRangePickerWrapper .rdrStaticRangePicker {
position: absolute;
top: 100%;
left: 0;
z-index: 999;
}

.modal-client-head button {
color: #405BFF !important;
text-align: center !important;
font-size: 14px !important;
background: transparent !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 122%;
letter-spacing: 0.5px !important;
text-decoration-line: underline !important;
}

.form-input.readonly {
border-radius: 7px !important;
border: 0.2px solid rgba(106, 121, 136, 0.70) !important;
background: #FAFAFA !important;
color: #282828 !important;
cursor: no-drop;
}

.modal-client-head.skip-sec button {
height: auto !important;
}

.modal-client-head.create-sprint-project h2 {
margin-bottom: 16px;
}

@media only screen and (max-width: 720px) and (min-width:480px) {
.time-client-client .button-sec-invoice {
  width: 100%;
}

.time-client-client .time-client-top {
  flex-wrap: wrap;
  grid-gap: 30px;
}
}

@media only screen and (max-width: 479px) {
.time-client-client .time-client-top {
  flex-direction: column;
  grid-gap: 30px;
}

.time-client-client .button-sec-invoice {
  justify-content: center;
  flex-wrap: wrap;
  width: 100% !important;
}
.time-client-client a.new-client, .button-sec-invoice a.new-client {
  width: 100%;
}
.modal-invite-main-outter {
  width: 100%;
  display: none;
}

.time-client-client .search-container input {
  width: 100%;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal {
  max-width: 92% !important;
}
}
@media only screen and (max-width: 343px) {

}
@media only screen and (max-width: 1100px) {
.time-client-client {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
}

@media only screen and (max-width: 479px) and (min-width: 220px) {
.ReactModal__Content.ReactModal__Content--after-open.client-modal {
  width: 92% !important;
}
}

@media only screen and (min-width: 992px) {
.ReactModal__Content.ReactModal__Content--after-open.client-modal {
  transform: translate(-20%, -50%) !important;
}

}

@media only screen and (min-width: 450px) {

.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal,
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal {
  width: 426px !important;
}
}