.time-client-outer .time-client-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 38px;
  padding-bottom: 42px;
}

.time-client-outer .button-sec-invoice {
  display: flex;
  padding-top: 0;
  margin-bottom: 0px;
}

.time-client-outer .search-container input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../../images/icons/search-icon.svg') no-repeat;
  background-position: left 15px center;
  cursor: pointer;
  padding-left: 58px;
  background-color: #EAEBEC;
  border-radius: 10px;
  width: 247px;
  height: 45px;
  border: none;
  font-weight: 300;
  font-size: 19px;
  line-height: 23.18px;
  letter-spacing: 0.5px;
  color: #6A7988;
  padding-right: 15px;
}

.time-client-outer {
  padding-left: 58px;
  padding-right: 54px;
}

.time-client-outer a.new-client {
  width: 129px;
  height: 44px;
  background: #6479F8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.08px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  grid-gap: 9px;
  padding: 10px;
}

.modal-client-head {
  text-align: center;
  width: 100%;
}

.modal-client-head h2 {
  font-weight: 500;
  font-size: 22px;
  line-height: 22.44px;
  color: #282828;
  text-align: center !important;
  display: block;
  margin-bottom: 36px;
}

.form-field-client-outer .form-field-client {
  margin-bottom: 20px;
}

.form-field-client-outer .form-field-client .form-label {
  font-weight: 300;
  font-size: 17px;
  line-height: 20.74px;
  color: #6A7988;
  margin-bottom: 9px;
  display: inline-block;
}

.form-field-client-outer .form-field-client .form-input,
.form-field-client-outer .form-field-client .form-select {
  height: 43px;
}

.form-field-client-outer .form-field-client .form-input,
.form-field-client-outer .form-field-client .form-select,
.form-field-client-outer .form-field-client textarea {
  background: #FFFFFF;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 7px;
  font-weight: 300;
  font-size: 19px;
  line-height: 23.18px;
  letter-spacing: 0.5px;
  color: #282828;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
}

.form-field-client-outer .form-field-client .form-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../../images//icons/down-arrow.svg') no-repeat;
  background-position: right 24px center;
  cursor: pointer;
  padding-right: 30px;
}

.form-field-client-outer .form-field-client textarea {
  height: 80px;
}

.form-field-client-outer .form-field-client .form-input:focus,
.form-field-client-outer .form-field-client .form-select:focus {
  outline: none;
  border-color: #4a90e2;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal {
  width: auto !important;
  height: 68% !important;
  overflow: auto;
  padding-top: 15px;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.file-input-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 7px;
}

.file-input-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-input-label {
  font-weight: 300;
  font-size: 14px;
  line-height: 17.08px;
  letter-spacing: 0.5px;
  color: #282828;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #F3F3F5;
  border-radius: 0px 7px 7px 0px;
  cursor: pointer;
  width: 103px;
  display: flex;
  grid-gap: 11.5px;
  align-items: center;
  justify-content: center;
  height: 43px;
}

.file-input-label:hover {
  background-color: #d5d5d5;
}

.file-input-wrapper input.form-input.form-input-file {
  width: calc(100% - 103px) !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
}

.form-field-client {
  width: 100%;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal button {
  width: 100%;
  background: #6479F8;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.08px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  text-align: center;
}

button.submit-client {
  margin-top: 13px !important;
}


@media only screen and (max-width: 479px) and (min-width: 220px) {
  .ReactModal__Content.ReactModal__Content--after-open.client-modal {
    width: 92% !important;
  }
}

@media only screen and (min-width: 992px) {
  .ReactModal__Content.ReactModal__Content--after-open.client-modal {
    transform: translate(-20%, -50%) !important;
  }
}