/* Styles for the checkbox */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
}

/* Hide the default checkbox */
.hidden-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkbox-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 81px;
  background-size: cover;
  border-radius: 10px;
  border: 0.2px solid rgba(106, 121, 136, 0.70);
  background-color: #FFF;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}

/* Add styles to the checkbox when checked */
.hidden-checkbox:checked+.checkbox-logo {
  border: 0.2px solid rgba(106, 121, 136, 0.70);
  background: #F0F3F5;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}

.hidden-checkbox:checked+.checkbox-logo:after {
  content: url('../../../images/icons/blue-arrow.svg');
  position: absolute;
  right: 10px;
  bottom: 7px;
}

.account-bank-outer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.account-bank-inner {
  position: relative;
  width: 100%;
  height: 81px;
  display: grid;

}

.modal.add-bank-account-content .modal-invite-head h2 {
  text-align: center;
  color: #282828;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.44px;
}

.hidden-checkbox:disabled+.checkbox-logo {
  cursor: not-allowed;
}

p.step-head {
  color: #282828 !important;
  font-size: 19px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 23.18px !important;
  letter-spacing: 0.5px !important;
  margin-bottom: 10px !important;
  text-align: left !important;
}

p.step-description {
  color: #282828 !important;
  font-size: 19px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 23.18px !important;
  letter-spacing: 0.5px !important;
  margin-bottom: 36px !important;
  text-align: left !important;
}

.modal.add-bank-account-content .invite-mail-popup-btn {
  width: 100%;
  margin-top: 52px;
}

.modal.add-bank-account-content button {
  width: 100% !important;
}

input.hidden-checkbox {
  left: 0;
  top: 0;
}

.ReactModal__Content.ReactModal__Content--after-open.add-bank-account-modal {
  padding: 20px 28px;
}

.progress-bar {
  margin-top: 17px;
}

.progress-bar progress {
  width: 100%;
  height: 5px;
  border-radius: 100px;
  background: linear-gradient(0deg, #D8DDE1 0%, #D8DDE1 100%), #D8DDE1;
}

.progress-bar progress::-webkit-progress-bar {
  border-radius: 100px;
  background: linear-gradient(0deg, #D8DDE1 0%, #D8DDE1 100%), #D8DDE1;
}

.progress-bar progress::-webkit-progress-value {
  border-radius: 100px;
  background: linear-gradient(0deg, #6479F8 0%, #6479F8 100%), #D8DDE1;
}

.progress-bar progress::-moz-progress-bar {
  border-radius: 100px;
  background: linear-gradient(0deg, #D8DDE1 0%, #D8DDE1 100%), #D8DDE1;
}

.form-step2 {
  margin-bottom: 37px;
}

.form-step2:last-child {
  margin-bottom: 0;
}

.user-outer-overview.add-bank-account .invoice-top {
  padding-top: 53px;
}

.user-outer-overview.add-bank-account .showing-count {
  margin-bottom: 16px;
}

.user-outer-overview .filter-inner.showing-result {
  align-items: center;
}

.modal.timeoff-review.account-detail-inner-sec .form-field-client {
  grid-template-columns: 1fr 1.25fr;
  padding-bottom: 14px;
}

.modal.timeoff-review.account-detail-inner-sec .form-field-client .form-label {
  margin-bottom: 0;
}

button.cta-link.account-payment {
  background: #6479F8;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  display: inline-flex;
  letter-spacing: 1px;
  padding: 6px 15px 7px 14px;
  color: #FFFFFF;
}

.delete-account-details button {
  margin-bottom: 0 !important;
}

.delete-account-details {
  padding-bottom: 15px !important;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

td.blur:hover {
  filter: none;
}

td.blur {
  filter: blur(6px);
  transition: filter 0.3s ease;
}

@media only screen and (max-width: 991px) {
  .add-bank-account .button-sec-invoice {
    display: flex !important;
  }

  .card-outer.card-outer-account {
    background: transparent;
    padding: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
}

@media only screen and (max-width: 667px) {
  .add-bank-account .button-sec-invoice a {
    width: 100% !important;
  }

}

@media only screen and (min-width: 600px) {
  .ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.add-bank-account-modal.add-bank-account-modal-details {
    width: 597px !important;
    max-width: 597px;
  }
}

@media only screen and (max-width: 600px) {
  .modal.timeoff-review.account-detail-inner-sec .form-field-client {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.add-bank-account-modal.add-bank-account-modal-details {
    transform: translate(-27%, -50%) !important;
  }


}