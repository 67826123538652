.overview-item {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 14px 21px 24px 24px;
    position: relative;
}

.overview-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #454F59;
}

.overview-title-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    text-align: left;
}

.overview-icon {
    display: flex;
}

.overview-rice {
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #282828;
    word-break: break-all;
}

.overview-item>a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.overview-title span {
    background: #D1D8FF;
    border-radius: 50%;
    min-width: 24px;
    display: flex;
    justify-content: center;
    margin-right: 8px;
    padding: 10px 15px;

}

@media screen and (max-width: 1300px) and (min-width: 992px) {
    .graph-overview {
        flex-wrap: wrap;
        grid-gap: 30px;
    }

    .overview-left {
        width: 100% !important;
    }

    .graph-main-outer {
        width: 100% !important;
    }
}

@media screen and (max-width: 1500px) and (min-width: 1250px) {
    .very-long-string {
        font-size: 28px;
    }
}

@media screen and (max-width: 1350px) and (min-width: 1301px) {
    .long-string {
        font-size: 28px;
    }
}