.member-main-outter {
  text-align: initial;
  padding-left: 44px;
  padding-top: 35px;
  padding-right: 45px;
}

.custom-dropdown-all {
  display: grid;
}

select#memberTeamSelect {
  background: #ffffff;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 5px;
  height: 43px;
  min-width: 182px;
  color: #78828d;
  font-weight: 300;
  font-size: 17px;
  padding-left: 12px;
  appearance: none;
  background-image: url(../../../../images/icons/down-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-position: calc(100% - 9px) center !important;
}
.member-main-outter span.icon-invoice img {
  margin-right: 0;
}
.table-head-outer span.icon-invoice img {
  margin-right: 0;
}
.table-top-project-client .table-head-outer {
  grid-gap: 20px;
}
.custom-dropdown-all label {
  color: #282828;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 7px;
}

.search-bar-team input {
  border: none;
  color: #6a7988;
  font-weight: 300;
  font-size: 19px;
  background: transparent;
}

.search-bar-team {
  background: #eaebec;
  border-radius: 10px;
  height: 45px;
  max-width: 361px;
  display: flex;
  width: 100%;
  margin-left: 17px;
}

.search-bar-team button {
  background: #eaebec;
  border-radius: 10px;
}

.search-userandmail {
  display: flex;
  align-items: flex-end;
}

/* TimeTrackerTeamMembers.css */

/* Other styles */

.datatable-team-maintb table {
  width: 100%;
  border-collapse: collapse;
}

.datatable-team-maintb th {
  background-color: #f5f6f8;
  padding: 10px 0;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
}

.datatable-team-maintb td {
  border-bottom: 0.2px solid rgba(106, 121, 136, 0.7);
  /* padding: 25px 35px 25px 27px; */
  color: #282828;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  background: #fff;
  padding: 25px 10px;
}

.datatable-team-maintb {
  padding-top: 38px;
  overflow: scroll;
}

.datatable-team-maintb th img {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  vertical-align: middle;
}

th.sortable-header {
  color: #6a7988;
  font-weight: 500;
  font-size: 17px;
  padding: 10px;
}

.datatable-team-maintb table {
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  min-width: max-content;
}

span.user-image-listingteam img {
  width: 27px;
  margin-right: 15px;
}

.head-tb-of-team-member > tr > th:first-child {
  padding-left: 27px;
}

.body-tb-of-team-member > tr td:first-child {
  padding-left: 27px;
}

.edit-value-changebtn-tic {
  display: flex;
  justify-content: space-between;
  max-width: 149px;
  align-items: baseline;
}

.edit-value-changebtn-tic input {
  max-width: 50px;
  background: #ffffff;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 5px;
  height: 43px;
  text-align: center;
  font-weight: 300;
}

.blurred {
  filter: blur(5px);
  cursor: not-allowed;
  user-select: none;
}

button.sep-pop-btn {
  background: transparent;
}

element.style {
  background: #f5f6f8;
  border: 0.2px solid #282828;
  border-radius: 6px;
  width: 68px;
  color: #405bff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 32px;
}

button.main-edit-btn-parent {
  background: #f5f6f8;
  border: 0.2px solid #282828;
  border-radius: 6px;
  width: 68px;
  color: #405bff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.filter-inner.showing-result.show-result-team-fill {
  /* position: absolute; */
  display: flex;
  gap: 10px;
  /* right: 5%; */
  /* margin-top: -15px; */
}

.filter-inner.showing-result.show-result-team-fill select {
  min-width: 44px;
  height: 27px;
  background-color: #fafafa;
  border: 0.5px solid #b4b4b4;
  border-radius: 5px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13.42px;
  letter-spacing: 0.5px;
  color: #282828;
  padding-left: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAACXU8ZrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABtSURBVHgBdY67DYAwDEQdpPSMABvACPRpvAEbhA3CCDCJq9Sswgj0KcxFSiSEwI0/93w6IpSItPRRMcYhdwOgs9YemHfn3PYAvKquKaW+YeYTw4S7hxAKEAAsuI/QL1M/i6PUHQBnA/rJMb8z3pC1Mkzst0lyAAAAAElFTkSuQmCC)
    no-repeat;
  background-position: right 8px center;
  padding-right: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}

select#memberTeamSelect {
  width: 361px;
}

@media screen and (max-width: 479px) {
  select#memberTeamSelect {
    width: 100%;
  }

  select#memberTeamSelect {
    width: 100%;
  }

}
@media screen and (max-width: 767px){
  .content-outer.clients .client-details-overview-outer{
    margin-top: 0 !important;
  }
}

/* Other styles */
@media screen and (max-width: 991px) {
  .search-userandmail {
    display: block;
  }

  .search-bar-team {
    margin-left: 0px;
    margin-top: 20px;
  }

  .member-main-outter {
    padding: 40px 15px;
  }

  .filter-inner.showing-result.show-result-team-fill {
    padding-top: 6px;
    margin: 0px;
  }
}

/* page nation  */
.pagination {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  padding-top: 27px;
  padding-bottom: 57px;
}

.pagination li a {
  display: inline-block;
  margin-right: 4px;
}

ul.pagination.maininteampag .next:after {
  display: none;
}

li.previous a {
  border: none;
}

.pagination li.active a {
  color: #fff;
}

.pagination li.previous a {
  color: #6479f8;
  font-weight: 300;
  font-size: 15px;
}

.maininteampag li.next.disabled a {
  color: #6479f8;
  font-weight: 300;
  font-size: 15px;
}
