.clint-title {
  color: #282828;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: start;
  margin: 18px 0 7px;
}
.client-with-invitation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.no-border {
 border: none !important;
}
.invite-and-addclient {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
}
.btn-invite-client {
  background: #6479f8;
  border-radius: 6px;
  letter-spacing: 0.5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 15px 17px 14px 14px;
}
a.btn-invite-client:hover {
  color: #fff;
}
a.btn-new-client:hover {
  color: #fff;
}
span.icon-invite img {
  margin-right: 13px;
  width: 23px;
  height: 15px;
}
.btn-new-client {
  background: #6479f8;
  border-radius: 6px;
  letter-spacing: 0.5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 14px 16px 14.5px 15px;
}
span.icon-new-client img {
  margin-right: 14.5px;
  width: 15.5px;
  height: 15.5px;
}
.client-details-overview-outer .client-with-invitation .custom-dropdown {
  width: 358px;
}
.client-details-overview-outer
  .client-with-invitation
  .custom-dropdown
  .selected-option.custom-dropdown-user-new {
  font-size: 17px;
  font-weight: 300;
  line-height: 21px;
  color: #78828d;
  border-radius: 5px !important;
  padding-left: 13px;
}
.client-details-overview-outer .custom-dropdown-new ul li {
  text-align:
   start;
}
.account-manager.project-manager.account-manager-client-overview .account-manager-image-with-name .account-manager-name .value-field {
  color: #282828;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 122%;
  letter-spacing: 1px;
}
.client-details-overview-outer .client-professional-details-outer {
  border-radius: 15px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  background: #fff;
  box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
  padding: 16px 45px 26px 26px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one {
  /* display: flex;
    align-items: flex-start; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding-bottom: 23px;
  border-bottom: 1px solid #e8e8e8;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .client-status {
  width: 100%;
  text-align: start;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .client-status
  .client-status-text {
  color: #454f59;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 14px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .client-status
  .client-status-bar {
  color: #70ad69;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.4px;
  border-radius: 100px;
  border: 0.2px solid #516d4d;
  background: #d5f3d8;
  padding: 6px 14px 8px 11px;
  width: fit-content;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .account-manager {
  text-align: start;
  width: 100%;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .account-manager
  .account-manager-text {
  color: #454f59;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .account-manager
  .account-manager-image-with-name {
  display: flex;
  align-items: center;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .account-manager
  .account-manager-image-with-name
  .account-manager-name {
  color: #282828;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 1px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .account-manager
  .account-manager-image-with-name
  img {
  width: 27px;
  height: 27px;
  margin-right: 7px;
  border-radius: 50%;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .customer-since {
  text-align: start;
  width: 100%;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .customer-since
  .customer-since-text {
  color: #454f59;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 18px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-one
  .customer-since
  .customer-since-date {
  color: #282828;
  font-size: 19px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.5px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two {
  /* display: flex;
    align-items: flex-end;
    justify-content: space-between; */
  display: grid;
  margin-top: 19px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: center;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .point-of-contact {
  text-align: start;
}

.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .point-of-contact-text {
  color: #454f59;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 12px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .client-manager-image-with-name {
  display: flex;
  align-items: center;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .client-manager-image-with-name
  img {
  width: 10px;
  height: 13px;
  margin-right: 10px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .account-manager-image-with-name
  .point-of-contact-name {
  color: #282828;
  font-size: 19px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.5px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .email-with-icon {
  display: flex;
  align-items: center;
  text-align: start;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .email-with-icon
  img {
  width: 18px;
  height: 12px;
  margin-right: 11px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .email-with-icon
  .mail-addrss-text
  a {
  color: #282828;
  font-size: 19px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.5px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .contact-with-icon {
  display: flex;
  align-items: center;
  text-align: start;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two 
  .contact-icon
  img {
  width: 17px;
  height: 17px;
  margin-right: 8px;
}
.client-details-overview-outer
  .client-professional-details-outer
  .client-detail-row-two
  .contact-with-icon
  .contact-number-text
  a {
  color: #282828;
  font-size: 19px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.5px;
}
.mail-addrss-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 200px; /* adjust as needed */
}

.mail-addrss-text:hover {
  max-width: none;
  overflow: visible;
}
.point-of-contact-name {
  color: #282828;
  font-size: 19px;
  font-weight: 300;
  line-height: 23.18px;
  letter-spacing: 0.5px;
}
.invite-client-outer button.delete-client {
  height: 44px;
  padding: 14px 16px 14.5px 15px;
}
.account-manager.project-manager.account-manager-client-overview{
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 25px;
}
.listing-recent-invoice-inner tbody.p-datatable-tbody > tr td:nth-child(5),
.listing-recent-invoice-inner tbody.p-datatable-tbody > tr td:last-child,
.clint-latest-project tbody.p-datatable-tbody > tr td:last-child {
  text-align: right;
}
.view-button a {
  color: #405bff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.96px;
  letter-spacing: 0.5px;
}
span.text-red-500 {
  color: red;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal {
  overflow: visible;
  max-height: 75vh !important;
  padding-right: 14px !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal form {
  height: max-content;
  overflow: auto;
  max-height: 40vh;
  padding-bottom: 20px;
  padding-right: 10px;
}
.client-manager-image-with-name {
  min-height: 23.18px;
}
.client-details-border {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
}
@media only screen and (max-width: 991px) and (min-width: 480px) {
  .client-details-overview-outer {
    margin-top: 80px;
  }
  .clint-latest-project,
  .client-recent-invoice,
  .client-recent-documents {
    padding-left: 0px !important;
  }
  .client-details-overview-outer .client-with-invitation .custom-dropdown {
    width: 50% !important;
  }
  .value-field {
    position: initial;
    transform: translate(0px, 0px);
  }
  .value-field.full-text {
    transform: translate(0px, -8px);
  }
  .content-outer.client.overview.clients .client-details-overview-outer {
    margin-top: 0;
  }
}
@media screen and (min-width: 992px) {
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two
    .email-with-icon,
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two
    .contact-with-icon {
    margin-top: 27px;
  }
}
@media screen and (max-width: 767px) {
  .client-details-overview-outer {
    margin-top: 80px;
  }
  .client-professional-details-outer .value-field.full-text {
    /* transform: translate(0px, -8px); */
    transform: translate(-7px, -50%);
  }
  .content-outer.client.overview.clients .client-details-overview-outer {
    margin-top: 0;
  }
  .invite-and-addclient {
    flex-wrap: wrap;
  }
  .client-activities-outer .client-profile-and-time {
    padding-left: 0 !important;
  }
  .value-field {
    position: initial;
    transform: translate(0px, 0px);
  }
  .value-field.full-text {
    transform: translate(0px, -8px);
  }

  .clint-latest-project,
  .client-recent-invoice,
  .client-recent-documents {
    padding-left: 0px !important;
  }
  .client-with-invitation {
    display: block;
    padding: 0 10px;
    margin-bottom: 26px;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-one {
    display: block;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-one
    .client-status {
    width: 100%;
    margin-bottom: 26px;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-one
    .account-manager {
    margin-bottom: 26px;
    width: 100%;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-one
    .customer-since {
    width: 100%;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-one
    .client-status
    .client-status-text,
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-one
    .account-manager
    .account-manager-text,
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-one
    .customer-since
    .customer-since-text {
    margin-bottom: 10px;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two {
    display: block;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two
    .point-of-contact,
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two
    .email-with-icon {
    margin-bottom: 18px;
  }
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two
    .client-manager-image-with-name
    .contact-profile-icon,
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two
    .email-with-icon
    .email-icon,
  .client-details-overview-outer
    .client-professional-details-outer
    .client-detail-row-two
    .contact-with-icon
    .contact-icon {
    min-width: 35px;
  }
  .client-details-overview-outer .client-with-invitation .custom-dropdown {
    width: 100%;
    margin-bottom: 26px;
  }
  /* .custom-dropdown {
    padding: 0 !important;
  } */
  .invite-and-addclient {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;
  }
  a.btn-invite-client,
  a.btn-new-client {
    width: 155px;
  }
}
