.sidebar-outer {
    background: #fff;
    padding: 35px 24px 50px 24px;
    text-align: left;
    position: relative;
    height: 100vh;
    position: fixed;
    border-right: 1px solid #D8DDE1;
    width: inherit;
    z-index: 9999;
}

.main-menu-outer ul li a.active {
    font-weight: 600;
    color: #282828;
    background: rgba(245, 246, 248, 0.9);
    border-radius: 7px;
}

.logo-sec {
    margin-bottom: 75px;
    padding-left: 14px;
}

.main-menu-header h3 {
    margin: 0;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 1px;
    color: #6A7988;
    padding-left: 17px;
    text-transform: uppercase;
}

.main-menu-outer ul {
    list-style-type: none;
    padding-left: 0;
}

.main-menu-outer ul li a {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: left;
    font-weight: 300;
    font-size: 19px;
    color: #6A7988;
    line-height: 23px;
    text-decoration: none;
    padding: 13px 17px;
}



.main-menu-outer ul li a span {
    width: 25px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-right: 20px;
}

.main-menu-outer ul li a:hover,
.main-menu-outer ul li a.active {
    font-weight: 600;
    color: #282828;
}

.main-menu-outer ul li:hover {
    background: rgba(245, 246, 248, 0.9);
    border-radius: 7px;
}

.main-menu-outer ul li a:hover svg rect,
.main-menu-outer ul li a:hover svg path,
.main-menu-outer ul li a.active svg rect,
.main-menu-outer ul li a.active svg path {
    fill: #405BFF;
    /* stroke-width: 0; */

}
.main-menu-outer ul li a.active svg path,
.main-menu-outer ul li a:hover svg path {
    fill: #405BFF;
    stroke: #405BFF;
}
li.low-border-width a:hover svg path {
    stroke: none !important;
}
/* .main-menu-outer ul li a:hover svg.f-rec4 rect,
.main-menu-outer ul li a:hover svg.f-rec4 circle {
    stroke-width: 2.5;
}
.main-menu-outer ul li a:hover svg.f-rec4 path {
    stroke-width: 2.5;
}
.main-menu-outer ul li a:hover svg.clock-icon-pr path{
    stroke-width: 1;
} */

.main-menu-outer ul li a:hover svg rect,
.main-menu-outer ul li a:hover svg circle,
.main-menu-outer ul li a.active svg rect,
.main-menu-outer ul li a.active svg circle {
    fill: #405BFF;

}

.main-menu-outer ul li a:hover svg.f-rec4 path,
.main-menu-outer ul li a:hover svg.f-rec4 rect,
.main-menu-outer ul li a:hover svg.f-rec4 circle,
.main-menu-outer ul li a.active svg.f-rec4 path,
.main-menu-outer ul li a.active svg.f-rec4 rect,
.main-menu-outer ul li a.active svg.f-rec4 circle {
    stroke: #405bff;
    fill: none;
}
li.low-border-width a:hover svg path,
li.low-border-width a.active svg path {
    stroke: none !important;
}
.main-menu-bottom {
    position: absolute;
    bottom: 20px;
}
/* Client */
.own-name {
    padding-left: 18px;
    color: #6A7988;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    letter-spacing: 1px;
    margin-bottom: 26px;
}
.own-name.own-name-client{
    margin-top: 36px;
}
.main-menu-outer {
    max-height: calc(100% - 130px);
    overflow: auto;
}
/* end client */
@media screen and (min-width: 992px) {
    .main-menu-bottom ul li a {
        padding-left: 6px;
    }
}
@media screen and (max-width: 991px) {
    .mobile-hide-logo-secsl {
        display: none;
    }

    .mobile-hide-mainmenu-text {
        display: none;
    }

    /* .main-menu-outer ul{
    width: max-content;
    display: inline-flex;
} */
    /* .sidebar-outer{
    width: 0px;
    padding: 0px;
    padding-top:75px;
} */
    /* .sidebar-nav{
    width: auto;
}
.admin-inner{
    justify-content: inherit;
} */
    /* .sidebar-outer{
    overflow: scroll;
    width: 100vw;
    display: block;
} */
    .main-menu-outer {
        display: flex;
        overflow: scroll;
        max-width: 300000px;
        padding: 0 20px;
        align-items: center;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .main-menu-outer::-webkit-scrollbar {
        display: none;
    }

    .sidebar-outer {
        position: initial;
        height: 100%;
        padding: 0px;
    }

    .main-menu-bottom {
        position: initial;
    }

    .main-menu-outer ul {
        list-style-type: none;
        padding-left: 0;
        display: inline-flex;
        width: max-content;
        margin: 15px 0;
        align-items: center;


    }

    .main-menu-outer ul li a span {
        margin-right: 10px;

    }

}