.outer-project-client-section-alert .text-arrow-outer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    flex-wrap: wrap;
    grid-gap: 25px;
    margin-left: 0 !important;
}
.outer-project-client-section-alert .invoice-top{
    padding-bottom: 0;
}
.alert-section-inner-add-new {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;
    grid-gap: 25px;
}
.user-outer-timeoff tbody.p-datatable-tbody>tr td .altert-row {
    display: grid;
    grid-gap: 29px;
}
.user-outer-timeoff tbody.p-datatable-tbody>tr td .altert-row label{
    margin-bottom: 0 !important;
}
.threashold-count-user-outer-table {
    display: grid;
    grid-gap: 25px;
}
.threashold-count-user-outer-table span{
    margin-left: 0;
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    cursor: initial;
}
.threashold-count-user-outer-table span:hover{
    color: #282828;
}
.threashold-count-user-outer-table span:hover a{
    color: #405BFF;
}
.threashold-count-user-outer-table span a{
    color: #405BFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.2px;
    padding-left: 8px;
    padding-right: 4px;
    cursor: pointer;
}
.threashold-count-user-outer-table span a.threashold-count-user{
    padding-left: 4px;
    padding-right: 0 !important;
}
.threashold-count-user-outer-table span.custom-switch-description.threashold-count-user-outer.disabled{
    opacity: 0.4;
    pointer-events: none;
}
@media screen and (max-width: 767px) {
    .user-outer-timeoff.user-outer-timeoff-alert-sec .button-sec-invoice{
        margin-bottom: 0 !important;
    }
    .alert-section-inner-add-new{
        margin-top: 30px;
    }
    .outer-project-client-section.outer-project-client-section-alert input.search-input{
        max-width: 100% !important;
    }
    .user-outer-timeoff.user-outer-timeoff-alert-sec .overview-top-right{
        width: 100%;
    }
}