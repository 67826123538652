.listing-recent-documents-inner {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 27px 26px 32px 23px;
    margin-top: 42px;
}
.data-table-down-arrow-icon img{
    width: 14px;
    height: 18px;
    margin-right: 15px;
}
.data-table-eye-icon img{
    width: 23px;
    height: 16px;
}
.listing-recent-documents-inner tbody.p-datatable-tbody>tr td:nth-child(5){
    font-weight: 300;
  }

.profile-section-outer .active-text {
    color: #7CC273 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17.08px !important;
    letter-spacing: 0.4px !important;
    width: 67px;
    height: 31px;
}
@media screen and (max-width: 1115px) {
    .listing-recent-documents-inner {
        width: 100%;
    }

    .recent-invoices {
        overflow: scroll;
    }
}