.pie-outer {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 19px 22px 18px 18px;
    height: 100%;
}

.pie-outer .graph-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #454F59;
}

.pie-outer .icon-graph {
    font-weight: 600;
    font-size: 20px;
    line-height: 24.4px;
    letter-spacing: 1px;
    color: #282828;
}

.pie-chart-top {
    margin-bottom: 10px;
}
.apexcharts-canvas{
    text-align: center !important;
    margin: 0 auto !important;
}