tspan {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #282828;
}

line.recharts-cartesian-axis-line {
    display: none;
}

line.recharts-cartesian-axis-tick-line {
    display: none;
}

.chart-container {
    border-bottom: 0.5px solid #D8DDE1;
}

.top-inner-graph {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    grid-gap: 20px;
    flex-wrap: wrap;
}

.graph-main-outer {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 14px 22px 18px 18px;
    width: 36.3%;

}

.graph-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #454F59;

}

g.recharts-cartesian-grid-vertical line {
    display: none;
}

.recharts-wrapper {
    width: 100% !important;
    max-width: 100% !important;

}

svg.recharts-surface {
    width: 100% !important;
    max-width: 100% !important;


}

@media screen and (max-width: 991px) {
    .graph-overview {
        display: block;
        padding-top: 20px;


    }

    .overview-left {
        width: 100%;
    }

    /* .content-outer {
    padding-right: 15px;
    padding-left: 15px;
    width: 100vw;
    margin-top: 50px;

} */
    .graph-main-outer {
        width: 100%;
    }

    .main-menu-bottom ul li a {
        /* display: none; */
    }

    .graph-main-outer {
        margin-top: 16px;
    }
}