.security-inner.noti-enable {
    padding-bottom: 24px;
    margin-bottom: 32px;
}

.security-inner.noti-enable span {
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 122%;
    letter-spacing: 0.2px;
}

.security-outer.noti-outer label.custom-switch {
    cursor: pointer;
}

.security-inner.noti-enable span {
    cursor: initial;
}

.toggle-spinner {
    display: flex;
    grid-gap: 14px;
    align-items: center;
}
.security-inner.noti-enable .custom-dropdown {
    padding-right: 0;
}
@media only screen and (min-width: 992px) {
    /* .security-outer.noti-outer {
        min-height: 401px;
    } */

}

@media only screen and (max-width: 992px) {
    .security-inner.noti-enable {
        flex-direction: row;
        justify-content: space-between;
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}

@media only screen and (max-width: 410px) {
    .security-inner.noti-enable span {
        margin-left: 0;
    }

    .security-inner.noti-enable {
        padding-left: 5px;
        padding-right: 5px;
    }
}