.client-setup-outer {
    /* padding-left: 38px;
    padding-right: 39px; */
    padding-left: 39px;
    padding-right: 45px;
}
.member-label-outer {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: #6A7988;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    margin-bottom: 9px;
}
.account-box .custom-dropdown-new  ul li{
    display: flex;
    grid-gap: 15px;
    align-items: center;
    color: #282828;
    font-size: 18px;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 1px;
}
.account-box .custom-dropdown-new ul li img{
    width: 27px;
    height: 27px;
    border-radius: 50%;
}
.account-box .selected-option.custom-dropdown-user-new {
    display: flex !important;
    justify-content: flex-start !important;
    grid-gap: 15px;
}
.member-label-icon {
    width: 10px;
}
.member-label-text{
    width: calc(100% - 20px);
    text-align: left;
}
.account-box-title{
    color: #454F59;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 0.2px;
    text-align: left;
    margin-bottom: 25px;
}
.setup-box-outer {
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 34px 40px 34px 35px;
}
.account-box-outer {
    border-bottom: 1px solid #E8E8E8;
    padding: 0 12px 37px 12px;
}
.account-box {
    margin-bottom: 30px;
}

.account-box:last-child {
    margin-bottom: 0;
}
.project-manager-outer{
    margin-top: 32px;
}
.add-row-button {
    text-align: left;
}
.add-row-button button{
    color: #282828;
    font-size: 18px;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    background-color: transparent;
}
.account-box-outer.project-manager-outer {
    border: none;
}
span.user-image-set img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
}
button.remove-user-btn.remove-user-btn-client{
    background: transparent;
    padding: 0;
}
.account-box-section {
    position: relative;
}
.client-setup-outer .selected-option.custom-dropdown-user-new {
    display: block !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 8px;
    padding-bottom: 5px;
}
.client-setup-outer span.user-image-set{
    margin-right: 15px;
}
@media screen and (min-width: 1200px) {
    .account-box {
        width: 50%;
    }
    button.remove-user-btn.remove-user-btn-client {
        position: absolute;
        right: -35px;
        top: 10px;
    }
}
@media screen and (max-width: 1199px) {
    .client-setup-outer{
        padding: 0 22px !important;
    }
    .setup-box-outer {
        padding: 34px 20px 34px 20px;
    }
   
}
@media only screen and (max-width: 1199px) and (min-width:350px) {
   .account-box-section  .custom-dropdown1{
    width: calc(100% - 40px);
    }
    .account-box-section {
        display: flex;
        
        justify-content: space-between;
        grid-gap: 20px;
    } 
    .account-box-outer{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
@media only screen and (max-width: 349px) {
    .account-box-section .custom-dropdown1{
        width: 100%;
    }
    .account-box-section {
        display: flex;
        justify-content: space-between;
        grid-gap: 30px;
        flex-wrap: wrap;
    }
}