.tab-outer-tab-account .tabs-content.account-settings-tab .company-profile {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    margin-bottom: 50px;
    margin-top: 29px !important;
}
.tab-outer-tab-account ul.tabs {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding-left: 32px;
    padding-top: 23px;
    border-bottom: 1px solid #E8E8E8;
    background-color: #fff;
    grid-gap: 53px;
}
.tab-outer-tab-account ul.tabs li{
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #6A7988;
    font-weight: 300;
    padding-bottom: 11px;
    /* margin-right: 46px; */
    cursor: pointer;
    white-space: nowrap;
}

.tab-outer-tab-account ul.tabs li.active-tab{
    color: #282828;
    font-weight: 500;
    border-bottom: 4px solid #405BFF;
}
.content-outer.account.settings {
    padding-left: 0;
    padding-right: 0;
}
.tab-outer-tab-account ul.tabs {
    max-width: 100000px;
    overflow: auto;
}
.prferences,
.user-security {
    margin-top: 29px;
}
.content-outer.Account.Settings {
    padding-left: 0;
    padding-right: 0;
}
.content-outer.Account.Settings .invoice-top {
    flex-wrap: wrap;
}
.user-outer-overview.invoice-outer-invoice.invite-history .card-outer .time-table {
    border: none;
    box-shadow: none;
}
.user-outer-overview.invoice-outer-invoice.invite-history {
    padding: 0 38px;
}

@media only screen and (min-width: 1440px){
    /* .tabs-content.account-settings-tab {
        width: 1122px;
        margin: 0 auto;
    } */
}
@media only screen and (max-width: 1439px) and (min-width: 992px){
    .tab-outer-tab-account .account-settings-tab {
        margin: 0 38px;
    }
}
@media only screen and (max-width: 479px){
    .tab-outer-tab-account ul.tabs li{
        margin-right: 0;
        font-size: 17px;
    }
    .tab-outer-tab-account ul.tabs{
        padding-left: 17px;
        padding-right: 17px;
        justify-content: space-between;
    }
    .tab-outer-tab-account ul.tabs li:last-child{
        margin-right: 0;
    }
}
@media only screen and (max-width: 767px){
    .tab-outer-tab-account ul.tabs li{
        margin-right: 32px;
        font-size: 17px;
        white-space: nowrap;
    }
    
    .content-outer.Account.Settings .invoice-top{
        padding-right: 0 !important;
    }
    .user-outer-overview.invoice-outer-invoice.invite-history .button-sec-invoice{
        display: none !important;
    }
}
@media only screen and (max-width: 1150px){
    .user-outer-overview.invoice-outer-invoice.invite-history {
        padding: 0 20px !important;
    }
   
}
@media only screen and (max-width: 1449px) and (min-width: 1275px){
    .user-outer-overview.invoice-outer-invoice.invite-history .invoice-filter-inner-section.no-flter-all .filter-inner:last-child{
        display: block !important;
    }
}
@media only screen and (max-width: 991px){
    .tab-outer-tab-account ul.tabs {
        max-width: 1000px;
        overflow: auto;
        padding-bottom: 0px;
        grid-gap: 30px;
        /* margin-right: -12px;
        margin-left: -12px; */
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }  
    .outer-project-client-section{
        padding: 0 30px !important;
    }
    .content-outer.profile.settings .tab-outer-tab-account ul.tabs {
        margin-left: 0;
        margin-right: 0;
    }
    .tab-outer-tab-account ul.tabs li{
        white-space: nowrap;
    }
    .tab-outer-tab-account ul.tabs li {
        margin-right: 0px;
        font-size: 17px;
    }
    .tab-outer-tab-account ul.tabs::-webkit-scrollbar {
        display: none;             /* width of the entire scrollbar */
      }
      
      .tab-outer-tab-account ul.tabs::-webkit-scrollbar-track {
        display: none;
      }
      
      .tab-outer-tab-account ul.tabs::-webkit-scrollbar-thumb {
        display: none;
      }
      .tab-outer-tab-account .tabs-content.account-settings-tab .company-profile {
        background: transparent;
        border: none;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 50px;
        margin-top: 29px;
    }
    .tab-outer-tab-account .profile-details .profile-photo-outer {
        /* flex-direction: column;
        margin: 29px 15px 24px 15px;
        justify-content: flex-start;
        text-align: left;
        align-items: baseline; */
    }
}
@media only screen and (min-width: 1440px){
    .payment-option{
        margin: 0 38px;
    }
    
}