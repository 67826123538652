.listing-recent-invoice-inner {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 27px 26px 32px 23px;
    margin-top: 42px;
}

.status-paid {
    border-radius: 100px;
    border: 0.2px solid #516D4D;
    background: #D5F3D8;
    color: #70AD69;
    font-size: 14px;
    font-weight: 500;
    line-height:18px;
    letter-spacing: 0.4px;
    padding: 7px 12px;
    width: fit-content;
  }
  
  .status-pending {
    border-radius: 100px;
    border: 0.2px solid #695437;
    background: #FDDFB5;
    color: #E39C4F;
    font-size: 14px;
    font-weight: 500;
    line-height:18px;
    letter-spacing: 0.4px;
    padding: 7px 12px;
    width: fit-content;
  }
  .detail-button{
    text-align: end;
  }
  .listing-recent-invoice-inner tbody.p-datatable-tbody>tr td:nth-child(4){
    font-weight: 500 !important;
  }

@media screen and (max-width: 1115px) {
    .listing-recent-invoice-inner {
        width: 100%;
    }

    .recent-invoices {
        overflow: scroll;
    }
}