/* .tab-outer-time {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    margin-bottom: 50px;
} */
.content-outer.time-tracker {
    padding-left: 0;
    padding-right: 0;
}
.tab-outer-time ul.tabs {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 23px;
    border-bottom: 1px solid #E8E8E8;
    background-color: #fff;
}
.tab-outer-time ul.tabs li a{
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #6A7988;
    font-weight: 300;
    padding-bottom: 11px;
    margin-right: 46px;
    cursor: pointer;
    border-radius: 0;
    display: inline-block;
}
.tab-outer-time ul.tabs li a.active{
    color: #282828;
    font-weight: 500;
    border-bottom: 4px solid #405BFF;
}
.content-outer.time.tracker {
    padding-left: 0;
    padding-right: 0;
}
@media only screen and (max-width: 479px){
    .tab-outer-time ul.tabs li a{
        margin-right: 32px;
        font-size: 17px;
    }
    .tab-outer-time ul.tabs{
        padding-left: 17px;
        padding-right: 17px;
        justify-content: space-between;
    }
    .tab-outer-time ul.tabs li:last-child{
        margin-right: 0;
    }
}
@media only screen and (max-width: 767px){
    .tab-outer-time ul.tabs li a{
        margin-right: 32px;
        font-size: 17px;
        white-space: nowrap;
    }
    .tab-outer-time {
        max-width: 10000px;
    }
    .tab-outer-time ul.tabs{
        overflow: auto;
        width: 100%;
    }
}
@media only screen and (max-width: 387px){
    .tab-outer-time ul.tabs {
        max-width: 1000px;
        overflow: scroll;
        padding-bottom: 15px;
    }  
    .tab-outer-time ul.tabs li{
        white-space: nowrap;
    }
    .tab-outer-time ul.tabs li {
        margin-right: 20px;
        font-size: 16px;
    }
}

.tab-mobile-select{
    background: #fff;
    border-top: 1px solid #E8E8E8;
    padding: 16px 12px 30px 12px;
}

.tab-mobile-select h4{
    color: #6A7988;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 1px;
    text-align: left;
    padding-left: 14px;
    padding-bottom: 7px;
}
.tab-mobile-select select{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../images/icons/down-arrow.svg) no-repeat;
    background-position: right 20px center;
    cursor: pointer;
    padding-right: 30px;
    border-radius: 5px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background-color: #FFF;
    color: #282828;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 1px;
    height: 48px;
    width: 100%;
    padding: 10px 20px;
}
.tab-mobile-select{
    display: none;
}
button.p-disabled {
    opacity: 0.5 !important;
}
/* Styles for mobile (screens up to 767px) */
@media (max-width: 767px) {
  .tab-mobile-select {
    display: block; /* Display the select on mobile */
    width: 100%;

  }
  .tab-outer-time > ul.tabs{
    display: none;
  }
  ul.tabs.team-tab-s1 {
    padding-top: 0 !important;
    background: #fff !important;
    padding-bottom: 33px !important;
}
ul.tabs.team-tab-s1 li {
    margin-bottom: 33px !important;
}
}

