.login-outer .login-inner {
    display: flex;
    flex-wrap: wrap;
    background: #FBFBFD;
}

.login-outer .login-inner .lg-lft,
.login-outer .login-inner .lg-rgt {
    width: 50%;
}

.login-outer .login-inner .lg-lft img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-outer .login-form-sec {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
}

.login-outer .login-inner .lg-rgt {
    position: relative;
    padding-left: 90px;
}

.login-outer .lg-rgt .top-logo {
    position: absolute;
    top: 53px;
    right: 58px;
}

.login-outer .login-form-sec h3 {
    color: #282828;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.4px;
    margin-bottom: 37px;
}

.login-outer .login-form-sec form label {
    color: #282828;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 6px;
}

.login-outer .login-form-sec form {
    text-align: left;
}

.login-outer .login-form-sec .form-outer {
    display: flex;
    flex-direction: column;
}

.login-outer .login-form-sec .form-outer.email {
    margin-bottom: 35px;
}

.login-outer .login-form-sec .form-outer.password {
    margin-bottom: 15px;
}

.login-outer .forget-password {
    text-align: right;
}

.login-outer .login-form-sec .form-outer input::placeholder {
    color: #78828D;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}

.login-outer .login-form-sec .form-outer input {
    width: 450px;
    height: 60px;
    border: 1px solid #6A7988;
    background-color: #fff;
    color: #000000;
    border-radius: 5px;
    padding: 15px 20px;
    font-size: 20px;
    padding-right: 50px;
}

.login-outer .forget-password a {
    color: #6479F8;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.login-outer .login-form-sec form button {
    border-radius: 6px;
    background: #6479F8;
    border: none;
    color: #FFF;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.5px;
    width: 452px;
    height: 44px;
    display: block;
}
.submit-message-onboard h4 {
    color: #78828D;
}
.login-outer .login-form-sec form button:hover {
    opacity: 0.8;
}

.login-outer .password-input-wrapper {
    position: relative;
}

.login-outer .password-input-wrapper img {
    position: absolute;
    top: 41%;
    right: 20px;
    cursor: pointer;
}

.login-outer .mobile-lg-img {
    display: none;
}

.top-logo-mobile {
    display: none;
}

.login-outer .error {
    border-color: red !important;
    background-color: #fdf4f4 !important;
}
.login-outer .remb-for {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
}
.login-outer .remember-me {
    display: flex;
    align-items: center;
    grid-gap: 5px;
}
@media only screen and (max-width: 985px) {
    .login-outer .desktop-lg-img {
        display: none;
    }
    /* .remb-for {
        flex-direction: column;
    } */
    .login-outer .top-logo-mobile {
        display: block;
    }

    .login-outer .top-logo {
        display: none;
    }

    .login-outer .mobile-lg-img {
        display: block;
    }

    .login-outer .top-logo-mobile {
        width: 100%;
        padding: 24px 0;
    }

    .login-outer .login-inner .lg-lft,
    .login-outer .login-inner .lg-rgt {
        width: 100%;
    }

    .login-outer .login-inner .lg-rgt {
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 50px;
    }

    .login-outer .top-logo {
        display: none;
    }

    .login-outer .top-logo-mobile img {
        width: 197.792px;
        height: 32px;
    }

}

@media only screen and (max-width: 767px) {
    .login-outer .login-form-sec .form-outer input {
        width: 100% !important;
    }
    /* .login-outer .login-form-sec form {
        text-align: center !important;
    } */
    /* .login-outer .login-form-sec .form-outer{
        align-items: center;
    } */
    .login-outer .login-form-sec form button {
        width: 100%;
    }

    .login-outer .login-form-sec h3 {
        text-align: center;
        margin-top: 18px;
    }

    .login-outer .login-form-sec {
        display: inline-block;
    }

    .login-outer .login-form-sec {
        justify-content: center;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
    .login-outer .login-inner .lg-rgt {
        padding: 0 50px;
        padding-bottom: 50px;
    }

    .login-outer .login-form-sec {
        justify-content: center;
    }
}
@media only screen and (min-width: 986px) {
    .login-outer .login-inner .lg-lft img {
        height: 100vh;
    }
}