.subscription-outer-overview {
    padding: 32px 43px 0 34px;
}
.current-plan {
    border-radius: 8px;
    border: 1px solid #EDEDED;
    background: #FFF;
    max-width: 495px;
    padding: 18px 17px 17px 32px;
    margin-bottom: 8px;
    display: inline-block;
    width: 100%;
}
.current-plan h3{
    color: #1F1F1F;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 5px;
}
.current-plan p{
    color: #8A8A8A;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 0;
}
.current-plan .current-plan-inner-top{
    margin-bottom: 7px;
}
.current-plan .current-plan-inner-bottom {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;
    flex-wrap: wrap;
    align-items: center;
}
.current-plan .current-plan-price {
    color: #405BFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.current-plan .upgrade-plan-btn button{
    color: #405BFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 8px;
    border: 1px solid #405BFF;
    background: #FFF;
    display: inline-flex;
    padding: 12px 16px;
    align-items: center;
    gap: 4px;
}
td.subscription-download-icon button {
    background: transparent;
}
td.subscription-download-icon button img{
    margin-left: 0 !important;
}
.subscription-download-icon{
    text-align: center !important;
}
/* Subscription plan listing */
.pricing-table-outer {
    padding: 47px 48px;
}
.subscription-plans-heading h3{
    color: #454F59;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.72px;
    margin-bottom: 9px;
}
.subscription-plans-heading p{
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
}
.subscription-plans-heading{
    margin-bottom: 49px;
}
.subscription-plans-inner button.tab-button{
    color: #282828;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    background: transparent;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.subscription-plans-inner button.tab-button.active{
    color: #fff;
    border-radius: 37px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #6479F8;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.subscription-plans-inner .tabs{
    border-radius: 100px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    display: inline-flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.subscription-plans-inner .plans {
    display: flex;
    grid-gap: 32px;
    margin-top: 42px;
    flex-wrap: wrap;
}
.subscription-plans-inner .plans .plan-box{
    display: flex;
    padding: 24px;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    gap: 24px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    max-width: 323.333px;
    width: 100%;
}
.subscription-plans-inner .plans .plan-box h4{
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
}
span.plan-price {
    color: #000;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
}
.top-plan-box p{
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
}
.top-plan-feature p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 14px;
}
.top-plan-feature ul li{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    position: relative;
    padding-left: 32px;
    margin-bottom: 12px;
}
.top-plan-feature ul li::before{
    content: "";
    background: url('../../images/icons/tick-gray.svg');
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0px;
    top: 0px;
}
button.get-started-btn{
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    height: 40px;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 6px;
    background: #6479F8;
    width: 100%;
    margin-top: 24px;
}
button.active:hover{
    opacity: 1 !important;
}
.time-table th img {
    cursor: pointer;
}
/* Master subscription */
.master-subscription-outer {
    display: flex;
    grid-gap: 33px;
    flex-wrap: wrap;
}
.master-subscription-inner{
    display: flex;
    max-width: 323px;
    width: 100%;
    padding: 22px 20px 17px 19px;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    position: relative;
}
.master-subscription-inner .name-value {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;
    margin-bottom: 25px;
    border-bottom: 1px solid #D8DDE1;
    margin-bottom: 12px;
    padding-bottom: 13px;
}
.master-subscription-inner .name-value-left{
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
}
.master-subscription-inner .name-value-right{
    color: #000;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.6px;
}
.plan-features-master.plan-features-master-last .name-value {
    border: none;
    padding-bottom: 16px;
    margin-bottom: 0;
}
.plan-features-master.plan-features-master-last ul li {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
}
.plan-features-master.plan-features-master-last ul li:before{
    content: "";
    background: url('../../images/icons/checkbox-blue.svg');
    width: 16.667px;
    height: 16.667px;
    position: absolute;
    left: 0px;
    top: 3px;
    background-position: center;
    background-size: cover;
}
.mater-btn-box-sectiokn{
    min-height: 20px;
}
.mater-btn-box-sectiokn button {
    position: absolute;
    background: transparent;
    padding: 0 !important;
    position: absolute;
    bottom: 18px;
    right: 22px;
}
.mater-btn-box-sectiokn ul {
    width: 180px;
    height: 164.5px;
    background: #FFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 0px 0px 8px rgba(106, 121, 136, 0.3);
    border-radius: 5px;
    position: absolute;
    z-index: 99;
    right: -17px;
    top: 100%;
    padding: 21.5px 17px 21.5px 18px;
}
.mater-btn-box-sectiokn ul:after{
    background: #fff;
    border-left: .2px solid #6a7988b3;
    border-top: .2px solid #6a7988b3;
    content: "";
    height: 15px;
    position: absolute;
    right: 34px;
    top: -8px;
    transform: rotate(45deg);
    width: 15px;
}
.mater-btn-box-sectiokn ul li{
    color: #6A7988;
    font-size: 19px;
    font-weight: 400;
    line-height: 23.18px;
    border-bottom: 0.5px solid #D8DDE1;    
    padding-bottom: 12px;
    margin-bottom: 12px;
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    grid-gap: 17px;
    align-items: center;
    cursor: pointer;
}
.mater-btn-box-sectiokn ul li button,
.mater-btn-box-sectiokn ul li a{
    background: transparent;
    color: #6A7988;
    font-size: 19px;
    font-weight: 400;
    line-height: 23.18px;

}
.mater-btn-box-sectiokn ul li:last-child{
    padding-bottom: 0px;
    margin-bottom: 0px;  
    border: none;
}
.mater-btn-box-sectiokn ul li .icon-dot-inner{
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.master-subscription{
    margin-top: 26px;
}
.master-subscription .button-sec-invoice a {
    padding: 14px;
    min-width: 125px;
    display: inline-flex;
    grid-gap: 14.5px;
    align-items: center;
    justify-content: center;
}
.master-subscription .button-sec-invoice a:hover{

}
.master-subscription span.icon-invoice img{
    margin-right: 0;
}
.master-subscription .button-sec-invoice{
    margin-bottom: 31px;
}
.plan-edit-top {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 25px;
    align-items: center;
    margin-top: 27px;
}

.master-subscription .button-sec-invoice a:hover{
    color: #fff;
}
.top-right-edit button {
    border-radius: 6px;
    border: 0.2px solid #282828;
    background: #F5F6F8;
    width: 98px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 13.5px;
}
.plan-edit-top .top-left-edit{
    color: #454F59;
    font-size: 26px;
    font-weight: 400;
    line-height: 31.72px;
}
.plan-edit-inner{
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
}
.plan-edit-inner-top {
    /* display: grid;
    grid-template-columns: 2fr 1fr 1fr; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 30px;
    padding: 26px 36px 35px 39px;
    border-bottom: 1px solid #E8E8E8;
    flex-wrap: wrap;
}
.plan-edit-inner-top-left {
    flex-grow: 2;
}
.plan-edit-inner-top label {
    display: grid;
    grid-gap: 11px;
    color: #282828;
    font-size: 17px;
    font-weight: 500;
    line-height: 20.74px;
}
.plan-edit-inner-top-right {
    display: flex;
    grid-gap: 30px;
    flex-wrap: wrap;
}
.plan-edit-inner-top label input{
    color: #282828;
    font-size: 17px;
    font-weight: 300;
    line-height: 20.74px;
    border-radius: 5px;
    border: 0.5px solid #B4B4B4;
    padding: 7px 15px;
    height: 40px;
    display: flex;
    align-items: center;
}
.feature-edit-subscription{
    padding: 26px 36px 35px 39px;
}
.feature-edit-title{
    color: #6A7988;
    font-size: 17px;
    font-weight: 500;
    line-height: 20.74px;
    margin-bottom: 19px;
}
.feature-edit-subscription ul li input {
    border-radius: 7px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    max-width: 576px;
    height: 43px;
    width: 100%;
    padding: 7px 15px;
    display: inline-flex;
    align-items: center;
    color: #282828;
    font-size: 16px;
    font-weight: 300;
    line-height: 19.52px;
}
.feature-edit-subscription ul li {
    display: flex;
    grid-gap: 18px;
    align-items: center;
    margin-bottom: 15px;
}
.feature-edit-subscription ul li button{
    background: transparent;
    border: transparent;
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
}
.feature-edit-subscription ul li button svg{
    width: 18.667px;
    height: 20px;
}
button.add-feature{
    padding: 5px 14px !important;
    color: #282828;
    font-size: 14px;
    font-weight: 300;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 11.5px;
    border-radius: 6px;
    border: 0.2px solid #282828;
    background: #F5F6F8;
    margin-top: 30px;
}
button.add-feature span {
    display: flex;
}
.plan-edit-inner-bottom {
    margin-top: 30px;
    display: flex;
    justify-content: end;
    grid-gap: 22px;
}
button.edit-delete {
    border-radius: 6px;
    border: 0.2px solid #282828;
    background: #F5F6F8;
    display: flex;
    grid-gap: 14px;
    height: 44px;
    padding: 11px 19px;
    color: #282828;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
}
button.edit-save{
    width: 196px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 0.2px solid #7CC273;
    background: #6479F8;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08p;
    letter-spacing: 0.5px;
}
@media screen and  (max-width: 1439px) and (min-width: 992px)  {
    .subscription-outer-overview,
    .pricing-table-outer{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
@media screen and (max-width: 991px) {
    .subscription-outer-overview,
    .pricing-table-outer{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
@media screen and (max-width: 650px) {
    .subscription-plans-inner .plans .plan-box{
        max-width: 100% !important;
    }
    .master-subscription-inner{
        max-width: 100% !important;
    }
    .mater-btn-box-sectiokn ul{
        right: 0;
    }
    .mater-btn-box-sectiokn ul:after{
        right: 15px;
    }
}
@media screen and (max-width: 537px) {
    .plan-edit-inner-top label{
        width: 100% !important;
    }
    .plan-edit-inner-top {
        padding: 26px 18px 35px 18px;
    }
    .feature-edit-subscription {
        padding: 26px 20px 35px 20px;
    }
    .plan-edit-inner-top-right{
        width: 100% !important;
    }
}
