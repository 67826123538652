.tab-outer-time-team ul.tabs {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding-left: 58px;
    padding-right: 70px;
    padding-top: 36px;
    border-bottom: none;
    background: #FBFBFD;
}

.tab-outer-time-team ul.tabs li {
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #6A7988;
    font-weight: 300;
    padding-bottom: 11px;
    margin-right: 16px;
    cursor: pointer;
    border: 1px solid #6A7988;
    border-radius: 100px;
}

.tab-outer-time-team ul.tabs li.active-tab {
    background: #6479F8;
    display: flex;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    border: 1px solid #6479F8;
}

/* start css */
ul.tabs.team-tab-s1 li {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px !important;
    letter-spacing: 0.5px;
    padding: 0 18px;
    height: 40px;
}

ul.tabs.team-tab-s1 li.active-tab a {
    color: #ffff;
    line-height: 17px;
}

ul.tabs.team-tab-s1 li a {
    color: #6A7988;

}

.content-outer.type-of-leave {
    padding-left: 0px;
    padding-right: 0px;
}

.content-outer.members {
    padding-right: 0px;
    padding-left: 0px;
}

.tab-outer-time-team ul.tabs li.active-tab a {
    padding: 0px;
    margin: 0;
    background: #6479F8;
    display: flex;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    border: 1px solid #6479F8;

}

.tab-outer-time-team ul.tabs li a {
    padding: 0px;
    margin: 0px;
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 17.08px;
}

@media only screen and (max-width: 479px) {
    .tab-outer-time-team ul.tabs li {
        margin-right: 32px;
        font-size: 17px;
    }

    .tab-outer-time-team ul.tabs {
        padding-left: 17px;
        padding-right: 17px;
        justify-content: space-between;
    }

    .tab-outer-time-team ul.tabs li:last-child {
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .tab-outer-time-team ul.tabs li {
        margin-right: 32px;
        font-size: 17px;
        white-space: nowrap;
    }

    .tab-outer-time-team ul.tabs {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
    }

    .tab-outer-time-team ul.tabs::-webkit-scrollbar {
        display: none;
    }
}

@media only screen and (max-width: 387px) {
    .tab-outer-time-team ul.tabs {
        max-width: 1000px;
        overflow: scroll;
        padding-bottom: 15px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
    }

    .tab-outer-time-team ul.tabs::-webkit-scrollbar {
        display: none;
    }

    .tab-outer-time-team ul.tabs li {
        white-space: nowrap;
    }

    .tab-outer-time-team ul.tabs li {
        margin-right: 20px;
        font-size: 16px;
    }
}