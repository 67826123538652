.navbar-lf h1 {
    margin: 0;
    color: #405BFF;
    font-weight: 500;
    font-size: 33px;
    line-height: 33.66px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
}

.navbar-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 53px 19px 44px;
    border-bottom: 1px solid #D8DDE1;
}

.navbar-rt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-noti {
    border-right: 1.8px solid #D8DDE1;
    margin-right: 24px;
    padding: 11px 24px 11px 0;
    position: relative;
}

.user-img {
    position: relative;
    padding-right: 25px;
    cursor: pointer;
}

.user-img::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 11px;
    height: 8px;
    background-image: url('../../images/user/ar-rt.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s ease;

}

.inner-profile {
    position: absolute;
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 0px 0px 8px rgba(106, 121, 136, 0.3);
    padding: 10px 17px 20px 17px;
    border-radius: 10px;
    right: -30px;
    min-width: 224px;
    top: 57px;
    z-index: 9;
}

.user-img img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: contain;
}

.inner-profile a {
    display: inline-block;
    width: 100%;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: #6A7988;
    text-decoration: none;
    white-space: nowrap;
    padding-bottom: 13px;
    border-bottom: 0.5px solid #D8DDE1;
    padding-top: 15px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: left;
    box-sizing: border-box;
}

.inner-profile:after {
    width: 15px;
    height: 15px;
    content: "";
    position: absolute;
    background: #FFFFFF;
    top: -8px;
    right: 28px;
    border-left: 0.2px solid rgba(106, 121, 136, 0.7);
    border-top: 0.2px solid rgba(106, 121, 136, 0.7);
    transform: rotate(45deg);
    /* box-shadow: 0px 0px 8px rgba(106, 121, 136, 0.3); */
}

.inner-profile a:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.user-inner {
    position: relative;
}

.user-sec.active .user-img::after {
    transform: rotate(180deg);
    transition: transform 0.3s ease;


}

@media screen and (max-width: 1220px) and (min-width: 992px) {
    .date-range-new{
        /* z-index: 9999 !important; */
    }
    .navbar-outer{
        z-index: 999 !important;
    }
}
@media screen and (min-width: 992px) {
    img.image-for-mob-only-nav1 {
        display: none;
    }
    .mobile-menu-new{
        display: none;
    }
    .page-top-section-header{
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .image-for-mob-only-nav1 {
        display: none;

    }
    .mobile-menu-new .sidebar-outer{
        /* position: fixed;
        max-height: 10000px;
        overflow: auto; */
        height: fit-content;
        border: 0;
        width: 100%;
    }
    .mobile-menu-new .inner-profile{
        padding: 0 15px;
    }
    .mobile-menu-new .main-menu-outer {
        display: initial;
    }
    .inner-profile{
        width: 100vw;
    }
    .mobile-menu-new .main-menu-outer ul {
        width: 100%;
    }
    .mobile-menu-new .own-name{
        color: #282828;
        font-size: 17px;
        font-weight: 400 !important;
        line-height: 20.74px;
        letter-spacing: 1px;
        padding-left: 13px !important;
        margin-bottom: 19px !important;
    }
    .main-menu-outer ul li:hover{
        background-color: transparent;
    }
    .mobile-menu-new .own-name.own-name-client{
        margin-top: 42.59px !important;
        font-weight: 400 !important;
        margin-bottom: 6px !important;
        /* padding-bottom: 18px;
        border-bottom: 0.5px solid #D8DDE1; */
        margin-right: 0;
    }
    .mobile-menu-new .main-menu-outer ul li a.active{
        background: transparent;
        border-radius: 0;
    }
    .mobile-menu-new .inner-profile{
        padding-top: 33px;
    }
    .mobile-menu-new .main-menu-outer ul {
        display: block;
        margin-top: 0;
    }
    .mobile-menu-new .main-menu-outer ul li a{
        padding-bottom: 17.5px;
        padding-top: 17.5px;
    }
    .main-menu-inner {
        margin-right: 15px;
    }
    .mobile-menu-new .main-menu-outer{
        padding: 0;
    }
    .page-top-section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .inner-page-head {
        display: flex;
        grid-gap: 22px;
        color: #282828;
        font-size: 19px;
        font-weight: 600;
        line-height: 23.18px;
    }
    .inner-page-head svg,
    .inner-page-head svg rect{
       stroke: #405BFF;
    }
    .page-top-section-right {
        color: #6A7988;
        font-size: 17px;
        font-weight: 300;
        line-height: 20.74px;
        letter-spacing: 1px;
    }
    .page-top-section-right {
        color: #6A7988;
        font-size: 17px;
        font-weight: 300;
        line-height: 20.74px;
        letter-spacing: 1px;
    }
    .inner-profile .mobile-menu-new {
        padding-top: 23px;
    }
    .logo-main-nav-text-m11 {
        display: none;
    }

    .image-for-mob-only-nav1 {
        display: block;

    }

    .navbar-inner {
        width: fit-content;
    }

    .navbar-inner {
        padding: 12px 18px 19px 26px;
        width: auto;


    }

    .inner-profile {
        height: 100vh;
        top: 63px;
        border-radius: 0px;
        z-index: 100;
        box-shadow: none;
        border-top: 0px;
        right: -19px;



    }

    .inner-profile:after {
        display: none;
    }

    body.open-menu-mob .content-outer:before {
        content: "";
        background: #000000;
        width: 100%;
        left: 0;
        top: -100px;
        height: 1002vh;
        z-index: 9999;
        position: absolute;
        opacity: .4;
    }

    body.open-menu-mob .content-outer {
        position: relative;

    }

    body.open-menu-mob {
        overflow: hidden;
    }

    .nav-noti {
        position: initial;
    }

    .navbar-rt {
        position: relative;
    }

    .notification-dropdown {
        height: 100vh;
        right: -18px !important;
        top: 65px !important;
        border-radius: 0 !important;
        z-index: 100;
        min-width: 312px !important;
        margin-top: 0px !important;
    }

    .notification-dropdown:before {
        display: none;


    }

    .inner-profile a {
        font-weight: 300;
    }

    .nav-noti {
        border-right: none;

    }

    .inner-profile a:last-child {
        padding-bottom: inherit;
        border-bottom: 0.5px solid #D8DDE1;
    }

    .nav-noti {
        margin-right: 4px;
    }

    .user-img {
        padding-right: 21px;

    }


}