/* .onboarding-sidebar-outer{
padding-top: 35px;
padding-left: 38px;
width: 34.5%;
background-color: #FBFBFD;
} */
.onboarding-sidebar-outer {
    padding-top: 35px;
    padding-left: 38px;
    width: 34.5%;
    background-color: #FBFBFD;
    height: 100vh; /* Make the sidebar take the full viewport height */
    position: sticky; /* Optional: to keep the sidebar fixed when scrolling */
    top: 0; /* Required for the sticky position */
  }
.onboard-logo {
    margin-bottom: 85px;
    text-align: left;
}
.sidebar-contents-inner {
    text-align: left;
    padding-left: 42px;
    padding-bottom: 45px;
    position: relative;
}
.sidebar-contents-inner::before {
    content: "";
    position: absolute; 
    left: 0; 
    top: 11px; 
    transform: translateY(-50%); 
    height: 25px; 
    width: 25px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27' fill='none'%3E%3Cpath d='M8 13.2667L11.8261 17L19 10' stroke='%2378828D' stroke-width='2'/%3E%3Ccircle cx='13.5' cy='13.5' r='12.5' stroke='%2378828D' stroke-width='2'/%3E%3C/svg%3E");
    background-repeat: no-repeat; 
    background-position: center;
    background-size: cover;
  }
.sidebar-single-point{
color: #78828D;
font-size: 19px;
font-weight: 400;
line-height: 23.18px;
margin-bottom: 3px;;
}
.sidebar-contents-inner.active .sidebar-single-point {
color: #282828; 
}
.sidebar-contents-inner.activated .sidebar-single-point {
color: #282828; 
}
.sidebar-contents-inner.active::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27' fill='none'%3E%3Cpath d='M8 13.2667L11.8261 17L19 10' stroke='%23405BFF' stroke-width='2'/%3E%3Ccircle cx='13.5' cy='13.5' r='12.5' stroke='%23405BFF' stroke-width='2' fill='none'/%3E%3C/svg%3E");
}
.sidebar-contents-inner.activated::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27' fill='none'%3E%3Ccircle cx='13.5' cy='13.5' r='12.5' fill='%23405BFF' stroke='%23405BFF' stroke-width='2'/%3E%3Cpath d='M8 13.2667L11.8261 17L19 10' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
}
  
.sidebar-single-point-content {
    color: #78828D;
    font-size: 16px;
    font-weight: 300;
    line-height: 19.52px;
}

@media screen and (max-width: 991px) {
    .mobile-hide-logo-secsl {
        display: none;
    }
    

}

@media screen and (max-width: 767px) {
    .onboarding-sidebar-outer {
        padding-top: 28px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: auto;
        background-color: #FBFBFD;
    }
    .onboard-logo {
        margin-bottom: 30px !important;
        padding: 30px 20px;
        background: #fff;
        margin: 0 -20px;
        text-align: center;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;
    }
    .onboarding-sidebar-outer{
        margin-top: 84.2px;
    }
}
@media screen and (max-width: 1200px) and (min-width: 768px) {
.onboarding-sidebar-outer {
    padding-top: 35px;
    padding-left: 25px;
    padding-right: 25px;
    width: 45.5%;
}
}