.tab-outer-tab-account .tabs-content.account-settings-tab .company-profile {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    margin-bottom: 50px;
    margin-top: 29px;
}
.tab-outer-tab-account ul.tabs {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding-left: 32px;
    padding-top: 23px;
    border-bottom: 1px solid #E8E8E8;
    background-color: #fff;
    grid-gap: 53px;
}
.tab-outer-tab-account ul.tabs li{
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #6A7988;
    font-weight: 300;
    padding-bottom: 11px;
    /* margin-right: 46px; */
    cursor: pointer;
    white-space: nowrap;
}
.tab-outer-tab-account ul.tabs li.active-tab{
    color: #282828;
    font-weight: 500;
    border-bottom: 4px solid #405BFF;
}
.content-outer.account.settings {
    padding-left: 0;
    padding-right: 0;
}
.tab-outer-tab-account ul.tabs {
    max-width: 100000px;
    overflow: auto;
}
.content-outer.profile.settings {
    padding-right: 0;
    padding-left: 0;
}
.tab-outer-tab-account .tabs-content.account-settings-tab{
    margin-top: 0px;
}
.general-setting-option,
.security-setting-option,
.notification-option{
    margin-top: 40px;
}
.content-outer.profile.settings .account-details {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    margin-bottom: 50px;
}
.security-outer.noti-outer {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 16px !important;
}
.security-outer.noti-outer:last-child{
    margin-bottom: 0 !important;
}
.security-inner.noti-enable {
    padding-left: 36px;
    padding-right: 13px;
    border-bottom: 1px solid #E8E8E8;
}
.security-inner.noti-enable.noti-head-noti span.custom-switch-description{
    color: #282828;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.security-inner.noti-enable span.custom-switch-description{
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.security-inner.noti-enable{
    margin-left: 15px;
    margin-right: 26px;
}
.security-inner.noti-enable.noti-head-noti{
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 44px;
}
.security-inner.noti-enable .custom-dropdown-user-new-outer.custom-dropdown-select {
    width: 229px !important;
}
.prferences .security-outer.noti-outer{
    padding-bottom: 34px;
}
.prferences .security-outer.noti-outer.profile-edit-profile{
    padding-bottom: 27px;
}
.general-setting-option .security-outer.noti-outer {
    margin-bottom: 24px !important;
}
.content-outer.Profile.Settings {
    padding-left: 0;
    padding-right: 0;
}
@media only screen and (max-width: 1439px){
    .tab-outer-tab-account .tabs-content.account-settings-tab .company-profile,
    .content-outer.profile.settings .account-details {
        padding: 0 38px;
    }
}
@media only screen and (min-width: 1440px){
    .tab-outer-tab-account .tabs-content.account-settings-tab .company-profile,
    .tab-outer-tab-account .tabs-content.account-settings-tab .security-outer,
    .content-outer.profile.settings .account-details {
        /* width: 1122px; */
        margin: 0 32px;
    }
}
@media only screen and (max-width: 1439px) and (min-width: 992px){
    .tab-outer-tab-account .account-settings-tab {
        margin: 0 38px;
    }
}
@media only screen and (max-width: 479px){
    .tab-outer-tab-account ul.tabs li{
        margin-right: 0;
        font-size: 17px;
    }
    .tab-outer-tab-account ul.tabs{
        padding-left: 17px;
        padding-right: 17px;
        justify-content: space-between;
    }
    .tab-outer-tab-account ul.tabs li:last-child{
        margin-right: 0;
    }
}
@media only screen and (max-width: 767px){
    .tab-outer-tab-account ul.tabs li{
        margin-right: 32px;
        font-size: 17px;
        white-space: nowrap;
    }
}
@media only screen and (max-width: 991px){
    .tab-outer-tab-account ul.tabs {
        max-width: 1000px;
        overflow: scroll;
        padding-bottom: 0px;
        grid-gap: 30px;
        margin-right: -12px;
        margin-left: -12px;
    }  
    .tab-outer-tab-account .tabs-content.account-settings-tab .security-outer{
        padding: 0 38px;
    }
    .tab-outer-tab-account ul.tabs li{
        white-space: nowrap;
    }
    .tab-outer-tab-account ul.tabs li {
        margin-right: 0px;
        font-size: 17px;
    }
    .tab-outer-tab-account ul.tabs::-webkit-scrollbar {
        display: none;             /* width of the entire scrollbar */
      }
      
      .tab-outer-tab-account ul.tabs::-webkit-scrollbar-track {
        display: none;
      }
      
      .tab-outer-tab-account ul.tabs::-webkit-scrollbar-thumb {
        display: none;
      }
      .tab-outer-tab-account .tabs-content.account-settings-tab .company-profile {
        background: transparent;
        border: none;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 50px;
        margin-top: 29px;
        padding: 0;
    }
    .tab-outer-tab-account .tabs-content.account-settings-tab .company-profile,
    .tab-outer-tab-account .tabs-content.account-settings-tab .security-outer,
    .content-outer.profile.settings .account-details {
        padding: 0;
    }
    .tab-outer-tab-account .profile-details .profile-photo-outer {
        /* flex-direction: column;
        margin: 29px 15px 24px 15px;
        justify-content: flex-start;
        text-align: left;
        align-items: baseline; */
    }
}