
.content-outer.Personnel.Reports {
    padding-left: 0;
    padding-right: 0;
}
.content-outer.Personnel.Reports .tab-inner-time {
    padding: 0 42px;
}
.reporting-timeoff-request-outer-page .outer-report-graph-piechart{
    display: grid;
    grid-template-columns: 1fr;
}
.reporting-timeoff-request-outer-page  tbody.p-datatable-tbody>tr td:nth-child(5){
    font-weight: 300;
}

.reporting-timeoff-request-outer-pager .button-sec-invoice {
    background: #6479F8;
    border-radius: 6px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 16.58px 15px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 123px;
}
.reporting-timeoff-request-outer-page .button-sec-invoice{
    display: flex;
    justify-content: right;
    margin-top: 24px;
}

@media only screen and (min-width: 1154px){
    .reporting-timeoff-request-outer-page .repot-tatal-box-sec{
    display: grid;
    /* grid-template-columns: repeat(4, 1fr) !important; */
}
}