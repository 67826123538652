.inner-overview-hours {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 22px 23px;
}

.inner-overview-hours .overview-item {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 14px 21px 24px 24px;
    position: relative;
}

.inner-overview-hours .overview-title-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    text-align: left;
}

.inner-overview-hours .overview-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #454F59;
}

.inner-overview-hours .overview-icon {
    display: flex;
}

.inner-overview-hours .overview-rice {
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #282828;
}

.inner-overview-hours {
    margin-bottom: 16px;
}

tr.member-extra {
    background: rgb(245 246 248 / 57%) !important;
}
.extra-drop-hide{
    visibility: hidden;
}
.member-name-extra span{
    background: transparent !important;
}
tr.member-extra td {
    /* border-bottom: 0.5px solid #999999; */
}
tr.member-extra .extra-detail .member-name.member-name-extra img,
tr.member-extra .extra-detail .member-name.member-name-extra .value-field{
    opacity: 0.6;
}
tr.member-extra .extra-detail .member-name.member-name-extra .value-field.full-text{
    opacity: 1; 
}

.extra-drop img {
    width: 12px;
    margin-left: 5px;
    display: none;
}
.delete-tracker-details{
    color: #282828;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    background-color: transparent !important;
    border: none !important;
}
.bulk-edit-outer{
    position: relative;
    height: 70.5px;
    padding-top: 16px;
    padding-bottom: 10px;
}
.bulk-edit {
    display: flex;
    grid-gap: 9px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 27px;
    border-bottom: 0.5px solid #d8dde1;
    position: absolute;
    width: 100%;
}
.extra-drop span {
    background: #D1D8FF;
    color: #405BFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 1px;
    border-radius: 5px;
    min-width: 22px;
    min-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.extra-drop span:hover {
    background-color: #6479f8;
    color: #fff;
}

.extra-detail {
    display: flex;
    align-items: center;
    grid-gap: 12px;
}

tr.member-extra .member-name span {
    background: #eee;
}

@media only screen and (max-width: 1100px) and (min-width: 768px) {
    .inner-overview-hours {
        grid-template-columns: 1fr 1fr;
    }

    .tab-inner-time .overview-graph-time {
        width: 100%;
    }

    .piechart {
        width: 50%;
        height: 100%;
    }

    .graph-main-outer-time {
        height: fit-content !important;
    }
}

@media only screen and (max-width: 768px) {
    .inner-overview-hours {
        grid-template-columns: 1fr;
    }

    .tab-inner-time .overview-graph-time {
        width: 100%;
    }

    .piechart {
        width: 100%;
    }

    .graph-main-outer-time {
        height: fit-content !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 660px) {
    .piechart {
        width: 50% !important;
    }
}

@media only screen and (max-width: 1220px) and (min-width: 1101px) {
    .tab-inner-time .graph-piechart {
        display: grid !important;
        grid-template-columns: 2fr 1fr;
    }

    .tab-inner-time .overview-graph-time,
    .piechart {
        width: 100%;
    }
}