.ReactModal__Content.ReactModal__Content--after-open .center-button a,
.modal-btn-main.center-button .pre-center-button {
    background: #6479F8;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    height: 44px;
    padding: 0;
    width: 118px;
    border-radius: 6px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    margin: 0 auto;
}
.modal-btn-main.center-button {
    justify-content: center;
}
.default-modal-set .outter-modal-main-2 p{
    text-align: left;
    margin-bottom: 27px;
    margin-top: 30px;
}
.default-modal-set .modal-btn-main.center-button{
    justify-content: left;
    grid-gap: 15px;
    
}
.default-modal-set .modal-btn-main.center-button a{
    margin: 0;
}
.default-modal-set .outter-modal-main-2 {
    max-width: fit-content;
}
.modal.default-modal-set {
    padding: 0 10px;
}
.default-modal-set .center-button a {
    width: auto !important;
    padding: 10px 15px !important;
}