.client-activities-outer{
    margin-top: 17px;
    margin-bottom: 44px;
}
.client-activities-outer .client-profile-and-time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    padding-left: 20px;
}
.client-activities-outer .open-client-profile a{
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
    border-radius: 6px;
    background: #6479F8;
    padding: 13px 21px 14px 19px;
    width: fit-content;
    display: flex;
    height: 44px
}
.client-activities-outer .client-profile-and-time .date-picker-outer.date-picker-outer-all {
    width: 154px !important;
}
.client-activity-columns{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 22px;
    row-gap: 24px;
}
.activity-column {
    text-align: start;
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 14px 21px 15px 24px;
    min-height: 137px;
    }
.activity-with-image{
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 14px;
}
.activity-name{
    color: #454F59;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1px;
}
.activity-with-image img{
    width: 41px;
    height: 41px;
}
.activity-count{
    color: #282828;
    font-size: 33px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 1px;
}

@media screen and (max-width: 479px) {
    .client-activity-columns{
        display: grid;
        grid-template-columns:1fr;
        row-gap: 24px;
    }  
}
@media screen and (max-width: 767px)  and (min-width: 480px){
    .client-activity-columns{
        display: grid;
        grid-template-columns:1fr;
        row-gap: 24px;
    }   
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
    .client-activity-columns{
        display: grid;
        grid-template-columns:1fr 1fr;
        row-gap: 24px;
    }
}