.invoice-outer-invoice .recent-invoice-inner {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 27px 32px;
    margin-top: 24px;
}

.invoice-outer-invoice .recent-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #454F59;
    text-align: left;
    margin-bottom: 21px;
}

.invoice-outer-invoice .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 12px 0px;
    color: #343a40;
    background: #F5F6F8;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #6A7988;
    padding-top: 11px;
}

.invoice-outer-invoice .p-datatable .p-datatable-thead>tr>th:first-child {
    border-top-left-radius: 15px !important;
    padding-left: 22px;
    border-bottom-left-radius: 0px;
}

.invoice-outer-invoice .p-datatable .p-datatable-thead>tr>th:last-child {
    border-top-right-radius: 15px !important;
    padding-right: 22px;
}

.invoice-outer-invoice .p-datatable-table {
    border-spacing: 0;
    width: 100%;
}

tbody.p-datatable-tbody>tr td:first-child {
    padding-left: 22px;
    ;
}

.invoice-outer-invoice tbody.p-datatable-tbody>tr td:last-child {
    padding-right: 22px;
    ;
}

.invoice-outer-invoice tbody.p-datatable-tbody>tr td {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #282828;
    padding-top: 19px;
    padding-bottom: 22px;
    border-bottom: 0.5px solid #D8DDE1;
}

.invoice-outer-invoice .card-outer {
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding-bottom: 32px;
    background: #fff;
}

.invoice-outer-invoice .member-name {
    display: flex;
    align-items: center;
    /* position: relative; */
    width: fit-content;
}

/* .invoice-outer-invoice .member-name.active span {
    background: #fff;
    position: relative;
} */

/* .invoice-outer-invoice .member-name:hover span {
    position: absolute;
    left: 42px;
    background: #fff !important;
}
.invoice-outer-invoice .member-name span{
    position: absolute;
    left: 42px;
} */
.invoice-outer-invoice .member-name img {
    margin-right: 15px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    object-fit: contain;
}

.invoice-outer-invoice .p-datatable .p-datatable-thead>tr>th span.p-column-title {
    margin-right: 6px;
}

.invoice-outer-invoice tbody.p-datatable-tbody>tr td:nth-child(5) {
    font-weight: 300 !important;
}

.invoice-outer-invoice a.cta-link {
    background: #6479F8;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: inline-flex;
    letter-spacing: 1px;
    padding: 6px 15px 7px 14px;
    color: #FFFFFF;
}

.invoice-outer-invoice span.payment-status.pending {
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #E39C4F;
    background: #FDDFB5;
    border: 0.2px solid #695437;
    border-radius: 100px;
    padding: 6px 10px 8px 11px;
}

.invoice-outer-invoice span.payment-status.manual,
.invoice-outer-invoice span.payment-status.sucessfully-processed,
.delivered {
    padding: 7px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #70AD69;
    background: #D5F3D8;
    border: 0.2px solid #516D4D;
    border-radius: 100px;
}

.invoice-outer-invoice span.payment-status.payment-processeing {
    padding: 7px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #01a6ff;
    background: #9fe4fb;
    border: 0.2px solid #00047e;
    border-radius: 100px;
}

.invoice-outer-invoice span.payment-status.overdue {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #A74E4E;
    background: #FFBBBB;
    border: 0.2px solid #9A5757;
    border-radius: 100px;
    padding: 6px 10px 8px 11px;
}

.invoice-outer-invoice .recent-invoice-inner {
    overflow: scroll;
}

/* .invoice-outer-invoice .member-name:hover span {
    box-shadow: none;
    border-radius: 0;
    padding-right: 10px !important;
    border: 0 !important;
    padding: 0;
} */

.invoice-outer-invoice .p-datatable-wrapper {
    max-width: 1000000px;
    width: 100%;
    overflow: auto;
    white-space: pre;
    border-radius: 15px;
}

.error {
    border-color: red !important;
    background-color: #fdf4f4 !important;
}

.errorDate {
    border-color: red !important;
    border-radius: 9px !important;
    background-color: #faf4f4 !important;
}

.selected-item {
    background-color: #f0f0f0; /* Add your preferred highlight color */
}  

@media only screen and (max-width: 667px) {}

@media only screen and (min-width: 1440px) {
    .content-outer.invoices .content-inner .react-outerme {
        width: 1200px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1440px) {
    .content-outer.invoices .content-inner .react-outerme {
        width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .invoice-outer-invoice .recent-invoice-inner {
        width: max-content;
    }

    .invoice-outer-invoice .p-datatable .p-datatable-thead>tr>th:last-child {
        border-bottom-right-radius: 0 !important;
    }

    .invoice-outer-invoice .recent-invoices {
        overflow: scroll;
    }

    .invoice-outer-invoice .p-datatable .p-datatable-thead>tr>th {
        max-width: 200px !important;
        width: 100%;
    }

    .invoice-outer-invoice .card-outer1 {
        overflow: auto;
        padding-right: 15px;
    }

    .invoice-outer-invoice .card-outer {
        width: max-content;
    }
}

@media screen and (max-width: 1300px) {
    .invoice-outer-invoice .p-datatable .p-datatable-thead>tr>th {
        max-width: 200px !important;
        width: 100% !important;
    }

    .invoice-outer-invoice .recent-invoices {
        overflow: scroll;
    }
}

.content-outer.invoices .invoice-outer-invoice {
    position: relative;
}

.content-outer.invoices .invoice-outer-invoice .recent-invoices-pagination {
    position: absolute;
    bottom: 0px;
    right: 0;
}

.invoice-outer-invoice .fixed-sec,
.invoice-outer-invoice .fixed-se {
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 99;
}

.invoice-outer-invoice .fixed-se1 {
    position: sticky;
    left: 157.81px;
    background: #fff;
    z-index: 999;
}

.invoice-outer-invoice .fixed-sec2 {
    position: sticky;
    left: 387.81px;
    background: #fff;
    z-index: 99;
}

.invoice-outer-invoice .fixed-sec3 {
    position: sticky;
    left: 509.54px;
    background: #fff;
    z-index: 99;
}

.invoice-outer-invoice .fixed-sec4 {
    padding-left: 3px;

}

.member-name span {
    width: 100%;
}

.invoice-outer-invoice tbody.p-datatable-tbody {
    background: #fff;
}

.invoice-outer-invoice .button-sec-invoice a {
    background: #6479F8;
    border-radius: 6px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 16.58px 15px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.invoice-outer-invoice .button-sec-invoice {
    display: flex;
    padding-top: 21px;
    margin-bottom: 0px;
    grid-gap: 20px;
    flex-direction: row;
}

.invoice-outer-invoice .button-sec-invoice a:hover {
    text-decoration: none;
    opacity: 0.8;
}

.invoice-outer-invoice .button-sec-invoice a:first-child,
.invoice-outer-invoice .button-sec-invoice a:nth-child(2) {
    /* margin-right: 14px; */
}

.invoice-outer-invoice .button-sec-invoice a:last-child {
    padding: 12px 21.02px 14px 15px;
}

span.icon-invoice img {
    margin-right: 8.5px;
}

.invoice-outer-invoice .button-sec-invoice a:first-child span.icon-invoice img {
    width: 15.5px;
    height: 19px;
}

.invoice-outer-invoice .button-sec-invoice a:nth-child(2) span.icon-invoice img {
    /* margin-right: 14.18px; */
    /* width: 11.82px;
    height: 15px; */
}

.invoice-outer-invoice .calendar-icon {
    position: relative;
    display: inline-block;
}

/* Adjust the icon size as needed */
.invoice-outer-invoice .calendar-icon img {
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.invoice-outer-invoice .invoice-filter {
    display: flex;
    justify-content: space-between;
    align-items: end;
    /* flex-wrap: wrap; */
    grid-gap: 0px 50px;
}

.invoice-outer-invoice .filter-inner {
    display: inline-grid;
    text-align: left;
}

.invoice-outer-invoice .filter-inner.showing-result {
    display: flex;
    white-space: nowrap;
    margin-top: 20px;
    align-items: center;
}

.invoice-outer-invoice .invoice-filter-inner,
.invoice-filter-inner-section {
    display: flex;
    grid-gap: 14px;
    width: 100%;
    overflow: visible;
    padding-right: 10px;
    /* padding-bottom: 8px; */
}

.invoice-outer-invoice .filter-invoice-right {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
}

.invoice-outer-invoice .filter-inner.showing-result label {
    margin-bottom: 0;
}

.invoice-outer-invoice td.amount-bold {
    font-weight: 500 !important;
}

.invoice-outer-invoice .invoice-filter label {
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #282828;
    margin-bottom: 7px;
}

.invoice-outer-invoice .filter-inner input,
.invoice-outer-invoice .filter-inner select {
    background-color: #FFFFFF !important;
    /* border: 0.2px solid #a2bedbb3; */
    border-radius: 5px;
    height: 43px;
    padding: 10px 16px 12px 16px;
    color: #78828D;
    font-size: 17px;
}

.invoice-outer-invoice .filter-inner.invoice-id input {
    max-width: 188px;
    min-width: 188px;
}

.invoice-outer-invoice .filter-inner.invoice-status select {
    max-width: 130px;
    min-width: 130px;
}

.invoice-outer-invoice .invoice-filter-top-date.invoice-filter-top-date.rs-picker.rs-picker-date.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-end.rs-picker-cleanable{
    max-width: 121px;
    min-width: 121px;
}

.invoice-outer-invoice .filter-inner.payment-status select {
    max-width: 136px;
    min-width: 136px;
}

.invoice-outer-invoice .filter-inner select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.invoice-outer-invoice .filter-inner.invoice-type select {
    max-width: 111px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.invoice-outer-invoice .filter-inner.user input {
    max-width: 198px;
    min-width: 198px;
}

.invoice-outer-invoice .filter-inner.showing-result select {
    min-width: 44px;
    height: 27px;
    padding: 1px 8px 4px 8px;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    color: #282828;
    border: 0.5px solid #B4B4B4;
    background-color: #FAFAFA !important;
    padding-right: 20px;
}

.invoice-outer-invoice .filter-inner.showing-result label:first-child {
    padding-right: 10px;
}

.invoice-outer-invoice .filter-inner.showing-result label:last-child {
    padding-left: 10px;
}

.invoice-outer-invoice .invoice-top {
    padding-bottom: 22px;
}

.invoice-outer-invoice .filter-inner.due-date input {
    /* font-size: 13px; */
    padding: 11px 9px 13px 12px;
    color: #858585;
    text-transform: lowercase;
}

.invoice-outer-invoice .filter-inner select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../images//icons/down-arrow.svg') no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    padding-right: 30px;
}

.invoice-outer-invoice .filter-inner.showing-result select {
    background: url('../../images//icons/down-arrow-gray.png') no-repeat;
    background-position: right 8px center;
}

/* For Firefox */
.invoice-outer-invoice .filter-inner.due-date::-ms-expand {
    display: none;
}

.invoice-outer-invoice .filter-inner.invoice-type select {
    width: 111px;
}

.invoice-outer-invoice {
    padding-bottom: 55px;
    margin-bottom: 50px;
}

.invoice-outer-invoice .filter-inner.due-date input {
    padding: 11px 12px 16px 36px !important;
    background-color: transparent !important;
}
.custom-picker-icon-all > svg {
    left: 10px !important; 
    top: 6px;
}
.custoom-icon-calender.custoom-icon-calender-all .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default {
    padding-right: 12px !important;
    padding-left: 36px !important;
}

.custoom-icon-calender.custoom-icon-calender-all svg.rs-picker-toggle-caret.rs-icon{
    display: none;
}
.custom-picker-icon.custom-picker-icon-all{
    z-index: 1;
}
.custoom-icon-calender.custoom-icon-calender-all .rs-picker-toggle-wrapper{
    width: 100%;
}
.custoom-icon-calender.custoom-icon-calender-all .rs-btn-close .rs-icon{
    margin-top: 5px !important;
}
.custoom-icon-calender.custoom-icon-calender-all .rs-picker-toggle,
.custoom-icon-calender.custoom-icon-calender-all input#date-range {
    padding-left: 42px !important;
    padding-right: 15px !important;
}
.overview-outer-time .filter-inner.due-date .custom-picker-icon.custom-picker-icon-all svg {
    left: 10px !important;
}
.overview-outer-time .filter-inner.due-date input#date-range{
    background-color: transparent !important;
    padding-right: 15px !important;
}
.rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default
.invoice-filter-top-date {
    width: 121px !important;
}

.invoice-filter-top-date .rs-picker-toggle.rs-btn.rs-btn-default {
    width: 100%;
}

.invoice-outer-invoice .filter-inner.due-date svg.rs-picker-toggle-caret.rs-icon {
    left: auto;
}

.invoice-outer-invoice .filter-inner.showing-result label {
    line-height: 14.64px;
    font-size: 12px;
}

.invoice-outer-invoice .rs-picker-toggle-read-only {
    border: 0 !important;
}

.outter-modal-main-2 {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
}

/* .ReactModal__Content.ReactModal__Content--after-open>div button {
    background: #6479F8;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    height: 44px;
    padding: 0;
    width: 118px;
    border-radius: 6px;
    margin-bottom: 0px;
   
} */

.modal-btn-main {
    display: flex;
    justify-content: space-between;
}

.ReactModal__Content.ReactModal__Content--after-open {
    max-width: 426px;
    height: max-content !important;
    min-height: 210px;
    width: 100% !important;
    border: none !important;
    border-radius: 15px !important;
    max-height: 80% !important;
}

.ReactModal__Content.ReactModal__Content--after-open>div {
    display: block;
}

.ReactModal__Content.ReactModal__Content--after-open p {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #282828;
    margin-bottom: 48px;

}

.outter-modal-main img {
    filter: grayscale(1);
    color: #000000;


}

.close-image-clr1 {
    display: flex;
    justify-content: flex-end;
}

h2.invoice-modal-main-head {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #282828;
    text-align: center;
}

.form-group-1modal {
    display: grid;
}

.form-group-1modal label {
    font-weight: 300;
    font-size: 17px;
    color: #6A7988;
    line-height: 21px;
    padding-bottom: 8px;
}

.select-modal-mainM {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 7px;
    width: 100%;
    height: 43px;
    padding-left: 16px;
    font-weight: 300;
    line-height: 23px;
    font-size: 19px;
}

.select-modal-mainM {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../images/singleinvoice/dropdown.svg') !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 19px) center !important;
    color: #282828;

}

.range-picinmodal-1 .rs-picker-toggle.rs-btn.rs-btn-default {
    width: 100%;
}

.range-picinmodal-1 svg.rs-picker-toggle-caret.rs-icon {
    left: unset;
}

button.create-button.modalsubmitbtn-1 {
    width: 100% !important;
}

.form-group-1modal.userseloutter-1 {
    padding-top: 32px;
}

.form-group-1modal.range-picinmodal-1 {
    padding-top: 25px;
}

.form-group-1modal.submittseloutter-1 {
    padding-top: 38px;
}

.range-picinmodal-1 input#date-range {
    padding: 0 16px !important;
    color: #282828;
}

button.generate-button.modalsubmitbtn-1 {
    width: 100% !important;
}

.rs-picker-daterange-calendar-group {
    height: auto !important;
}

.rs-picker.rs-picker-date.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-end.rs-picker-cleanable {
    z-index: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(174, 174, 176, 0.67) !important;
}

.outter-modal-main {
    min-height: 13px;
}

.outter-load-table {
    display: flex;
    justify-content: center;
}

.content-outer.invoices {
    min-height: 100vh;
}

.tran-outer {
    position: relative;
    /* display: contents; */
}
/* new filter */
.ReactModal__Content.ReactModal__Content--after-open.new-filter-modal {
    width: 100% !important;
    /* max-width: calc(100% - 332px); */
    max-width: 1115px;
    transform: translate(-39%, -50%) !important;
    padding: 28px 37px 28px 37px !important;
}
.new-nodal-invoice-outer-sec .invoice-filter-inner-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 33px 14px;
    overflow: visible;
    padding-right: 0 !important;
}
.new-nodal-invoice-outer-sec input {
    background-color: #FFFFFF !important;
    border: 0.2px solid #a2bedbb3;
    border-radius: 5px;
    height: 43px;
    padding: 10px 16px 12px 16px;
    color: #78828D;
    font-size: 17px;
}
.new-nodal-invoice-outer-sec .filter-inner {
    display: grid;
    grid-gap: 7px;
}
.new-nodal-invoice-outer-sec .selected-options1{
    max-width: 100% !important;
}
.new-modal-top-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.new-modal-heading{
    color: #282828;
    font-size: 22px;
    font-weight: 500;
    line-height: 22.44px;
}
.new-nodal-invoice-outer-sec .filter-buttons {
    display: flex !important;
    grid-gap: 33px !important;
    justify-content: flex-end;
}
.ReactModal__Content.ReactModal__Content--after-open.new-filter-modal button.clear-all{
    color: #6479F8;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    padding: 0;
    background: transparent;
    width: auto;
}
.ReactModal__Content.ReactModal__Content--after-open.new-filter-modal button.apply-filter {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 137px;
    height: 44px;
    grid-gap: 12px;
}
.ReactModal__Content.ReactModal__Content--after-open.new-filter-modal .filter-buttons {
    display: flex;
    grid-gap: 33px;
    justify-content: flex-end;
    margin-top: 38px;
}
.new-nodal-invoice-outer-sec .dropdown-list1{
    max-height: 150px;
}
.filter-invoice-right button {
    color: #6479F8;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    background: transparent;
    display: flex;
    align-items: center;
    grid-gap: 9px;
    flex-direction: row;
    white-space: nowrap;
    padding: 0;
    margin-bottom: 12px;
}
.new-top-flter {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;
    grid-gap: 10px;
}
.invoice-filter-inner-section.no-flter-all .date-picker-outer.date-picker-outer-all {
    width: 100% !important;
}
@media only screen and (max-width: 550px) {
    .filter-invoice-right button{
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and (max-width: 780px) {
    .ReactModal__Content.ReactModal__Content--after-open.new-filter-modal {
        height: 100% !important;
        max-height: calc(100% - 49px) !important;
    }
}
@media only screen and (width: 992px) {
    .ReactModal__Content.ReactModal__Content--after-open.new-filter-modal{
        max-width: 90%;
        transform: translate(-50%, -50%) !important;
    }
    .new-nodal-invoice-outer-sec .invoice-filter-inner-section {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (max-width: 991px) {
    .invoice-filter-inner-section{
        display: none !important;
    }
    .invoice-filter-inner-section.no-flter-all .date-picker-outer.date-picker-outer-all {
        width: 100% !important;
    }
    .new-nodal-invoice-outer-sec .invoice-filter-inner-section{
        display: grid !important;
    }
    .new-top-flter {
        flex-wrap: wrap;
    }
    .filter-invoice-right button {
        border-radius: 5px;
        border: 0.2px solid rgba(106, 121, 136, 0.70);
        background: #FFF;
        padding: 13px 126px;
        margin-right: 30px;
    }
    .invoice-outer-invoice .filter-invoice-right{
        width: 100%;
    }
    .invoice-outer-invoice .invoice-top{
        padding-left: 15px;
    }
    .invoice-outer-invoice .filter-invoice-right{
        justify-content: flex-start;
    }
    .ReactModal__Content.ReactModal__Content--after-open.new-filter-modal {
        width: 100% !important;
        max-width: 100% !important;
        transform: translate(0) !important;
        padding: 23px 24px 55px 24px !important;
        border-radius: 0 !important;
        top: 72px !important;
        /* height: 100% !important;
        max-height: calc(100% - 49px) !important; */
    }
    .new-nodal-invoice-outer-sec .invoice-filter-inner-section {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 33px 14px;
    }
    .new-nodal-invoice-outer-sec .custom-dropdown1{
        position: relative !important;
    }
    .new-nodal-invoice-outer-sec .dropdown-list1 {
        max-height: 150px;
        left: 0 !important;
        width: 100% !important;
        top: 53px !important;
    }
    .mobile-hide{
        display: none;
    }
}
@media only screen and (max-width: 1439px) and (min-width: 1238px){
    .invoice-filter-inner-section.no-flter-all .filter-inner:last-child {
        display: none;
    }
}
@media only screen and (max-width: 1237px) and (min-width: 1043px){
    .invoice-filter-inner-section.no-flter-all .filter-inner:last-child,
    .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(-n+2) {
        display: none;
    }
}
@media only screen and (max-width: 1042px) and (min-width: 992px){
    .invoice-filter-inner-section.no-flter-all .filter-inner:last-child,
    .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(-n+2),
    .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(-n+3) {
        display: none;
    }

}
@media only screen and (max-width: 1000px) and (min-width: 993px){
    .ReactModal__Content.ReactModal__Content--after-open.new-filter-modal {
        width: 100% !important;
        max-width: calc(100% - 332px);
        /* max-width: 70%; */
        transform: translate(-30%, -50%) !important;
        padding: 28px 37px 28px 37px !important;
    }
    .new-nodal-invoice-outer-sec .invoice-filter-inner-section {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 25px 14px;
        overflow: visible;
        padding-right: 0 !important;
    }
}
@media only screen and (max-width: 1100px) and (min-width: 1001px){
    .ReactModal__Content.ReactModal__Content--after-open.new-filter-modal {
        width: 100% !important;
        max-width: calc(100% - 332px);
        /* max-width: 70%; */
        transform: translate(-36%, -50%) !important;
        padding: 28px 37px 28px 37px !important;
    }
    .new-nodal-invoice-outer-sec .invoice-filter-inner-section {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 25px 14px;
        overflow: visible;
        padding-right: 0 !important;
    }
}
@media only screen and (max-width: 1400px) and (min-width: 1101px){
    .ReactModal__Content.ReactModal__Content--after-open.new-filter-modal {
        width: 100% !important;
        max-width: calc(100% - 332px);
        /* max-width: 70%; */
        transform: translate(-37%, -50%) !important;
        padding: 28px 37px 28px 37px !important;
    }
    .new-nodal-invoice-outer-sec .invoice-filter-inner-section {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 25px 14px;
        overflow: visible;
        padding-right: 0 !important;
    }
}
/* end new filter */
@media only screen and (max-width: 2420px) and (min-width: 1302px) {
    /* .invoice-outer-invoice .fixed-se1 {
    display: block
} */
}

@media only screen and (max-width: 1200px) {

    .invoice-outer-invoice .fixed-sec,
    .invoice-outer-invoice .fixed-se1,
    .invoice-outer-invoice .fixed-sec2,
    .invoice-outer-invoice .fixed-sec3 {
        position: initial;
    }
}

@media only screen and (max-width: 480px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 92% !important;
        max-width: 100% !important;
        transform: none !important;
        inset: auto !important;
        min-height: 180px !important;
        height: fit-content !important;
    }

    .invoice-outer-invoice .button-sec-invoice a:first-child {
        width: auto;
        height: 44px;
    }

    .invoice-outer-invoice .button-sec-invoice a:last-child {
        width: auto;
        height: 44px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 481px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        transform: none !important;
        inset: auto !important;
    }
}

@media only screen and (max-width: 991px) {

    /* .invoice-outer-invoice .button-sec-invoice {
        display: none;
    } */
    .invoice-outer-invoice .invoice-filter {
        flex-wrap: wrap;
    }

    .invoice-outer-invoice .button-sec-invoice {
        padding-top: 16px !important;
        overflow: auto;
        padding-bottom: 7px;
        padding-right: 12px;
    }

    .invoice-outer-invoice .fixed-sec,
    .invoice-outer-invoice .fixed-se1,
    .invoice-outer-invoice .fixed-sec2,
    .invoice-outer-invoice .fixed-sec3 {
        position: initial;
    }

    .invoice-outer-invoice .invoice-filter-inner {
        padding-bottom: 10px;
    }

    .invoice-outer-invoice .button-sec-invoice a {
        /* width: 100% !important; */
        display: inline-flex;
        white-space: nowrap;
    }

    .invoice-outer-invoice .filter-inner.showing-result {
        margin-top: 10px;
    }

    .invoice-outer-invoice .admin-outer.invoices .content-outer {
        background: #FBFBFD;
    }

    .invoice-outer-invoice .invoice-top {
        padding-top: 20px;
    }

    .invoice-outer-invoice .admin-outer.invoices .content-outer {
        padding-right: 0;
    }

    /* .invoice-outer-invoice .card-outer {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    } */
    .admin-outer.invoices .content-outer {
        padding-right: 0 !important;
    }

    /* .invoice-outer-invoice .p-datatable-wrapper,
    .invoice-outer-invoice .p-datatable .p-datatable-thead > tr > th:last-child{
        border-top-right-radius: 0;
    } */
    .invoice-outer-invoice a.editbtn-in-sle {
        padding: 0px !important;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .invoice-outer-invoice a.editbtn-in-slp {
        padding: 0px !important;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fixed-sec {
        max-width: 130px !important;
    }
}

@media only screen and (max-width: 1302px) {
    .invoice-outer-invoice .invoice-filter-inner {
        padding-bottom: 10px;
    }
}