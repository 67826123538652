.notification-option .security-outer.noti-outer {
    padding-top: 24px;
    padding-bottom: 34px;
}
.notification-accordion {
    text-align: left;
    padding: 0 38px;
}
.accordion-head {
    display: flex;
    grid-gap: 14.8px;
    margin-bottom: 18px;
    padding-left: 20px;
    color: #282828;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.4px;
    letter-spacing: 0.2px;
}
.accordion-title {
    color: #454F59;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.accordion-subtitle{
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.accordion-content{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 17px 26px 27px 15px;
    text-align: left;
    border: 0.2px solid #E8E8EA;
    margin-bottom: 62px;
}
.acoordion-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 50px;
    border-bottom: 1px solid #E8E8E8;
    padding: 0 15px 17px 39px;
    margin-bottom: 20px;
}
.acoordion-inner:last-child{
    margin-bottom: 0;
    border: none;
    padding-bottom: 0;
}
.accordion-trigger svg{
    transition: 0.5s;
}
.accordion-trigger.active svg {
    transform: rotate(180deg);
    transition: 0.5s;
}
@media only screen and (max-width: 550px)  {
    .notification-accordion {
        text-align: left;
        padding: 0 18px;
    }
    .acoordion-inner{
        padding-left: 0;
        padding-right: 0 ;
    }
}