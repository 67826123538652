.text-arrow .top-text{
    text-align: left;
    }
    .text-arrow .top-text {
    color: #282828;
    font-size: 18px;
    font-weight: 300;
    line-height: 21.96px;
    letter-spacing: 0.5px;
    }
    .text-arrow-outer{
    margin: 32px 11px;
    text-align: left;
    }
    .text-arrow-outer .text-arrow-inner{
    display: inline-block;
    }
    .text-arrow{
    display: flex;
    gap: 14px;
    }
    .client-name-section{
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #FFF;
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    padding: 24px 0px 17px 33px;
    margin-bottom: 80px;
    border-radius: 10px;
    border: 0.2px solid rgba(106, 121, 136, 0.2);
    }
    .content-outer.clients .dropdown-edit-profile{
        top: calc(100% + 13px);
    }
    .client-name-section .active-text{
    padding: 6px 14px;
    font-size: 14px;
    border-radius: 100px;
    color:#7CC273;
    background-color:#D5F3D8 ;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    margin-top: 13px;
    display: inline-flex;
    }
    .profile-section-outer .client-name-section {
        margin-bottom: 42px;
    }
  
    .client-name-section .client-right {
        text-align: left;
    }
    .client-name-section .client-text{
        color: #282828;
        font-size: 20px;
        font-weight: 500;
        line-height: 24.4px;
        letter-spacing: 1px;
    }
    .company-information-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 61px;
    padding: 0 43px;
    margin-top: 19px;
    margin-bottom: 26px;
    border-top: 1px solid #E8E8E8;
    }
    .company-information-section .text-section input{
        width: 100%;
        height: 43px;
        border: 0.5px solid #a9b3bc;
        border-radius: 8px;
        margin-left: 29px;
        color: #282828;
        font-size: 19px;
        font-style: normal;
        font-weight: 300;
        line-height: 23.18px;
        letter-spacing: 0.5px;
        padding: 0 16px !important;
        width: -webkit-fill-available;
    }
    .company-information-section .text-icon{
    display: flex;
    gap: 8px;
    margin-bottom: 18px;
    align-items: center;
    }
    .company-information-section .text-icon span{
        color: #6A7988;
        font-size: 17px;
        font-weight: 300;
        line-height: 20.74px;
        width: calc(100% - 29px);
        text-align: left;   
    }
    .company-information-section .outer-section{
    margin-top: 38px; 
    }
    .accordion-client-outer{
    margin: 30px 42px;
    padding-top: 20px;
    padding-bottom: 19px;
    border-radius: 20px;
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    background-color: #FFF;
    cursor: pointer;
    border: 0.2px solid rgba(106, 121, 136, 0.2);
    }
    .header-text:active{
        border-radius: 30px 30px 0px 0px;
    }
    .header-client-accordion span{
    padding-left: 39px;
    color: #454F59;
    font-size: 18px;
    font-weight: 500;
    line-height:21.96px;
    letter-spacing: 0.2px;
    }
    .header-client-accordion img{
    padding-right: 42px;   }
    .header-client-accordion{
        display: flex;
        justify-content: space-between;
    }
    .accordion-client-outer .total-section textarea{
        width: 92%;
        height: 65px;
        border: transparent;
        resize: none;
        color: #282828;
        font-size: 19px;
        font-style: normal;
        font-weight: 300;
        line-height: 23.18px;
        letter-spacing: 0.5px;
    }
    .accordion-client-outer .total-section{
    padding-top: 20px;
    }
    .accordion-client-outer .bottom-field{
    border-radius: 7px;
    }
    .company-information-section select.type-field{
        width: 418px;
        height: 43px;
        border: 0.5px solid #a9b3bc;
        border-radius: 8px;
        margin-left: 26px;
    }
    .company-information-section input.type-field {
        padding-left: 16px;
    }
    .accordion-client-outer .custom-dropdown1{
        width: 100%;
        height: 43px;  
        border-radius: 8px;
        /* margin-left: 26px; */
    }
    .button-section-outer .cancel-button{
    border-radius: 6px;
    border: 0.2px solid #282828;
    background: #F5F6F8;
    cursor: pointer;
    width: 86px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;   
    }
    .button-section-outer .cancel-button span{
    color: #282828;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;

    }
    .button-section-outer button{
    border-radius: 6px;
    border: 0.2px solid #7CC273;
    background: #6479F8;
    width: 151px;
    height: 44px; 
    
    }
    .button-section-outer .submit-button{
        display: flex;
    justify-content: center;
    gap: 13px;  
    align-items: center;
    }
    .button-section-outer .submit-button span{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height:17.08px;
    letter-spacing: 0.5px;
    }
    .client-name-section .remove-pic label img {
        width: 12px;
    }
    .client-name-section .remove-pic label{
        width: 22px;
        height: 22px;
    }
    .button-section-outer{
    display: flex;
    justify-content: center;
    gap: 17px;
    margin-top: 68px;
    }
    .profile-image img {
    width: 107px;
    height: 107px;
    border-radius: 50%;
    border: 0.5px solid #a9b3bc;
    object-fit: contain;
    }
     .profile-pic-edit {
    bottom: -5px;
    position: absolute;
    right: 5px;
    }
     .profile-photo-sec {
        position: relative;
    }
    .profile-section-outer{
        /* margin-left: 39px;
        margin-right: 38px; */
        padding-left: 39px;
        padding-right: 45px;
    }
    .accordion-client-outer .icon-image{
    width: 21px;
    text-align: right;
    }
    .accordion-client-outer input.search-bar {
        margin-left: 0;
        padding-left: 50px !important;
    }
    .accordion-client-outer .custom-dropdown-user-new-outer.custom-dropdown-select {
        margin-left: 26px;
        width: 100% !important;
        width: -webkit-fill-available !important;
    }
    .content-outer.clients {
        padding-left: 0;
        padding-right: 0;
    }
    .accordion-client-outer .PhoneInput {
        margin-left: 26px;
    }
    .accordion-client-outer input.PhoneInputInput {
        margin-left: 10px !important;
    }
    .outer-project-client-section {
        /* padding-left: 39px;
        padding-right: 50px; */
        padding-left: 39px;
        padding-right: 45px;
        
    }
    .outer-project-client-section .invoice-top {
        padding-left: 19px;
        padding-right: 14px;
        padding-top: 7px !important;
    }
    .outer-project-client-section .filter-inner.showing-result.show-result-team-fill {
        margin-top: 0;
    }
    .outer-project-client-section .overview-top-right {
        justify-content: right;
    }
    .outer-project-client-section .user-outer-timeoff .button-sec-invoice a {
        margin-right: 16px;
        padding: 13px 18px 14px 18px;
        justify-content: center;
        grid-gap: 14.5px;
    }
    .outer-project-client-section .user-outer-timeoff .button-sec-invoice a:last-child{
        margin-right: 0;
    }
    .outer-project-client-section .user-outer-timeoff .p-datatable .p-datatable-thead>tr>th:first-child {
        border-bottom-left-radius: 0 !important;
    }
    .user-outer-timeoff .p-datatable .p-datatable-thead>tr>th:last-child{
        border-bottom-right-radius: 0px !important;
    }
    .outer-project-client-section .user-outer-timeoff span.icon-invoice img {
        margin-right: 0;
    }
    .client-management-project .client-details-overview-outer {
        padding-left: 0;
    }
    .client-management-project{
        padding: 0 38px;
    }
    .btn-blue-sec{
        background: #6479F8;
    border-radius: 6px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 18px 15px 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    grid-gap: 14.5px;
}
.client-management-project .delete-client{
    padding: 12px 18px 15px 18px;
}
.inactive-text{
    margin-top: 13px;
}
.text-arrow-outer.text-arrow-outer-delete {
    display: flex;
    justify-content: space-between;
    align-items: end;
    grid-gap: 30px;
    flex-wrap: wrap;
    margin-right: 0;
    margin-top: 16px;
}
    /*************Responsive Styles*****************/
/***************iPhone Portrait**************/
@media only screen and (max-width: 479px) and (min-width: 220px){
 
.company-information-section .text-section input {
    width: 100%;
    height: 43px;
    margin: 0;
}
.company-information-section {
display: block;
padding: 15px;
}
.accordion-client-outer .custom-dropdown1 {
width: 100%;
height: 43px;
border-radius: 8px;
margin: 0;
}
.accordion-client-outer {
width: 95%;
margin: auto !important;
}
.profile-section-outer{
    margin: 0px;
}
}
/**********************iPhone Landscape*************/
@media only screen and (max-width: 767px) and (min-width: 480px){
 
    .company-information-section .text-section input {
        height: 43px;
        /* margin: 0; */
    }
    .company-information-section {
    display: block;
    padding: 30px;
    }
    .accordion-client-outer .custom-dropdown1 {
    height: 43px;
    border-radius: 8px;
    margin: 0;
    }
    .accordion-client-outer {
        width: 95%;
        margin: 30px auto !important;
    }
    .profile-section-outer{
        margin: 0px;
    }
}
/*************************iPad Portrait***************/
@media only screen and (max-width: 985px) and (min-width: 768px){
  
    .company-information-section .text-section input {
        width: 100%;
        height: 43px;
        margin: 0;
    }
    .company-information-section {
    display: block;
    padding: 30px;
    }
    .accordion-client-outer .custom-dropdown1 {
    width: 100%;
    height: 43px;
    border-radius: 8px;
    margin: 0;
    }
    .accordion-client-outer {
        width: 95%;
        margin: 30px auto !important;
    }
    
}
/**************iPad Landscape***************/
@media only screen and ( max-width: 1024px ) and (min-width: 986px){
  
.company-information-section {
    display: block;
    }
    .accordion-client-outer {
        width: 95%;
        margin: 30px auto !important;
        }
}
@media only screen and ( max-width: 1366px ) and (min-width: 1025px){
    .accordion-client-outer {
        width: 95%;
        margin: 30px auto !important;
        }
        .company-information-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        }
       
}
@media only screen and (min-width: 1367px){
    .company-information-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        }
        .invite-client-outer button {
            min-width: 183px;
        }
        /* .accordion-client-outer .custom-dropdown1 {
            width: 100% !important;
            width: -webkit-fill-available !important;
        }
        .company-information-section .text-section input {
            width: 100% !important;
            width: -webkit-fill-available !important;
        } */
}