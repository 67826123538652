.onboarding-page-outer {
    display: flex;
    align-items: flex-start;
    min-height: 100vh;
}
.detail-form-section-outer{
    padding-top: 86px;
    padding-bottom: 60px;
    padding-left: 91px;
    max-width: 509px;
    width: calc(100% - 34.5%);
}

@media screen and (max-width: 767px) {
 .onboarding-page-outer {
   display: block;
 }
}