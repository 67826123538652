a.cancelbtn-link {
    border: 0.2px solid #282828;
    border-radius: 6px;
    padding: 14px 15px 14px 16px;
    min-width: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
}

a.cancelbtn-link img {
    width: 10px;
    height: 10px;
    margin-right: 11px;
}

.fs-head-autoinvo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 22px;
    padding-top: 48px;

}

.editmanual-table-auto-inpu input {
    background: #FAFAFA;
}
.text-input{
    height: 43px;
    min-width: 131px;
    border-radius: 7px;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    margin-left: 0.5px;
}
.type-dropdown-em-text-input{
    align-items: center !important;
    
}
.read-only-input.text-input{
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-right: 10px;
}
.read-only-input {
    background-color: #fafafa !important;
    cursor: no-drop !important;
    color: rgba(40, 40, 40, 0.40);
    font-size: 17px;
    font-weight: 300;
    line-height: 20.74px;
}

.inp-sec2-with2-one {
    grid-template-columns: 1fr;
    padding-top: 0 !important;
}

.invoiceauto-date-range-picker input {
    border: 0.5px solid #B4B4B4;
    border-radius: 5px;
    width: 247px;
    color: rgba(40, 40, 40, 0.4);
    height: 42px;
    font-weight: 300;
    line-height: 21px;
    font-size: 17px;
    appearance: none;
    background-image: url(../../../images/icons/calendar-icon.svg);
    background-repeat: no-repeat;
    background-position: left 12px center;
    /* background-position: calc(100% - 15px) center !important; */
    padding-left: 40px;
    background-color: #fafafa;
    cursor: no-drop;
    background-size: 20px;
}
.profile-details-edit .inner-detail-inner input[readonly] {
    background: #fafafa !important;
    cursor: no-drop;
    color: rgba(40, 40, 40, 0.4) !important;
}
input[readonly]::placeholder {
    color: rgba(40, 40, 40, 0.4);
}
.information-right .inner-detail-inner input[type="date"]{
    /* background-image: url(../../../images/icons/calendar-icon.svg);
    background-repeat: no-repeat;
    background-position: left 12px center;
    padding-left: 40px; */
}
.invo-autodate-due-pic input {
    padding-left: 21px;
}

.auto-date-due-pic input {
    border: 0.5px solid #B4B4B4;
    border-radius: 5px;
    width: 143px;
    height: 40px;
    font-weight: 300;
    color: #282828;
    line-height: 21px;
    font-size: 17px;
    appearance: none;
    background-image: url(../../../images/icons/calendar-icon.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-position: calc(100% - 13px) center !important;
    padding-left: 12px;
}
.whitebg-sec,
input.whitebg-sec,
.invoice-table-editmanual input.whitebg-sec{
    background-color: #fff !important;
}