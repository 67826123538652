.popup-content {
    background-color: #fff;
    /* border: 0.2px solid #6a7988b3; */
    border-radius: 4px;
    border-radius: 10px;
    box-shadow: 0px 2px 16px rgba(75, 85, 110, 0.20);
    height: auto;
    margin-top: 6px;
    /* padding: 10px 7px 10px 10px; */
    position: absolute;
    right: -20px;
    text-align: left;
    top: 100%;
    width: 179px;
    z-index: 99;
    opacity: 1 !important;
    padding: 17px 18px;
}
.popup-content:after{
    background: #fff;
    border-left: 0.2px solid #6a798800;
    border-top: 0.2px solid #6a798800;
    content: "";
    height: 10px;
    position: absolute;
    right: 28px;
    top: -6px;
    transform: rotate(45deg);
    width: 10px;
}
.popup-content h6 {
    color: #282828;
    font-size: 11px;
    font-weight: 300;
    line-height: 13.42px;
    letter-spacing: 0.5px;
    margin-bottom: 7px;
}
.popup-content p{
    color: #6A7988;
    font-size: 10px;
    font-weight: 300;
    line-height: 12.2px;
    letter-spacing: 0.5px;
}
.popup-content p:last-child{
    margin-bottom: 0;
}
@media only screen and (max-width: 1200px) {
    .popup-content{
        right: 0;
    }
}