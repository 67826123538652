.profile-details-edit .profile-photo-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 38px 40px 24px 35px;
    padding-bottom: 41px;
    border-bottom: 1px solid #E8E8E8;
}

.profile-details-edit .profile-image img {
    width: 107px;
    height: 107px;
    border-radius: 50%;
}

.profile-details-edit .profile-photo-sec {
    position: relative;
}

.profile-details-edit .profile-pic-edit {
    position: absolute;
    bottom: -5px;
    right: 5px;
}

.profile-details-edit .profile-type {
    font-weight: 300;
    font-size: 16px;
    line-height: 19.52px;
    letter-spacing: 1px;
    color: #282828;
    margin-bottom: 12px;
    text-align: left;
}

.profile-details-edit .profile-status .active {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #7CC273;
    background: #D5F3D8;
    border: 0.2px solid #7CC273;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-details-edit .profile-status .inactive {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #96A8BA;
    background: #EAEAEA;
    border: 0.2px solid #6A7988;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
}

.profile-details-edit .profile-photo .profile-photo-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 23px;
}

.profile-details-edit .profile-name-sec {
    font-weight: 500;
    font-size: 20px;
    line-height: 24.4px;
    letter-spacing: 1px;
    color: #282828;
    margin-bottom: 6px;
}

.profile-details-edit .profile-pic-edit img {
    width: 29px;
    height: 29px;
}

.profile-details-edit a.edit-profile {
    display: flex;
    width: 132px;
    height: 44px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background: #6479F8;
    border-radius: 6px;
    grid-gap: 12.75px;
}

.profile-details-edit a.edit-profile img {
    Width: 21.23px;
    Height: 21.36px;
}

.profile-details-edit a.delete-profile img {
    width: 18.67px;
    height: 20px;
}

.profile-details-edit a.delete-profile {
    display: flex;
    width: 110px;
    height: 44px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08;
    letter-spacing: 0.5px;
    color: #282828;
    background: #F5F6F8;
    border: 0.2px solid #282828;
    border-radius: 6px;
    grid-gap: 14.33px;
}

.profile-details-edit .profile-button a:hover {
    opacity: 0.8;
}

.profile-details-edit .profile-button {
    display: flex;
    flex-direction: row;
    grid-gap: 19px;
}


.profile-details-edit .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.profile-details-edit .confirmation {
    background-color: #fff;
    padding: 50px;
    border-radius: 4px;
    text-align: center;
}

.profile-details-edit .confirmation p {
    margin-bottom: 30px;
    font-size: 18px;
}

.profile-details-edit .confirmation button {
    margin-right: 10px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #6479F8;
    color: #fff;
    cursor: pointer;
}

.profile-details-edit .success-message {
    background-color: #6479F8;
    color: #fff;
    padding: 20px;
    text-align: center;
    margin-top: 10px;
    border-radius: 4px;
    position: absolute;
    right: 42px;
    top: 15%;
}

.profile-details-edit .information-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 0.2px;
    color: #454F59;
    display: flex;
    margin: 28px 0;
}

.profile-details-edit .information-outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    grid-gap: 61px;
}

.profile-details-edit .information {
    padding: 0 39px 0px 44px;
}

.profile-details-edit .inner-detail label {
    font-weight: 300;
    font-size: 17px;
    line-height: 20.74px;
    display: flex;
    align-items: center;
    color: #6A7988;
}

.profile-details- .inner-detail label img {
    margin-right: 10px;
}

.profile-details-edit .inner-detail-outer {
    margin-bottom: 23px;
}

.profile-details-edit .inner-detail {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.profile-details-edit .inner-detail .icon {
    width: 26px;
}

.profile-details-edit .inner-detail-inner {
    width: 100%;
}

.profile-details-edit .inner-detail .icon {
    display: inline-grid;
    justify-content: center;
    margin-top: 5px;
}

.profile-details-edit .value-field {
    font-weight: 300;
    font-size: 19px;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    color: #282828;
}

.profile-details-edit .information-inner {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 7px;
    margin-bottom: 28px;
    margin-left: 35px;
    margin-right: 40px;
}

.profile-details-edit .information-inner:last-child {
    border: 0;
}

.profile-details-edit .inner-detail-inner input,
.profile-details-edit .inner-detail-inner select,
.form-field-client .PhoneInputInput,
.form-field-client .PhoneInput.PhoneInput--focus select {
    background-color: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 7px;
    width: 100%;
    height: 43px;
    padding: 8px 16px;
    font-weight: 300;
    font-size: 19px;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    color: #282828;
}

.profile-details-edit .inner-detail-inner select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../../images/icons/down-arrow.svg') no-repeat;
    background-position: right 14px center;
    cursor: pointer;
    padding-right: 40px;
}

.profile-details-edit .inner-detail-inner .rs-picker-toggle-wrapper {
    width: 100%;
}

.profile-details-edit .inner-detail-inner .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn.rs-btn-default {
    width: 100%;
}

.profile-details-edit .inner-detail-inner .rs-picker-toggle-wrapper input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
    border: 0;
}

.profile-details-edit .inner-detail-inner .rs-picker-toggle-wrapper input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
    padding-right: 52px !important;
    padding-left: 16px !important;
}

.profile-details-edit .inner-detail-inner .rs-picker-toggle-wrapper svg.rs-picker-toggle-caret.rs-icon {
    left: auto !important;
}

.profile-details-edit .inner-detail-inner .rs-picker-toggle-wrapper input.rs-picker-toggle-textbox {
    padding-right: 52px !important;
    padding-left: 16px !important;
    border: 0 !important;
    text-transform: lowercase;
}

.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-date-menu.rs-picker-menu {
    margin-top: 7px;
}

.profile-details-edit .save-button-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 13px;
    margin-bottom: 56px;
    ;
    padding-top: 8px;
}

.profile-details-edit .save-button-sec a:hover {
    opacity: 0.8;
}

.profile-details-edit a.cancel-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    color: #282828;
    width: 86px;
    height: 44px;
    border: 0.2px solid #282828;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F6F8;
}

.profile-details-edit a.save-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    width: 151px;
    height: 44px;
    background: #6479F8;
    border: 0.2px solid #7CC273;
    border-radius: 6px;
    grid-gap: 13px;
}

.save-button-sec button {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    width: 151px;
    height: 44px;
    background: #6479F8;
    border: 0.2px solid #7CC273;
    border-radius: 6px;
    grid-gap: 13px;
}
.favicon-text{
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
.profile-photo.profile-faviocn .profile-image {
    width: 79px;
    height: 79px;
    border-radius: 50%;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-photo.profile-faviocn .profile-image img{
    width: 44px;
    height: auto;
    object-fit: contain;
    border-radius: 0;
}
.profile-photo.profile-faviocn .profile-photo-inner{
    grid-gap: 26px;
}
.content-outer.Tenant.Management .content-inner .profile-details{
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    filter: drop-shadow(3px 3px 4px rgba(106, 121, 136, 0.07));
    border-radius: 15px;
    background: #fff;
    margin-top: 50px;
}


@media only screen and (max-width: 767px) and (min-width: 220px) {
    .profile-details-edit .profile-photo-outer {
        flex-direction: column;
        margin: 29px 15px 24px 15px;
    }
    .profile-photo-outer.edit {
        grid-gap: 20px;
    }
    .profile-details-edit .tab-outer ul.tabs li {
        font-size: 17px !important;
        margin-right: 27px !important;
    }
    .content-outer.Tenant.Management .content-inner .profile-details{
        margin-top: 0px;
    }

    .profile-details-edit .tab-outer ul.tabs li:last-child {
        margin-right: 0 !important;
    }

    .profile-details-edit .tab-outer ul.tabs {
        padding: 28px 17px 0 17px !important;
    }

    .profile-details-edit a.delete-profile {
        width: 132px;
    }

    .profile-details-edit .information {
        padding: 0 !important;
    }

    .profile-details-edit .information-inner {
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 4px;
    }

    .profile-details-edit .information-outer {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .profile-details-edit .inner-detail {
        margin-bottom: 9px;
    }

    .profile-details-edit .information-right .inner-detail:last-child {
        margin-bottom: 20px;
    }

    .profile-details-edit .profile-button {
        margin-top: 52px;
    }

    .profile-details-edit .inner-detail-outer {
        margin-bottom: 20px;
    }

    .profile-details .information-inner:last-child {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 479px) and (min-width: 220px) {
    .profile-details-edit .tab-outer ul.tabs {
        justify-content: space-between;
    }

    .profile-details-edit .profile-button {
        margin-top: 52px;
        ;
    }

    .profile-details-edit a.save-btn {
        width: 165px;
    }

    .profile-details-edit a.cancel-btn {
        width: 112px;
    }
}