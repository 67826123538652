.user-outer-overview .recent-invoice-inner {
background: #FFFFFF;
border: 0.2px solid rgba(106, 121, 136, 0.7);
box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
border-radius: 15px;
padding: 27px 32px;
margin-top: 24px;
}

.user-outer-overview .recent-title {
font-weight: 700;
font-size: 22px;
line-height: 27px;
color: #454F59;
text-align: left;
margin-bottom: 21px;
}

.user-outer-overview .p-datatable .p-datatable-thead>tr>th {
text-align: left;
padding: 12px 0px;
color: #343a40;
background: #F5F6F8;
font-weight: 500;
font-size: 17px;
line-height: 21px;
color: #6A7988;
padding-top: 11px;
}

.user-outer-overview .p-datatable .p-datatable-thead>tr>th:first-child {
border-top-left-radius: 10px !important;
padding-left: 15px;
border-bottom-left-radius: 10px;
}

.user-outer-overview .p-datatable .p-datatable-thead>tr>th:last-child {
border-top-right-radius: 10px !important;
padding-right: 22px;
border-bottom-right-radius: 10px !important;
}

.user-outer-overview .p-datatable-table {
border-spacing: 0;
width: 100%;
}

.user-outer-overview tbody.p-datatable-tbody>tr td:first-child {
padding-left: 15px;
}

.user-outer-overview tbody.p-datatable-tbody>tr td:last-child {
padding-right: 22px;
;
}

.user-outer-overview tbody.p-datatable-tbody>tr td {
font-weight: 300;
font-size: 18px;
line-height: 22px;
letter-spacing: 1px;
color: #282828;
padding-top: 20px;
padding-bottom: 22px;
border-bottom: 0.5px solid #D8DDEE;
}

.user-outer-overview tbody.p-datatable-tbody tr:first-child td {
padding-top: 27px !important;
}

.user-outer-overview .card-outer {
background: #fff;
padding: 20px 38px 0 32px;
border: 0.2px solid rgba(106, 121, 136, 0.7);
box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
border-radius: 15px;
}

.user-outer-overview .member-name {
display: flex;
align-items: center;
}

.user-outer-overview .member-name img {
margin-right: 15px;
width: 27px;
height: 27px;
border-radius: 50%;
object-fit: contain;
}

.p-datatable .p-datatable-thead>tr>th span.p-column-title {
margin-right: 6px;
}

.user-outer-overview a.cta-link {
background: #6479F8;
border-radius: 8px;
text-decoration: none;
font-weight: 300;
font-size: 15px;
line-height: 18px;
display: inline-flex;
letter-spacing: 1px;
padding: 6px 15px 7px 14px;
color: #FFFFFF;
}

.user-outer-overview span.payment-status.pending {
font-weight: 500;
font-size: 14px;
line-height: 122%;
letter-spacing: 0.4px;
color: #E39C4F;
background: #FDDFB5;
border: 0.2px solid #695437;
border-radius: 100px;
padding: 6px 10px 8px 11px;
}

.user-outer-overview span.payment-status.manual,
.user-outer-overview span.payment-status.sucessfully-processed {
padding: 7px 15px;
font-weight: 500;
font-size: 14px;
line-height: 122%;
letter-spacing: 0.4px;
color: #7CC273;
background: #D5F3D8;
border: 0.2px solid #516D4D;
border-radius: 100px;
}

.user-outer-overview span.payment-status.overdue {
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.4px;
color: #A74E4E;
background: #FFBBBB;
border: 0.2px solid #9A5757;
border-radius: 100px;
padding: 6px 10px 8px 11px;
}

.user-outer-overview .recent-invoice-inner {
overflow: scroll;
}


.user-outer-overview .p-datatable-wrapper {
max-width: 1000000px;
width: 100%;
overflow: auto;
white-space: pre;
}

.user-outer-overview .card-outer-user h3 {
font-weight: 600;
font-size: 22px;
line-height: 26.84px;
color: #454F59;
text-align: left;
margin-bottom: 21px;
}

.modal-close-invite.outter-modal-main {
display: flex;
justify-content: flex-end;
}

.modal-invite-head h2 {
font-weight: 500;
font-size: 22px;
line-height: 22px;
color: #282828;
padding-left: 10px;
}

.the-modal-inuser label {
font-weight: 300;
font-size: 17px;
line-height: 21px;
color: #6A7988;
}

.the-modal-inuser input {
background: #FFFFFF;
border: 0.2px solid rgba(106, 121, 136, 0.7);
border-radius: 7px;
width: 100%;
height: 43px;
color: #282828;
font-weight: 300;
font-size: 18px;
line-height: 22px;
padding-left: 15px;
margin-top: 10px;

}

.add-icon-main-text {
color: #282828;
font-weight: 300;
font-size: 14px;
line-height: 17px;
padding-left: 11px;

}

.add-email-sec-modal {
display: flex;
padding-top: 15px;
padding-bottom: 35px;
align-items: center;

}

.the-modal-inuser {
padding-top: 24px;
}

.invite-mail-popup-btn {
display: flex;
justify-content: flex-end;
}

a.btn-forpop-initem {
padding: 13px 18px 14px 14px;
}

.modal-top-section-user-invite {
display: flex;
justify-content: space-between;
margin-bottom: 6px;
padding-top: 21px;
position: sticky;
top: 0;
background-color: #fff;
z-index: 999;
}

.modal-top-section-user-invite .modal-close-invite.outter-modal-main.outter-modal-main-user a {
padding: 0 !important;
}

.modal-top-section-user-invite .modal-invite-head {
padding-top: 5px;
}

.ReactModal__Content.ReactModal__Content--after-open.user-invite-modal-main-outer {
/* width: 527px !important; */
max-width: 527px !important;
padding: 21px 26px 37px 26px;
max-height: 465px !important;
height: 90% !important;
overflow: auto;
padding-top: 0 !important;
}

.add-email-sec-modal {
/* border-top: 1px solid #E8E8E8; */
/* margin-top: 37px; */
padding-top: 26px;
padding-bottom: 64px !important;
}
.the-modal-inuser {
border-bottom: 1px solid #E8E8E8;
padding-bottom: 37px;
}

.user-add-outer-innner {
display: flex;
justify-content: space-between;
align-items: flex-start;
flex-wrap: wrap;
}

.user-add-outer-innner .invite-user {
max-width: 428px;
padding-left: 10px;
width: 100%;
}

.invite-user-delete img {
cursor: pointer;
margin-top: 40px;
}

.add-email-sec-modal img {
padding-left: 10px;
}
.modal-close-invite.outter-modal-main.outter-modal-main-user {
padding-top: 0 !important;
}
.gray-label{
font-size: 14px;
opacity: 0.6;
margin-bottom: 7px;
display: block;
}
.content-outer.My.Team .invoice-outer-invoice .invoice-top {
flex-wrap: wrap;
}
.content-outer.My.Team .invoice-outer-invoice .search-sec.search-sec-user-overview{
padding-top: 21px;
}
@media screen and (max-width: 991px) {
.user-outer-overview .recent-invoice-inner {
    width: max-content;

}

.ReactModal__Content.ReactModal__Content--after-open.user-invite-modal-main-outer {
    max-height: fit-content !important;
    height: 70% !important;
}

.user-outer-overview .recent-invoices {
    overflow: scroll;
}

.user-outer-overview .p-datatable .p-datatable-thead>tr>th {
    width: 200px !important;

}
}

@media screen and (max-width: 530px) {
.user-add-outer-innner {
    flex-direction: row;
    grid-gap: 30px;

}

.user-add-outer-innner .invite-user {
    width: auto;
}

.user-add-outer-innner .invite-user {
    width: calc(100% - 60px);
}
}

@media screen and (max-width: 1300px) {
.user-outer-overview .p-datatable .p-datatable-thead>tr>th {
    width: 200px !important;

}

.user-outer-overview .recent-invoices {
    overflow: scroll;
}
}

.user-outer-overview tbody.p-datatable-tbody {
background: #fff;
}

.user-outer-overview .button-sec-invoice a {
background: #6479F8;
border-radius: 6px;
letter-spacing: 0.5px;
color: #FFFFFF;
font-weight: 500;
font-size: 14px;
line-height: 17px;
display: inline-flex;
justify-content: center;
align-items: center;
height: 44px;
}

.user-outer-overview .button-sec-invoice {
display: flex;
padding-top: 0;
margin-bottom: 0px;
}

.user-outer-overview .button-sec-invoice a:hover {
text-decoration: none;
opacity: 0.8;
}

.user-outer-overview .button-sec-invoice a:first-child {
margin-right: 16px;
padding: 13px 18px 14px 18px;
}

.user-outer-overview .button-sec-invoice a:last-child {
padding: 15px 14px 14px 14px;
}

.user-outer-overview span.icon-invoice img {
/* margin-right: 16.3px; */
}

.user-outer-overview .button-sec-invoice a:first-child span.icon-invoice img {
width: 13.69px !important;
height: 17.34px !important;
}

.user-outer-overview .button-sec-invoice a:last-child span.icon-invoice img {
width: 23px !important;
height: 15px !important;
}

.user-outer-overview .button-sec-invoice a:nth-child(2) span.icon-invoice img {
margin-right: 12px;
}

.user-outer-overview .calendar-icon {
position: relative;
display: inline-block;
}

/* Adjust the icon size as needed */
.user-outer-overview .calendar-icon img {
width: 20px;
height: 20px;
pointer-events: none;
}

.user-outer-overview .invoice-filter {
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
grid-gap: 0px 70px;
}

.user-outer-overview .filter-inner {
display: inline-grid;
text-align: left;
}

.user-outer-overview .filter-inner.showing-result {
display: flex;
white-space: nowrap;
margin-top: 20px;
}

.user-outer-overview .invoice-filter-inner {
display: flex;
grid-gap: 14px;
width: 1060px;
overflow: auto;
}

.user-outer-overview .filter-invoice-right {
display: flex;
text-align: right;
justify-content: flex-end;
align-items: center;
}

.user-outer-overview .filter-inner.showing-result label {
margin-bottom: 0;
}

.user-outer-overview td.amount-bold {
font-weight: 500 !important;
}

.user-outer-overview .invoice-filter label {
font-weight: 300;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 0.5px;
color: #282828;
margin-bottom: 7px;
}

.user-outer-overview .filter-inner input,
.user-outer-overview .filter-inner select {
background: #FFFFFF;
border: 0.2px solid rgba(106, 121, 136, 0.7);
border-radius: 5px;
height: 43px;
padding: 10px 16px 12px 16px;
color: #78828D;
font-size: 17px;
}

.user-outer-overview .filter-inner.invoice-id input {
min-width: 110px;
}

.user-outer-overview .filter-inner.invoice-status select {
min-width: 130px;
}

.user-outer-overview .filter-inner.due-date input {
min-width: 121px;
}

.user-outer-overview .filter-inner.payment-status select {
min-width: 136px;
}

.user-outer-overview .filter-inner select {
padding-top: 0 !important;
padding-bottom: 0 !important;
}

.user-outer-overview .filter-inner.invoice-type select {
min-width: 111px;
padding-top: 0 !important;
padding-bottom: 0 !important;
}

.user-outer-overview .filter-inner.user input {
min-width: 198px;
}

.user-outer-overview .filter-inner.showing-result select {
min-width: 44px;
height: 27px;
padding: 1px 8px 4px 8px;
width: 100%;
font-weight: 500;
font-size: 11px;
color: #282828;
}

.user-outer-overview .filter-inner.showing-result label:first-child {
padding-right: 10px;
}

.user-outer-overview .filter-inner.showing-result label:last-child {
padding-left: 10px;
}

.user-outer-overview .invoice-top {
padding-bottom: 22px;
}

.user-outer-overview .filter-inner.due-date input {
font-size: 13px;
padding: 11px 9px 13px 12px;
color: #858585;
}

.user-outer-overview .filter-inner select {
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background: url('../../../images//icons/down-arrow.svg') no-repeat;
background-position: right 8px center;
cursor: pointer;
padding-right: 30px;
}

.user-outer-overview .filter-inner.showing-result select {
background: url('../../../images//icons/down-arrow-gray.png') no-repeat;
background-position: right 8px center;
}

/* For Firefox */
.user-outer-overview .filter-inner.due-date::-ms-expand {
display: none;
}

.user-outer-overview .filter-inner.invoice-type select {
width: 111px;
}

.user-outer-overview {
padding-bottom: 135px;
}

.user-outer-overview span.payment-status.active {
font-weight: 500;
font-size: 14px;
line-height: 17.08px;
letter-spacing: 0.4px;
color: #7CC273;
background: #D5F3D8;
border: 0.2px solid #7CC273;
border-radius: 100px;
width: 67px;
height: 31px;
display: flex;
justify-content: center;
align-items: center;
padding-bottom: 2px;
}

.user-outer-overview .accout-status-outer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    }
.user-outer-overview span.account-status.active{
color: #7CC273;
text-align: left;
font-size: 14px;
font-weight: 500;
line-height: 122%;
letter-spacing: 0.4px;
}
.accout-status-outer span.inactive,
.accout-status-outer span.Inactive {
    color: #96A8BA;
    font-size: 14px;
    font-weight: 500;
    line-height: 122%;
    letter-spacing: 0.4px;
    background: none;
    border: none;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
}

/* Outer container for the toggle switch */
.status-toggle-btn {
    position: relative;
    width: 60px;
    height: 33px;
    background-color: #E4E4E4;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* The toggle knob */
  .status-toggle-btn::before {
    content: "";
    position: absolute;
    width: 27px;
    height: 27px;
    background-color: white;
    border-radius: 50%;
    top: 3px;
    left: 11%;
    transition: transform 0.3s ease;
  }
  
  /* Active state */
  .account-status.active + .status-toggle-btn {
    background-color: #D5F3D8;
  }
  
  .account-status.active + .status-toggle-btn::before {
    transform: translateX(20px);
  }
  .outter-load-table-position{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .status-toggle-btn.loading{
    position: relative;

  }
.status-toggle-btn.loading::before{
    display: none;
}



.bulk-import{
margin-left: 16px;
}
.bulk-import-popup .modal-top-section-user-invite {
margin-bottom: 43px;
}
.bulk-import-popup .file-input-wrapper{
padding-left: 15px;
margin-bottom: 10px;
}
.text-sm.bulk-text {
margin-top: 10px;
}
.bulk-btn-section {
display: flex;
justify-content: center;
margin-top: 20px;
grid-gap: 16px;
}
.user-outer-overview span.payment-status.inactive,
.inactive {
font-weight: 500;
font-size: 14px;
line-height: 17.08px;
letter-spacing: 0.4px;
color: #96A8BA;
background: #EAEAEA;
border: 0.2px solid #6A7988;
border-radius: 100px;
width: 67px;
height: 31px;
display: flex;
justify-content: center;
align-items: center;
padding-bottom: 2px;
}


.user-outer-overview .invoice-top {
display: flex;
justify-content: space-between;
align-items: center;
padding-top: 21px;
padding-bottom: 30px;
}

.user-outer-overview .search-container input {
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background: url('../../../images/icons/search-icon.svg') no-repeat;
background-position: left 15px center;
cursor: pointer;
padding-left: 58px;
background-color: #EAEBEC;
border-radius: 10px;
width: 247px;
height: 45px;
border: none;
font-weight: 300;
font-size: 19px;
line-height: 23.18px;
letter-spacing: 0.5px;
color: #6A7988;
padding-right: 15px;
}

.user-outer-overview tbody.p-datatable-tbody>tr:last-child td {
border-bottom: none;
}

button.p-paginator-prev.p-paginator-element:after {
content: "Back";
color: #6479F8;
font-size: 15px;
font-style: normal;
font-weight: 300;
line-height: 18.3px;
letter-spacing: 1px;
}

button.p-paginator-prev.p-paginator-element svg path,
button.p-paginator-next.p-paginator-element svg path {
fill: #6479F8;
}

button.p-paginator-prev.p-paginator-element,
button.p-paginator-next.p-paginator-element {
background: transparent;
display: flex;
align-items: center;
grid-gap: 2px;
}

button.p-paginator-prev.p-paginator-element {
margin-right: 18px;
}

button.p-paginator-next.p-paginator-element {
margin-left: 18px;
}

button.p-paginator-first.p-paginator-element.p-link,
button.p-paginator-last.p-paginator-element.p-link {
display: none;
}

button.p-paginator-next.p-paginator-element:before {
content: "Next";
color: #6479F8;
font-size: 15px;
font-style: normal;
font-weight: 300;
line-height: 18.3px;
letter-spacing: 1px;
}

.user-outer-overview .card {
position: relative;
}

.user-outer-overview .recent-invoices-pagination {
position: absolute;
right: 0;
bottom: -75px;
}

.sprint-btn-outer.resend-invoice {
justify-content: center;
}

.sprint-btn-outer.resend-invoice button.submit-client.save-sprint {
width: auto !important;
padding: 0 20px !important;
}

button.del-resend {
background: transparent !important;
padding: 0 !important;
height: auto !important;
margin: 0 !important;
width: auto !important;
margin-top: 10px !important;
}

.items-center-with-delete {
display: flex;
align-items: center;
justify-content: space-between;
grid-gap: 20px;
}
.search-sec-user-overview {
text-align: right;
/* margin-bottom: 30px; */
}
.top-filter-client-status-user {
width: min-content;
margin-bottom: 22px;
}
.content-outer.Users .user-outer-overview .invoice-top {
padding-top: 28px;
padding-bottom: 23px;
}
/* .content-outer.My.Team {
padding-left: 0;
padding-right: 0;
} */
.content-outer.My.Team .user-outer-overview.invoice-outer-invoice{
padding-right: 38px;
padding-left: 38px;
}
@media only screen and (max-width: 667px) {
.user-outer-overview .invoice-top {
    flex-wrap: wrap;
    padding-right: 15px;
}
.search-sec-user-overview{
    /* padding-right: 15px; */
}
.content-outer.Users .custom-dropdown {
    padding-right: 0;
}

.user-outer-overview .button-sec-invoice {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.user-outer-overview .button-sec-invoice a {
    width: 48%;
}

.user-outer-overview .button-sec-invoice a:first-child {
    margin-right: 0;
}

.user-outer-overview .button-sec-invoice {
    margin-bottom: 13px;
    padding-bottom: 13px;
}

.user-outer-overview .search-sec,
.user-outer-overview .search-container input {
    width: 100%;
}

.user-outer-overview .invoice-top {
    padding-top: 28px;
    padding-bottom: 27px;
}

.user-outer-overview .admin-outer.list .content-outer {
    padding-right: 0;
}

/* .user-outer-overview .p-datatable-wrapper{
    max-width: 100% !important;
    overflow: initial;
} */

}
@media only screen and (max-width: 991px) and (min-width: 668px) {
.content-outer.Users .search-sec{
    padding-right: 15px;
}
}
@media only screen and (max-width: 1040px) and (min-width: 992px) {
.content-outer.Users {
padding-right: 10px;
padding-left: 10px;
}
.content-outer.My.Team .user-outer-overview.invoice-outer-invoice{
padding-right: 20px;
padding-left: 20px;
}
}
@media only screen and (max-width: 991px) {
.user-outer-overview .button-sec-invoice {
    /* display: none; */
}
.content-outer.My.Team .user-outer-overview.invoice-outer-invoice{
    padding-right: 0px;
    padding-left: 20px;
}
.content-outer.Users {
    padding-right: 0;
}
.tab-outer {
    margin-right: 15px;
}
.content-outer.My.Team {
    padding-right: 0;
}
.user-outer-overview .card-outer {
    padding-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.user-outer-overview {
    padding-bottom: 80px;
}

.user-outer-overview .invoice-filter-inner {
    padding-bottom: 10px;
}

.user-outer-overview .filter-inner.showing-result {
    margin-top: 10px;
}

.user-outer-overview .admin-outer.invoices .content-outer {
    background: #FBFBFD;
}

.user-outer-overview .invoice-top {
    padding-top: 20px;
    flex-wrap: wrap;
    grid-gap: 20px;
    padding-right: 15px;
}

.admin-outer.list .content-outer {
    padding-right: 0;
}

.user-outer-overview .card-outer1 {
    overflow: auto;
    padding-right: 0px;
    margin-right: 0px;
}

/* .user-outer-overview .card-outer {
    width: max-content;
    overflow: scroll;
} */
.user-outer-overview .p-datatable .p-datatable-thead>tr>th {
    width: auto !important;
}

.user-outer-overview .card-outer {
    padding-right: 0px;
}

.user-outer-overview .card {
    position: initial;
}

.user-outer-overview .recent-invoices-pagination {
    position: absolute;
    right: 0px;
    bottom: auto;
    /* transform: translate(15%, 38%); */
    padding-top: 30px;
}
.content-outer.My.Team .user-outer-overview .recent-invoices-pagination {
    transform: translate(0, 38%);
}
button.p-paginator-prev.p-paginator-element {
    margin-right: 0px;
}
button.p-paginator-next.p-paginator-element {
    margin-left: 0px;
}
}
@media only screen and (max-width: 550px) {
.top-filter-client-status.top-filter-client-status-user {
    padding-right: 15px;
}
}
@media only screen and (max-width: 450px) {
.recent-invoices-pagination .p-paginator-pages .p-paginator-page, .recent-invoices-pagination .p-paginator-pages .p-paginator-element{
    min-width: 28px !important;
    height: 30px !important;
    margin: 0 1px !important;
}
}
.resend-invitation input,
.resend-invitation select {
cursor: no-drop;
color: #282828 !important;
}
