.react-outer.report-expense{
    padding-left: 42px;
    padding-right: 42px;
}
.react-outer.report-expense .graph-main-outer-time.graph-main-outer-time-report {
    padding: 12px 20px 39px 18px;
    /* width: calc(100% - 25%); */
}
.react-outer.report-expense .pie-chart-right,
.react-outer.report-expense .pie-chart-right-no {
    width: calc(100% - 16px);
    margin-left: 16px;
}
.content-outer.Financial.Reports .graph-main-outer-time.graph-main-outer-time-report {
    width: 100%;
}
.modal.add-bank-account-content.account-details-modal-inner.report-grpah-filter-inner tbody.p-datatable-tbody>tr td:nth-child(5) {
    font-weight: 300;
}
td.amount-expense-recent {
    color: #282828 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 21.96px !important;
    letter-spacing: 1px !important;
}
.outer-report-graph-piechart.client-report-inner .graph-main-outer-time.graph-main-outer-time-report{
    overflow: auto;
}
.outer-report-graph-piechart.client-report-inner table.p-datatable-table td {
    white-space: nowrap;
}