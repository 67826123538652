.home-login-inner {
display: flex;
flex-wrap: wrap;
background: #FBFBFD;
}
.home-login-inner .lg-lft {
width: 50%;
}
.home-register-outer .lg-lft img {
  object-fit: cover;
  width: 100%;
    height: 100vh;
}
.home-login-inner .lg-lft img {
vertical-align: middle;
width: 100%;
height: 100vh;
object-fit: cover;
}
.home-login-inner .login-container .top-logo {
position: absolute;
top: 53px;
right: 58px;
}
.home-login-inner .login-container {
text-align: center;
margin: auto;
max-width: 453px;
width: 100%;
}

.home-login-inner .login-header{
color: #282828;
font-size: 33px;
font-weight: 500;
line-height: 102%;
letter-spacing: 0.4px;
margin-bottom: 41px;
text-align: left;
}

.home-login-inner .login-options {
display: flex;
flex-direction: column;
align-items: center;
}
.login-container .S9gUrf-YoZ4jf iframe {
    width: 100% !important;
    height: 60px !important;
}
.nsm7Bb-HzV7m-LgbsSe {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 114px 16px 122px;
    border-radius: 62px;
    border: 1px solid #78828D;
    background: #FFF;
    color: #282828;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 122%;
    letter-spacing: 0.5px;
    width: 100%;
    height: 60px;
}
.home-login-inner .nsm7Bb-HzV7m-LgbsSe{
display: inline-flex;
align-items: center;
justify-content: center;
padding: 16px 114px 16px 122px;
border-radius: 62px;
border: 1px solid #78828D;
background: #FFF;
color: #282828;
text-align: center;
font-size: 17px;
font-weight: 400;
line-height: 122%;
letter-spacing: 0.5px;
width: 100%;
height: 60px;
}
.home-login-inner .google-btn svg {
  width: 26px;
  height: 26px;
  margin-right: 12px;
}
.home-login-inner .google-btn{
display: inline-flex;
align-items: center;
justify-content: center;
padding: 16px 114px 16px 122px;
border-radius: 62px;
border: 1px solid #78828D;
background: #FFF;
color: #282828;
text-align: center;
font-size: 17px;
font-weight: 400;
line-height: 122%;
letter-spacing: 0.5px;
width: 100%;
}
.home-login-inner .email-input-btn {
width: 100%;
}
.home-login-inner .email-input-btn::placeholder {
color: #78828D;
}
.home-login-inner .email-input-btn {
display: inline-flex;
align-items: center;
justify-content: left;
padding: 15px 20px 17px 20px;
width: 100%;
margin: 0 1.5px;
border-radius: 7px;
border: 1px solid #6A7988;
background: #FFF;
color: #000;
font-size: 20px;
font-weight: 200;
line-height: normal;
}


.home-login-inner .google-btn .google-icon {
background-image: url('../../images/icons/Google-logo.svg'); 
background-size: cover;
width: 26px !important;
height: 26px !important;
margin-right: 13px;
}
.nsm7Bb-HzV7m-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK svg {
width: 26px;
height: 26px;
margin-top: -5px;
}

.home-login-inner .divider {
display: flex;
align-items: center;
justify-content: center;
margin: 43px 0 36px;
color: #282828;
font-size: 20px;
font-weight: 300;
line-height: normal;
}

.home-login-inner .divider:before {
content: "";
flex: 1 1;
height: 1px;
width: 61.5px;
background-color: #282828;
margin: 3px 23px 0 0;
}
.login-outer-forget .form-outer {
display: flex;
flex-direction: column;
gap: 12px;
}
.login-outer-forget .form-outer a{
border-radius: 6px;
background: #6479F8;
border: none;
color: #FFF;
text-align: left !important;
padding-left: 30px;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.5px;
width: 452px;
height: 44px;
position: relative;
display: flex;
  align-items: center;
}
.login-outer-forget .form-outer a::after {
    content: '→'; 
    color: #FFF;
    font-size: 17px;
    right: 30px;
    top: 12px;
    position: absolute;
    margin-left: 8px; 
    display: inline-block;
}

.home-login-inner .divider:after {
content: "";
flex: 1 1;
height: 1px;
width: 61.5px;
background-color: #282828;
margin: 3px 0px 0 23px;
}
.submit-button button {
border-radius: 6px;
background: #6479F8;
padding: 12px 62px 14px;
margin-top: 26px;
color: #FFF;
text-align: center;
font-size: 17px;
font-weight: 500;
line-height: 122%;
letter-spacing: 0.5px;
width: 100%;
margin: 26px 0 0 !important;
}
.home-login-inner .switch-btn {
font-size: 14px;
color: #0070f3;
background: none;
border: none;
cursor: pointer;
margin-top: 10px;
}
.home-login-inner .have-account {
display: flex;
align-items: center;
justify-content: center;
margin: 90px 0 16px;
color: #282828;
font-size: 16px;
font-weight: 300;
line-height: normal;
}
.home-login-inner .have-account a {
padding-left: 4px;
}


/* otp section */
.otp-outer-bg {
padding-top: 42px;
height:100vh;
background-image: url(../../images/background/OTP.png) !important;
}
.top-logo {
text-align: left;
margin: 0 0 177px 45px;
}
.otp-section-outer {
display: flex;
align-items: center;
justify-content: center;
margin-top: 0;
}
.otp-section-outer .outer-otp{
border-radius: 15px;
background: #FFF;
box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
padding: 34px 180px 71px;
width: fit-content;
margin: 0 auto;
}
.enter-otp-head {
color: #282828;
text-align: center;
font-size: 33px;
font-weight: 500;
line-height: 102%;
letter-spacing: 0.4px;
margin-bottom: 24px;
}
.enter-otp-subhead {
color: #78828D;
text-align: center;
font-size: 19px;
font-weight: 300;
line-height: 122%;
margin-bottom: 45px;
}
.otp-outer-bg input[type="number"] {
width: 45px;
height: 51px;
border: 1px solid #B4B4B4;
border-radius: 5px;
color: #282828;
text-align: center;
font-size: 22px;
font-weight: 500;
line-height: 122%;
}
.otp-section-inner {
gap: 29px;
display: flex;
align-items: center;
justify-content: center;
}
.opt-verify-button button {
border-radius: 6px;
background: #6479F8;
padding: 10px 25px 13px;
width: 217px;
color: #FFF;
text-align: center;
font-size: 17px;
font-weight: 500;
line-height: 122%;
letter-spacing: 0.5px;
margin-top: 51px;
}
.opt-verify-button{
  display: flex;
  align-items: center;
  justify-self: center;
}
.top-logo-mobile {
display: none;
}
.home-login-inner .mobile-lg-img {
display: none;
}



@media screen and (max-width: 985px){
.otp-outer-bg {
padding-top: 0;
}
.login-outer-forget .form-outer a{
width: 100%;
}
.home-login-inner .mobile-lg-img {
display: block !important;
}
.home-login-inner .desktop-lg-img {
display: none;
}
.home-login-inner .lg-lft img {
height: 100%;
}
.home-login-inner .lg-lft{
width: 100%;
}
.home-login-inner .login-container {
text-align: center;
padding: 0 39px 50px;
margin: aoto;
max-width: 100%;
width: 100%;
}
.home-login-inner .login-header {
font-size: 26px !important;
line-height: 30px !important;
margin-top: 18px;
text-align: center;
}
.home-login-inner .google-btn{
padding: 16px;
}
.home-login-inner .nsm7Bb-HzV7m-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK{
padding: 16px 24px;
}
.home-login-inner .divider {
margin: 35px 0 30px;
font-size: 14px;
}

.otp-section-outer {
margin-top: 100px;
}
.otp-section-outer .outer-otp {
padding: 30px;
margin: 0 20px;
width: 100%;
max-width: 627px;

}
.top-logo-mobile {
display: block !important;
}
.top-logo {
display: none;
}
.top-logo-mobile {
width: 100%;
padding: 24px 20px;
}
}
@media screen and (max-width: 350px){
  .otp-outer-bg input[type="number"]{
    width: 35px;
  }
  .otp-section-inner{
    grid-gap: 18px;
  }
}


/* register */
.top-logo-mobile {
display: none;
}
.home-register-outer .password-input-wrapper {
  position: relative;
  width: 100%;
  max-width: 450px;
}
.home-register-outer .password-input-wrapper img {
  position: absolute;
  top: 41%;
  right: 20px;
  cursor: pointer;
}
.home-register-outer {
display: flex;
flex-wrap: wrap;
background: #FBFBFD;
}
.home-register-outer .lg-lft {
width: 51%;
}
.home-register-outer img.mobile-lg-img {
display: none;
}
.home-register-outer .lg-lft img {
vertical-align: middle;
width: 100%;
height: 100vh;
}
.home-register-outer .home-register-form-section {
text-align: left;
margin: auto;
max-width: 500px;
margin-top: 169px;
}
.home-register-outer .top-logo {
position: absolute;
top: 53px;
right: 58px;
}
.home-register-outer .register-head {
color: #282828;
font-size: 33px;
font-weight: 500;
line-height: 102%;
letter-spacing: 0.4px;
margin-bottom: 28px;
}
.home-register-outer .input-outer {
display: flex;
align-items: flex-start;
gap: 6px;
flex-direction: column;
}
.home-register-outer label {
color: #282828;
font-size: 20px;
font-weight: 300;
line-height: normal;
margin: 0;
}
.home-register-outer input {
border: 1px solid #6A7988;
border-radius: 7px;
padding: 16px 20px;
background: #fff;
width: 100%;
max-width: 450px;
color: #000;
font-size: 20px;
font-weight: 400;
line-height: normal;
}
.home-register-outer input[type="email"] {
color: #78828D;
}

.create-acnt-button button {
color: #FFF;
text-align: center;
font-size: 17px;
font-weight: 500;
line-height: 122%;
letter-spacing: 0.5px;
border-radius: 6px;
background: #6479F8;
width: 100%;
max-width: 452px;
padding: 12px 20px 15px;
}

@media screen and (max-width: 985px){
.top-logo-mobile {
display: block;
}
.home-register-outer .lg-lft img {
vertical-align: middle;
width: 100%;
height: 100%;
}
.home-register-outer .lg-lft {
width: 100%;
}
.home-register-outer img.mobile-lg-img {
display: block;
}
.home-register-outer img.desktop-lg-img {
display: none;
}
.home-register-outer .home-register-form-section {
text-align: left;
max-width: 500px;
margin: auto; 
margin-top: 0;
padding: 0 20px 50px;
}
.home-register-outer .register-head {
text-align: center;
font-size: 33px;
font-weight: 500;
line-height: 102%;
margin-top: 18px;
}
.button-link {
  display: inline-flex;
  align-items: center;
  background-color: #6479F8;
  color: #FFF;
  padding: 12px 20px;
  border-radius: 6px;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.button-link:hover {
  background-color: #5468d4; /* Darker shade on hover */
}

.button-link span {
  margin-right: 10px;
}

.button-link::after {
  content: '→'; /* Adds arrow */
  font-size: 17px;
  margin-left: 5px;
}
}