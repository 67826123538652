.tab-outer {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    margin-bottom: 50px;
}
.tab-outer ul.tabs {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding-left: 32px;
    padding-top: 23px;
    border-bottom: 1px solid #E8E8E8;
}
.tab-outer ul.tabs li{
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 1px;
    color: #6A7988;
    font-weight: 300;
    padding-bottom: 11px;
    margin-right: 46px;
    cursor: pointer;
}
.tab-outer ul.tabs li.active-tab{
    color: #282828;
    font-weight: 500;
    border-bottom: 4px solid #405BFF;
}
/* .content-inner-inner {
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    filter: drop-shadow(3px 3px 4px rgba(106, 121, 136, 0.07));
    border-radius: 15px;
    background: #fff;
} */


@media only screen and (max-width: 479px){
    .tab-outer ul.tabs li{
        margin-right: 32px;
        font-size: 17px;
    }
    .tab-outer ul.tabs{
        padding-left: 17px;
        padding-right: 17px;
        justify-content: space-between;
        max-width: 100000px;
        overflow: auto;
    }
    .tab-outer ul.tabs li:last-child{
        margin-right: 0;
    }
    .tab-outer ul.tabs::-webkit-scrollbar {
        display: none;             /* width of the entire scrollbar */
      }
      
      .tab-outer ul.tabs::-webkit-scrollbar-track {
        display: none;
      }
      
      .tab-outer ul.tabs::-webkit-scrollbar-thumb {
        display: none;
      }
}
@media only screen and (max-width: 767px){
    .tab-outer ul.tabs li{
        margin-right: 32px;
        font-size: 17px;
        white-space: nowrap;
    }
}
@media only screen and (max-width: 387px){
    .tab-outer ul.tabs {
        max-width: 1000px;
        overflow: scroll;
        padding-bottom: 15px;
    }  
    .tab-outer ul.tabs li{
        white-space: nowrap;
    }
    .tab-outer ul.tabs li {
        margin-right: 20px;
        font-size: 16px;
    }
}