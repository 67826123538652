.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "halyard-display", sans-serif !important;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  background: #6479F8;
  border-radius: 6px;
  display: inline-block;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 12px 20px 15px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  margin-top: 30px;
}
.link_404:hover{
  color: #fff;
}
.contant_box_404 {
  /* margin-top: -50px; */
  text-align: center;
}
