.login-outer-forget .login-form-sec {
height: 100%;
display: flex;
flex-direction: column;
width: 100%;
justify-content: center;
align-items: center;
}
.login-outer-forget .login-inner .lg-rgt{
padding-left: 0;
}
.login-outer-forget .login-form-sec h3{
color: #282828;
font-size: 33px;
font-weight: 500;
letter-spacing: .4px;
line-height: 34px;
margin-bottom: 37px;
}
.login-outer-forget .login-form-sec p {
    color: #282828;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 122%;
    margin-bottom: 32px;
}
.button-container button {
    background: #6479f8;
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    height: 44px;
    letter-spacing: .5px;
    line-height: 21px;
    text-align: center;
    width: fit-content;
    min-width: 200px;
    padding: 0 70px;
}

@media only screen and (max-width: 985px) {
.login-outer-forget .login-inner .lg-rgt {
    padding:0 39px 50px;
}
.button-container {
    display: flex;
    gap: 22px;
    width: 100%;
    flex-direction: column;
}
.button-container button {
    width: 100%;
    padding: inherit;
}
}