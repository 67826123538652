@import url("https://fonts.googleapis.com/css?family=Varela+Round");

.page_404_chirstmas {
  position: relative;
  background-color: #6479f9;
  margin: 0;
  font-family: "Varela Round", sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.text-box {
  position: relative;
  overflow: hidden;
}

.text {
  position: relative;
  bottom: 50px;
}
.text__error {
  position: relative;
  bottom: 0;
  display: block;
  font-size: 25px;
  margin: 0;
  text-align: center;
  color: #fefefe;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.text__error:first-of-type {
  margin-bottom: 20px;
}
.text__error span {
  margin: 0 2px;
  font-weight: bold;
}
.text__error span:nth-child(1n) {
  color: #d40000;
}
.text__error span:nth-child(2n) {
  color: #fed429;
}
.text__error span:nth-child(3n) {
  color: #008002;
}

.status-code {
  position: relative;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  width: 515px;
  max-width: 595px;
  margin: auto;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}
.status-code p {
  font-size: 250px;
  margin: 3px 0 0 0;
  color: #fefefe;
  opacity: 0.8;
}

.zero {
  position: absolute;
  top: 48px;
  left: calc(50% - 85px);
  width: 170px;
  height: 190px;
}
.zero__top {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  z-index: 20;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.zero__top-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 100%;
  border: 33px solid #fefefe;
  border-radius: 50%;
  opacity: 0.8;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.zero__bottom {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.zero__bottom-inner {
  position: absolute;
  top: 0;
  right: 0;
  width: 170px;
  height: 100%;
  border: 33px solid #fefefe;
  border-radius: 50%;
  opacity: 0.8;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.house {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300px;
  height: 309px;
  z-index: 0;
  overflow: hidden;
}
.house__roof {
  position: absolute;
  top: 0;
  left: 0;
}
.house__roof--trapezoid {
  top: -30px;
  left: 121px;
  width: 112px;
  height: 45px;
  z-index: 8;
  background-color: #660f14;
}
.house__roof--trapezoid:before, .house__roof--trapezoid:after {
  top: 0;
  border-bottom: 45px solid #660f14;
  border-left: 6px solid transparent;
  border-right: 0 solid transparent;
}
.house__roof--trapezoid:before {
  left: -6px;
  border-left-width: 6px;
}
.house__roof--trapezoid:after {
  right: -20px;
  border-right-width: 20px;
}
.house__roof--trapezoid .snow {
  top: -2px;
  left: -2px;
  width: 120px;
  height: 20px;
  border-radius: 8px;
}
.house__roof--trapezoid .snow:before {
  top: 8px;
  left: 0;
  width: 65px;
  height: 28px;
  border-radius: 0 0 20px 40px;
}
.house__roof--trapezoid .snow:after {
  top: 11px;
  left: 58px;
  width: 66px;
  height: 26px;
  border-radius: 0px 10px 20px 40px;
  transform: rotate(-17deg);
}
.house__roof--triangle {
  width: 0;
  height: 0;
  border-bottom: 95px solid #fe9051;
  border-left: 135px solid transparent;
  border-right: 135px solid transparent;
}
.house__roof--triangle:before, .house__roof--triangle:after {
  width: 8px;
  height: 177px;
  border-radius: 10px;
  border: none;
  background-color: #660f14;
  z-index: 8;
}
.house__roof--triangle:before {
  top: -34px;
  left: -73px;
  transform: rotate(54deg);
}
.house__roof--triangle:after {
  top: -35px;
  left: 63px;
  transform: rotate(-54deg);
}
.house__roof--triangle .snow {
  width: 0;
  height: 0;
}
.house__roof--triangle .snow:before, .house__roof--triangle .snow:after {
  width: 10px;
  height: 128px;
}
.house__roof--triangle .snow:before {
  top: -30px;
  left: -48px;
  border-radius: 8px 8px 2px 8px;
  transform: rotate(45deg);
}
.house__roof--triangle .snow:after {
  top: -31px;
  left: 37px;
  border-radius: 8px 8px 8px 2px;
  transform: rotate(-45deg);
}
.house__bottom {
  position: absolute;
  bottom: 0;
  left: 35px;
  background-color: #c4996f;
  width: 225px;
  height: 150px;
}
.house__bottom:before, .house__bottom:after {
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 67px solid transparent;
}
.house__bottom:before {
  right: -20px;
  border-top: 150px solid #c4996f;
}
.house__bottom:after {
  left: -20px;
  border-top: 230px solid #c4996f;
}
.house__bottom .house__window {
  top: 50px;
  left: 135px;
  width: 78px;
  height: 50px;
}
.house__bottom .house__window:after {
  width: 78px;
  height: 4px;
}
.house__window {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #4f5a46;
  overflow: hidden;
  border-radius: 5px;
}
.house__window:before, .house__window:after {
  background-color: #c4996f;
  z-index: 10;
}
.house__window:before {
  top: 0;
  left: calc(50% - 2px);
  width: 4px;
  height: 50px;
}
.house__window:after {
  top: calc(50% - 2px);
  left: 0;
  width: 50px;
  height: 4px;
}
.house__door {
  position: absolute;
  bottom: 0;
  left: 24px;
  width: 60px;
  height: 95px;
  background-color: #660f14;
  z-index: 10;
}
.house__door:before {
  top: calc(50% - 4px);
  left: 4px;
  background-color: #a76c00;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}
.house__chimney {
  position: absolute;
  top: 5px;
  left: 106px;
  width: 55px;
  height: 90px;
  background-color: #660f14;
}
.house__chimney:before {
  top: -3px;
  left: -2px;
  width: 103%;
  height: 13px;
  border-radius: 7px;
  background-color: #fff;
}
.house__chimney .snow {
  width: 17px;
  height: 14px;
  top: 5px;
  left: -2px;
  border-radius: 0 30px 30px 30px;
}
.house__chimney .snow:before {
  width: 43px;
  height: 29px;
  top: -8px;
  left: 16px;
  border-radius: 0 10px 30px 30px;
}
.house--first-floor {
  left: calc(100% - 300px);
}
.house--first-floor .lease {
  top: 115px;
  left: 115px;
  z-index: 20;
}
.house--first-floor .house__bottom {
  background-color: #d97b09;
}
.house--first-floor .house__bottom .house__roof--triangle {
  top: -87px;
  left: -22px;
  border-bottom: 95px solid #d97b09;
  border-left-width: 135px;
  border-right-width: 135px;
}
.house--first-floor .house__bottom .house__roof--triangle:before {
  top: -34px;
  left: -73px;
  height: 177px;
}
.house--first-floor .house__bottom .house__roof--triangle:after {
  top: -35px;
  left: 63px;
  height: 177px;
}
.house--first-floor .house__bottom .house__roof--triangle .snow:before {
  top: -45px;
  left: -77px;
  height: 183px;
  transform: rotate(54deg);
}
.house--first-floor .house__bottom .house__roof--triangle .snow:after {
  top: -44px;
  left: 66px;
  height: 183px;
  transform: rotate(-54deg);
}
.house--first-floor .house__bottom:before {
  border-top-color: #d97b09;
}
.house--first-floor .house__bottom:after {
  left: -20px;
  border-top: 150px solid #d97b09;
  border-left: 20px solid transparent;
  border-right: 0 solid transparent;
}
.house--first-floor .house__bottom .house__window {
  left: 118px;
  width: 95px;
}
.house--first-floor .house__bottom .house__window:before, .house--first-floor .house__bottom .house__window:after {
  background-color: #d97b09;
}
.house--first-floor .house__bottom .house__window:after {
  width: 95px;
}
.house--first-floor .house__chimney {
  top: 71px;
  left: 32px;
  width: 75px;
}
.house--first-floor .house__chimney .snow {
  width: 43px;
  height: 27px;
  top: 2px;
  left: -2px;
  border-radius: 0 10px 30px 30px;
}
.house--first-floor .house__chimney .snow:before {
  width: 38px;
  height: 18px;
  top: 2px;
  left: 39px;
  border-radius: 10px 0 30px 30px;
}
.house--second-floor {
  width: 395px;
}
.house--second-floor .house__top {
  position: absolute;
  top: 90px;
  left: 35px;
  background-color: #c4996f;
  width: 118px;
  height: 80px;
}
.house--second-floor .house__top:before {
  top: 0;
  right: -8px;
  width: 0;
  height: 0;
  border-top: 80px solid #c4996f;
  border-right: 8px solid transparent;
  border-left: 0 solid transparent;
}
.house--second-floor .house__top .house__roof--triangle {
  top: -70px;
  left: -20px;
  border-bottom: 70px solid #c4996f;
  border-left-width: 73px;
  border-right-width: 73px;
}
.house--second-floor .house__top .house__roof--triangle:before {
  top: -19px;
  left: -44px;
  height: 120px;
  transform: rotate(45deg);
}
.house--second-floor .house__top .house__roof--triangle:after {
  top: -19px;
  left: 35px;
  height: 120px;
  transform: rotate(-45deg);
}
.house--second-floor .house__top .house__roof--triangle .snow:before {
  top: -30px;
  left: -48px;
  height: 128px;
  z-index: 10;
  transform: rotate(45deg);
}
.house--second-floor .house__top .house__roof--triangle .snow:after {
  top: -31px;
  left: 37px;
  height: 128px;
  transform: rotate(-45deg);
}
.house--second-floor .house__top .house__window {
  top: 0;
  left: 27px;
  width: 50px;
  height: 50px;
}

.snow {
  position: absolute;
  background-color: #fff;
  z-index: 10;
}
.snow:before, .snow:after {
  background-color: #fff;
  z-index: 10;
}

.tree {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.tree__body {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 22px solid #008002;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
.tree__body:before, .tree__body:after {
  width: 0;
  height: 0;
}
.tree__body:before {
  top: 10px;
  left: -25px;
  border-bottom: 27px solid #008002;
  border-left: 26px solid transparent;
  border-right: 25px solid transparent;
}
.tree__body:after {
  top: 23px;
  left: -30px;
  border-bottom: 30px solid #008002;
  border-left: 31px solid transparent;
  border-right: 31px solid transparent;
}
.tree__decoration {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: radial-gradient(#b0e2f9, #54bab6);
  transform: scale(1.6);
}
.tree__decoration:before {
  background: radial-gradient(#54bab6 0%, rgba(242, 191, 81, 0) 70%);
  border-radius: 50%;
  display: block;
  height: 8px;
  opacity: 0.5;
  width: 8px;
  -webkit-animation: decoration 2s 2s infinite;
  animation: decoration 2s 2s infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translateX(-5px) translateY(-5px) scale(1);
}
.tree__decoration:nth-of-type(2) {
  top: 11px;
  left: 24px;
}
.tree__decoration:nth-of-type(2):before {
  -webkit-animation: decoration 1s 3s infinite;
  animation: decoration 1s 3s infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.tree__decoration:nth-of-type(3) {
  top: 40px;
  left: 9px;
}
.tree__decoration:nth-of-type(3):before {
  -webkit-animation: decoration 2s 2s infinite;
  animation: decoration 2s 2s infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.tree__decoration:nth-of-type(4) {
  top: 26px;
  left: 18px;
}
.tree__decoration:nth-of-type(4):before {
  -webkit-animation: decoration 1s 3s infinite;
  animation: decoration 1s 3s infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.tree__decoration:nth-of-type(5) {
  top: 42px;
  left: 30px;
}
.tree__decoration:nth-of-type(5):before {
  -webkit-animation: decoration 2s 2s infinite;
  animation: decoration 2s 2s infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.cat {
  position: absolute;
  top: 0;
  right: 43px;
  opacity: 0;
}
.cat__face {
  position: absolute;
  top: 32px;
  left: 0;
  width: 18px;
  height: 16px;
  border-radius: 50%;
  background-color: #000;
}
.cat__face:before, .cat__face:after {
  border-bottom: 8px solid #000;
  border-right: 14px solid transparent;
  border-left: 2px solid transparent;
}
.cat__face:before {
  top: -3px;
  left: -1px;
  transform: rotate(-14deg);
}
.cat__face:after {
  top: 1px;
  left: 10px;
  transform: rotate(54deg);
}
.cat__body {
  position: absolute;
  top: 39px;
  left: 4px;
  width: 40px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
}
.cat__body:after {
  top: 0;
  left: 0;
  border-bottom: 8px solid #000;
  border-right: 14px solid transparent;
  border-left: 2px solid transparent;
}

.present {
  position: absolute;
  bottom: 0;
  left: 3px;
  width: 38px;
  height: 17px;
  background-color: #6b9c27;
  opacity: 0;
}
.present:before, .present:after {
  left: 16px;
  background-color: #d63527;
}
.present:before {
  top: 0;
  width: 5px;
  height: 17px;
}
.present:after {
  top: -10px;
  width: 6px;
  height: 38px;
  transform: rotate(90deg);
}
.present__ribbon:before, .present__ribbon:after {
  top: -6px;
  border-bottom: 6px solid #d63527;
}
.present__ribbon:before {
  right: 10px;
  border-right: 0px solid transparent;
  border-left: 10px solid transparent;
}
.present__ribbon:after {
  left: 10px;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
}

.lease {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border: 15px solid green;
  border-radius: 50%;
  background-color: transparent;
}
.lease__ribbon {
  position: absolute;
  top: 0;
  left: calc(50% - 4px);
  width: 8px;
  height: 7px;
  border-radius: 8px;
  background-color: #d63527;
}
.lease__ribbon:before, .lease__ribbon:after {
  top: -7px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.lease__ribbon:before {
  left: -10px;
  border-left: 16px solid #d63527;
}
.lease__ribbon:after {
  left: 2px;
  border-right: 16px solid #d63527;
}
.lease__decoration {
  position: absolute;
  top: 0;
  left: 0;
}
.lease__decoration-part {
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fed429;
}
.lease__decoration-part:before, .lease__decoration-part:after {
  top: 0;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #c74734;
}
.lease__decoration-part:nth-of-type(1) {
  top: -11px;
}
.lease__decoration-part:nth-of-type(1):before {
  top: 12px;
  left: -11px;
}
.lease__decoration-part:nth-of-type(1):after {
  content: none;
}
.lease__decoration-part:nth-of-type(2) {
  top: 20px;
  left: -10px;
}
.lease__decoration-part:nth-of-type(2):before {
  top: 12px;
  left: 12px;
}
.lease__decoration-part:nth-of-type(2):after {
  top: 10px;
  left: 33px;
  background-color: #fed429;
}
.lease__decoration-part:nth-of-type(3) {
  top: 1px;
  left: 31px;
}
.lease__decoration-part:nth-of-type(3):before {
  top: 16px;
  left: 5px;
}
.lease__decoration-part:nth-of-type(3):after {
  top: -13px;
  left: -13px;
}

.snowman {
  position: absolute;
  bottom: -32px;
  right: 0;
  width: 137px;
  height: 145px;
  z-index: 10;
  overflow: hidden;
}
.snowman__hat {
  position: absolute;
  top: -64px;
  left: 36px;
  width: 20px;
  height: 15px;
  background-color: #87d3dd;
  border-radius: 2px;
  z-index: 10;
  transform: rotate(15deg);
}
.snowman__hat:before, .snowman__hat:after {
  top: 0px;
  background-color: #fff;
}
.snowman__hat:before {
  width: 12px;
  height: 8px;
  left: 0px;
  border-radius: 5px 0 30px 30px;
}
.snowman__hat:after {
  width: 11px;
  height: 10px;
  left: 10px;
  border-radius: 0 16px 30px 30px;
}
.snowman__body {
  position: absolute;
  top: 105px;
  left: -9px;
  width: 85px;
  height: 66px;
  border-radius: 50%;
  background-color: #fff;
}
.snowman__body:before, .snowman__body:after {
  border-radius: 50%;
  background-color: #fff;
}
.snowman__body:before {
  top: -24px;
  left: 18px;
  width: 45px;
  height: 40px;
}
.snowman__body:after {
  top: -53px;
  left: 22px;
  width: 35px;
  height: 35px;
}
.snowman__face {
  position: absolute;
  top: -45px;
  left: 31px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  z-index: 10;
}
.snowman__face:before {
  top: 2px;
  left: 12px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  z-index: 10;
}
.snowman__face:after {
  top: 7px;
  left: -4px;
  width: 0px;
  height: 0px;
  border-top: 1px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 12px solid #fe9051;
  transform: rotate(10deg);
}
.snowman__hand {
  position: absolute;
  top: -28px;
  left: 15px;
  width: 3px;
  height: 15px;
  background-color: #8e6428;
}
.snowman__hand--left {
  transform: rotate(-62deg);
}
.snowman__hand--right {
  left: 63px;
  transform: rotate(62deg);
}
.snowman__scarf {
  position: absolute;
  top: -25px;
  left: 23px;
  width: 34px;
  height: 8px;
  border-radius: 4px 4px 8px 8px;
  background-color: #c74734;
  z-index: 10;
}
.snowman__scarf:before, .snowman__scarf:after {
  width: 9px;
  background-color: #c74734;
  border-radius: 4px;
}
.snowman__scarf:before {
  top: 5px;
  right: 3px;
  height: 19px;
}
.snowman__scarf:after {
  top: 1px;
  right: 4px;
  height: 23px;
  transform: rotate(15deg);
}
.snowman .hukidashi {
  top: 0;
  right: 2px;
  width: 100px;
  height: 53px;
}
.snowman .hukidashi:before {
  left: 23px;
  bottom: -2px;
  transform: rotate(0);
}
.snowman .hukidashi p {
  margin: 11px 0;
}

.santa {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 125px;
  height: 107px;
  z-index: 10;
}
.santa--walk {
  -webkit-animation: santa-walk 20s linear normal infinite;
  animation: santa-walk 20s linear normal infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa--walk .hukidashi {
  top: -23px;
  left: -31px;
  -webkit-animation: santa-walk-hukidashi 20s linear normal infinite;
  animation: santa-walk-hukidashi 20s linear normal infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa--walk .sweat {
  -webkit-animation: sweat 1s linear normal infinite;
  animation: sweat 1s linear normal infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa--walk .sweat:nth-of-type(2) {
  top: -1px;
  right: 20px;
  -webkit-animation: sweat2 1s linear normal infinite;
  animation: sweat2 1s linear normal infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa--sori {
  bottom: 0;
  left: 0;
  transform: rotateY(180deg);
}
.santa--sori:before, .santa--sori:after {
  bottom: 0;
  background-color: #115a8d;
}
.santa--sori:before {
  left: -10px;
  width: 126px;
  height: 30px;
  border-radius: 10px 50% 10px 50%;
  z-index: 10;
  transform: rotate(0);
}
.santa--sori:after {
  left: 70px;
  width: 50px;
  height: 53px;
  border-radius: 50% 10px 22px 10px;
  transform: rotate(8deg);
}
.santa--sori .santa__hand--left .santa__hand-inner {
  -webkit-animation: sori-santa-hand-right 1s linear alternate infinite;
  animation: sori-santa-hand-right 1s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa--sori .santa__hand--right {
  border-radius: 10px;
  height: 7px;
  transform: rotate(25deg);
  -webkit-animation: sori-santa-hand-left 1s linear alternate infinite;
  animation: sori-santa-hand-left 1s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa--sori .santa__hand--right:before {
  width: 8px;
  height: 7px;
  top: -2px;
  left: -6px;
  background-color: #000;
  border-radius: 50%;
  transform: rotate(10deg);
}
.santa--sori .santa__foot {
  display: none;
}
.santa--chimney {
  top: 15px;
  left: 26px;
  height: 55px;
  left: 8px;
  z-index: 0;
  -webkit-animation: santa-chimney 8s linear normal infinite;
  animation: santa-chimney 8s linear normal infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa--chimney:before, .santa--chimney:after {
  bottom: 0;
  left: 0;
  width: 10px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
  z-index: 20;
}
.santa--chimney:before {
  bottom: -4px;
  left: 33px;
}
.santa--chimney:after {
  bottom: -4px;
  left: 73px;
}
.santa--chimney .santa__face {
  left: 42px;
  transform: rotate(0);
}
.santa--chimney .santa__hat {
  transform: rotate(-9deg);
}
.santa--chimney .santa__beard {
  transform: rotate(-3deg);
}
.santa--chimney .santa__eyebrows--right {
  top: 1px;
  left: 24px;
}
.santa--chimney .santa__eye--right {
  left: 23px;
}
.santa--chimney .santa__eye--left {
  left: 4px;
}
.santa--chimney .santa__nose {
  left: 9px;
}
.santa--chimney .santa__cheek--right {
  top: 12px;
  left: 26px;
}
.santa--chimney .santa__body:before {
  content: none;
}
.santa--chimney .santa__hand {
  display: none;
}
.santa__hat-part {
  position: absolute;
  top: 7px;
  left: 31px;
  width: 43px;
  height: 58px;
  border-radius: 50%;
  background-color: #d63527;
  transform: rotate(28deg);
}
.santa__hat-part:before, .santa__hat-part:after {
  height: 7px;
  border-radius: 50%;
}
.santa__hat-part:nth-of-type(1):before {
  top: 9px;
  left: 45px;
  width: 7px;
  background-color: #fff;
}
.santa__hat-part:nth-of-type(1):after {
  top: 3px;
  left: 19px;
  width: 30px;
  background-color: #d63527;
  transform: rotate(22deg);
}
.santa__hat-part:nth-of-type(2) {
  position: absolute;
  top: 18px;
  left: 31px;
  width: 44px;
  height: 34px;
  border-radius: 50%;
  background-color: #fff;
  transform: rotate(12deg);
}
.santa__face {
  position: absolute;
  top: 25px;
  left: 37px;
  width: 31px;
  height: 17px;
  border-radius: 20px 20px 50% 50%;
  background-color: #fde2b7;
  z-index: 10;
  transform: rotate(10deg);
}
.santa__beard-part {
  position: absolute;
  top: 8px;
  left: -14px;
  width: 15px;
  height: 17px;
  border-radius: 50%;
  background-color: #fff;
}
.santa__beard-part:before, .santa__beard-part:after {
  background-color: #fff;
}
.santa__beard-part:before {
  top: 12px;
  left: 1px;
  width: 15px;
  height: 17px;
  border-radius: 50%;
}
.santa__beard-part:nth-of-type(2) {
  top: 16px;
  left: -8px;
  width: 26px;
  height: 30px;
}
.santa__beard-part:nth-of-type(2):before {
  top: 16px;
  left: 13px;
  width: 19px;
  height: 17px;
}
.santa__beard-part:nth-of-type(2):after {
  top: 1px;
  left: 13px;
  width: 19px;
  height: 17px;
}
.santa__beard-part:nth-of-type(3) {
  top: 16px;
  left: 14px;
  width: 27px;
  height: 28px;
}
.santa__beard-part:nth-of-type(3):before {
  top: -4px;
  left: 13px;
  width: 17px;
  height: 17px;
}
.santa__eyebrows {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 7px;
  border-radius: 50%;
  background-color: #fff;
}
.santa__eyebrows--left {
  top: 1px;
  left: 4px;
  transform: rotate(65deg);
}
.santa__eyebrows--right {
  top: 2px;
  left: 22px;
  transform: rotate(-65deg);
}
.santa__eye {
  position: absolute;
  top: 8px;
  left: 2px;
  width: 3px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
}
.santa__eye--left {
  top: 8px;
  left: 2px;
}
.santa__eye--right {
  top: 8px;
  left: 20px;
}
.santa__nose {
  position: absolute;
  top: 10px;
  left: 6px;
  width: 12px;
  height: 9px;
  border-radius: 50%;
  z-index: 10;
  background-color: #f7d194;
}
.santa__cheek {
  position: absolute;
  top: 10px;
  left: 6px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  z-index: 10;
  background-color: #f4cfe3;
}
.santa__cheek--left {
  top: 12px;
  left: -3px;
}
.santa__cheek--right {
  top: 13px;
  left: 22px;
}
.santa__body {
  position: absolute;
  top: 54px;
  left: 16px;
  width: 88px;
  height: 53px;
}
.santa__body:before {
  top: -23px;
  right: -10px;
  width: 53px;
  height: 51px;
  border-radius: 42% 50%;
  background-color: #f3f0f0;
  z-index: -1;
}
.santa__body-top {
  top: -3px;
  left: 10px;
  position: absolute;
  width: 45px;
  height: 39px;
  border-radius: 50% 50% 10% 10%;
  background-color: #d63527;
  z-index: 5;
}
.santa__body-top:before, .santa__body-top:after {
  height: 5px;
  background-color: #000;
  transform: rotate(1deg);
}
.santa__body-top:before {
  top: 28px;
  left: 0px;
  width: 45px;
}
.santa__body-top:after {
  top: 27px;
  left: 10px;
  width: 7px;
  border: 1px solid #fff;
  border-radius: 3px;
}
.santa__body-bottom {
  position: absolute;
  top: 18px;
  left: 10px;
  width: 44px;
  height: 23px;
  border-radius: 50%;
  background-color: #d63527;
}
.santa__foot {
  position: absolute;
  bottom: -7px;
  left: 23px;
  width: 10px;
  height: 17px;
  border-radius: 3px;
  background-color: #d63527;
  transform: rotate(0);
}
.santa__foot:before, .santa__foot:after {
  background-color: #000;
}
.santa__foot:before {
  bottom: -3px;
  left: 0;
  height: 7px;
}
.santa__foot:after {
  width: 15px;
  height: 6px;
  bottom: -5px;
  left: -3px;
  border-radius: 50% 50% 50% 4px;
}
.santa__foot--left {
  bottom: -7px;
  left: 6px;
  transform: rotate(0);
  -webkit-animation: santa-foot-left 0.8s linear alternate infinite;
  animation: santa-foot-left 0.8s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa__foot--left:before {
  width: 10px;
}
.santa__foot--left:after {
  width: 13px;
}
.santa__foot--right {
  width: 12px;
  bottom: -7px;
  left: 26px;
  transform: rotate(0);
  -webkit-animation: santa-foot-right 0.8s linear alternate infinite;
  animation: santa-foot-right 0.8s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa__foot--right:before {
  width: 12px;
}
.santa__foot--right:after {
  width: 15px;
}
.santa__hand {
  position: absolute;
}
.santa__hand--left {
  top: 5px;
  left: 19px;
  width: 33px;
  height: 30px;
  overflow: hidden;
}
.santa__hand--left .santa__hand-inner {
  position: absolute;
  top: 10px;
  left: 8px;
  width: 49px;
  z-index: 100;
  height: 7px;
  border-radius: 10px;
  background-color: #d63527;
  transform: rotate(12deg);
  -webkit-animation: santa-hand-left 1s linear alternate infinite;
  animation: santa-hand-left 1s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.santa__hand--left .santa__hand-inner:before {
  width: 8px;
  height: 7px;
  top: -2px;
  left: -6px;
  background-color: #000;
  border-radius: 50%;
  transform: rotate(25deg);
}
.santa__hand--right {
  top: 4px;
  left: 3px;
  width: 11px;
  height: 8px;
  border-radius: 50%;
  background-color: #d63527;
  transform: rotate(-60deg);
}

.sori-santa {
  position: absolute;
  top: -16px;
  width: 295px;
  height: 155px;
  transform: rotate(-1deg);
  -webkit-animation: sori-santa 8s linear normal infinite;
  animation: sori-santa 8s linear normal infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lead {
  position: absolute;
  top: 92px;
  left: 84px;
  width: 182px;
  height: 33px;
  overflow: hidden;
  z-index: 10;
  transform: rotate(0);
  -webkit-animation: sori-santa-lead-right 1s linear alternate infinite;
  animation: sori-santa-lead-right 1s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.lead--back {
  top: 85px;
  left: 105px;
  width: 149px;
  z-index: 0;
  transform: rotate(4deg);
  -webkit-animation: sori-santa-lead-left 1s linear alternate infinite;
  animation: sori-santa-lead-left 1s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.lead-inner {
  position: absolute;
  bottom: 0;
  left: -12px;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #fff;
  border-radius: 50%;
}

.sori {
  position: absolute;
  bottom: -12px;
  left: 0px;
  width: 145px;
  height: 11px;
  border-bottom: 5px solid #690e00;
  border-right: 5px solid #690e00;
  border-radius: 10px;
  z-index: 10;
  transform: rotate(-3deg);
}
.sori:before, .sori:after {
  width: 5px;
  background-color: #690e00;
}
.sori:before {
  top: 2px;
  left: 34px;
  height: 9px;
}
.sori:after {
  top: 3px;
  left: 108px;
  height: 8px;
}

.reindeer {
  position: absolute;
  width: 115px;
  height: 155px;
  right: 0;
  top: 20px;
  z-index: 0;
  transform: rotate(14deg);
}
.reindeer:before {
  top: 65px;
  left: 76px;
  width: 8px;
  height: 31px;
  background-color: #115a8d;
  z-index: 10;
  transform: rotate(-55deg);
}
.reindeer__face {
  position: absolute;
  width: 30px;
  height: 22px;
  top: 44px;
  left: 72px;
  border-radius: 10px 10px 50% 50%;
  background-color: #cca58b;
  transform: rotate(-3deg);
}
.reindeer__face:before {
  background-color: #cca58b;
  width: 29px;
  height: 16px;
  border-radius: 50%;
  top: 0px;
  left: 11px;
  transform: rotate(-49deg);
}
.reindeer__face:after {
  background-color: #000;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: -8px;
  left: 31px;
}
.reindeer__horn {
  position: absolute;
  width: 29px;
  height: 4px;
  top: -7px;
  left: -21px;
  border-radius: 2px;
  background-color: #f0dbc2;
  transform: rotate(38deg);
}
.reindeer__horn:before, .reindeer__horn:after {
  background-color: #f0dbc2;
  border-radius: 2px;
}
.reindeer__horn--left {
  top: -7px;
  left: -21px;
}
.reindeer__horn--left:before, .reindeer__horn--left:after {
  top: -4px;
  width: 14px;
  height: 4px;
}
.reindeer__horn--left:before {
  left: 6px;
  transform: rotate(43deg);
}
.reindeer__horn--left:after {
  left: 13px;
  transform: rotate(53deg);
}
.reindeer__horn--right {
  top: -12px;
  left: -6px;
  width: 24px;
  transform: rotate(62deg);
}
.reindeer__horn--right:before, .reindeer__horn--right:after {
  top: -3px;
  width: 10px;
  height: 4px;
}
.reindeer__horn--right:before {
  left: 5px;
  transform: rotate(43deg);
}
.reindeer__horn--right:after {
  left: 11px;
  transform: rotate(53deg);
}
.reindeer__ear {
  position: absolute;
  width: 21px;
  height: 11px;
  top: 4px;
  left: -18px;
  border-radius: 4px 0 50% 50%;
  background-color: #cca58b;
  transform: rotate(4deg);
}
.reindeer__ear:before {
  top: -2px;
  left: 34px;
  width: 4px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  transform: rotate(-35deg);
}
.reindeer__body {
  position: absolute;
  width: 58px;
  height: 31px;
  top: 84px;
  left: 28px;
  border-radius: 50% 0;
  background-color: #cca58b;
  transform: rotate(-3deg);
}
.reindeer__body:before, .reindeer__body:after {
  height: 26px;
  border-radius: 0 0 50% 50%;
  background-color: #cca58b;
}
.reindeer__body:before {
  width: 46px;
  top: -15px;
  left: 32px;
  transform: rotate(-55deg);
}
.reindeer__body:after {
  width: 43px;
  top: -11px;
  transform: rotate(-30deg);
}
.reindeer__foot--front .reindeer__foot-inner {
  width: 40px;
  height: 8px;
  top: 13px;
  left: 35px;
  border-radius: 0 50%;
  background-color: #cca58b;
  transform: rotate(-17deg);
  -webkit-animation: reindeer-front 0.8s linear alternate infinite;
  animation: reindeer-front 0.8s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.reindeer__foot--front .reindeer__foot-inner:before, .reindeer__foot--front .reindeer__foot-inner:after {
  height: 8px;
  background-color: #cca58b;
}
.reindeer__foot--front .reindeer__foot-inner:before {
  width: 28px;
  top: 10px;
  left: 17px;
  border-radius: 2px 50%;
  transform: rotate(131deg);
}
.reindeer__foot--front .reindeer__foot-inner:after {
  width: 7px;
  height: 8px;
  top: 17px;
  left: 18px;
  border-radius: 2px;
}
.reindeer__foot--back .reindeer__foot-inner {
  width: 56px;
  height: 9px;
  top: 37px;
  left: -29px;
  border-radius: 0 50%;
  background-color: #cca58b;
  transform: rotate(-73deg);
  -webkit-animation: reindeer-back 0.8s linear alternate infinite;
  animation: reindeer-back 0.8s linear alternate infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.reindeer__foot--back .reindeer__foot-inner:before, .reindeer__foot--back .reindeer__foot-inner:after {
  background-color: #cca58b;
}
.reindeer__foot--back .reindeer__foot-inner:before {
  width: 25px;
  height: 16px;
  top: 4px;
  left: 25px;
  border-radius: 0 50%;
  transform: rotate(15deg);
}
.reindeer__foot--back .reindeer__foot-inner:after {
  width: 8px;
  height: 9px;
  top: -2px;
  left: -2px;
  border-radius: 2px 0 2px 2px;
  transform: rotate(14deg);
}
.reindeer__foot-inner {
  position: absolute;
}
.reindeer__tail {
  position: absolute;
  width: 27px;
  height: 26px;
  top: 6px;
  left: -8px;
  border-radius: 50% 2px;
  background-color: #cca58b;
  transform: rotate(-17deg);
}
.reindeer__tail:before {
  background-color: #cca58b;
  border-radius: 50%;
  top: -2px;
  left: -3px;
  width: 15px;
  height: 5px;
  transform: rotate(25deg);
}

.input-toggle {
  position: absolute;
  opacity: 0;
}
.input-toggle:checked + .house-toggle .house__window {
  background-color: #f5ca95;
  transition: 0.5s;
}
.input-toggle:checked + .house-toggle .house__window .tree,
.input-toggle:checked + .house-toggle .house__window .cat,
.input-toggle:checked + .house-toggle .house__window .present {
  opacity: 1;
  transition: 0.5s;
}

.hukidashi {
  position: absolute;
  width: 69px;
  height: 45px;
  border-radius: 50%;
  opacity: 0.8;
  background-color: #fff;
}
.hukidashi:before, .hukidashi:after {
  opacity: 0.8;
}
.hukidashi--speak:before {
  border-left: 9px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 4px solid transparent;
  right: 3px;
  bottom: 0;
  opacity: 1;
  transform: rotate(41deg);
}
.hukidashi--think:before, .hukidashi--think:after {
  background-color: #fff;
  border-radius: 50%;
}
.hukidashi--think:before {
  width: 10px;
  height: 8px;
  right: 33px;
  bottom: -9px;
}
.hukidashi--think:after {
  width: 8px;
  height: 6px;
  right: 20px;
  bottom: -14px;
}
.hukidashi p {
  margin: 13px 0;
  font-size: 15px;
  text-align: center;
}

.sweat {
  position: absolute;
  top: 10px;
  right: 27px;
  width: 10px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  transform: rotate(-16deg);
}
.sweat:before {
  width: 0;
  height: 0;
  border-right: 6px solid #fff;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  top: 1px;
  left: -5px;
}

@-webkit-keyframes decoration {
  0% {
    opacity: 0.5;
    transform: translateX(-2px) translateY(-3px) scale(1);
  }
  40% {
    opacity: 0.6;
    transform: translateX(-2px) translateY(-3px) scale(1.5);
  }
  66% {
    opacity: 0.4;
    transform: translateX(-2px) translateY(-3px) scale(1.4);
  }
  100% {
    opacity: 0.5;
    transform: translateX(-2px) translateY(-3px) scale(1);
  }
}
@keyframes decoration {
  0% {
    opacity: 0.5;
    transform: translateX(-2px) translateY(-3px) scale(1);
  }
  40% {
    opacity: 0.6;
    transform: translateX(-2px) translateY(-3px) scale(1.5);
  }
  66% {
    opacity: 0.4;
    transform: translateX(-2px) translateY(-3px) scale(1.4);
  }
  100% {
    opacity: 0.5;
    transform: translateX(-2px) translateY(-3px) scale(1);
  }
}
@-webkit-keyframes santa-chimney {
  0% {
    top: 60px;
  }
  10% {
    top: 10px;
  }
  13% {
    top: 15px;
  }
  45% {
    top: 15px;
  }
  48% {
    top: 10px;
  }
  60% {
    top: 60px;
  }
  100% {
    top: 60px;
  }
}
@keyframes santa-chimney {
  0% {
    top: 60px;
  }
  10% {
    top: 10px;
  }
  13% {
    top: 15px;
  }
  45% {
    top: 15px;
  }
  48% {
    top: 10px;
  }
  60% {
    top: 60px;
  }
  100% {
    top: 60px;
  }
}
@-webkit-keyframes reindeer-front {
  0% {
    transform: rotate(-24deg);
  }
  100% {
    transform: rotate(-13deg);
  }
}
@keyframes reindeer-front {
  0% {
    transform: rotate(-24deg);
  }
  100% {
    transform: rotate(-13deg);
  }
}
@-webkit-keyframes sori-santa-hand-left {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}
@keyframes sori-santa-hand-left {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}
@-webkit-keyframes sori-santa-lead-left {
  0% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
@keyframes sori-santa-lead-left {
  0% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
@-webkit-keyframes sori-santa-hand-right {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
@keyframes sori-santa-hand-right {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
@-webkit-keyframes sori-santa-lead-right {
  0% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes sori-santa-lead-right {
  0% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@-webkit-keyframes sori-santa {
  0% {
    top: -10px;
    left: -500%;
  }
  100% {
    top: -20px;
    left: 500%;
  }
}
@keyframes sori-santa {
  0% {
    top: -10px;
    left: -500%;
  }
  100% {
    top: -20px;
    left: 500%;
  }
}
@-webkit-keyframes santa-foot-left {
  0% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@keyframes santa-foot-left {
  0% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@-webkit-keyframes santa-foot-right {
  0% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes santa-foot-right {
  0% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@-webkit-keyframes santa-walk {
  0% {
    left: 100%;
    transform: rotateY(0);
  }
  35% {
    left: 30%;
    transform: rotateY(0);
  }
  36% {
    transform: rotateY(180deg);
  }
  50% {
    left: 57%;
    transform: rotateY(180deg);
  }
  51% {
    transform: rotateY(0);
  }
  80% {
    left: calc(0% - 125px);
  }
  100% {
    transform: rotateY(0);
    left: calc(0% - 125px);
  }
}
@keyframes santa-walk {
  0% {
    left: 100%;
    transform: rotateY(0);
  }
  35% {
    left: 30%;
    transform: rotateY(0);
  }
  36% {
    transform: rotateY(180deg);
  }
  50% {
    left: 57%;
    transform: rotateY(180deg);
  }
  51% {
    transform: rotateY(0);
  }
  80% {
    left: calc(0% - 125px);
  }
  100% {
    transform: rotateY(0);
    left: calc(0% - 125px);
  }
}
@-webkit-keyframes santa-walk-hukidashi {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes santa-walk-hukidashi {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes sweat {
  0% {
    opacity: 0;
    top: 18px;
    right: 30px;
    transform: rotate(-30deg);
  }
  40% {
    opacity: 1;
    top: 10px;
    right: 20px;
  }
  60% {
    opacity: 0;
    top: 10px;
    right: 20px;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sweat {
  0% {
    opacity: 0;
    top: 18px;
    right: 30px;
    transform: rotate(-30deg);
  }
  40% {
    opacity: 1;
    top: 10px;
    right: 20px;
  }
  60% {
    opacity: 0;
    top: 10px;
    right: 20px;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes sweat2 {
  0% {
    opacity: 0;
    top: 10px;
    right: 38px;
    transform: rotate(-47deg);
  }
  40% {
    opacity: 1;
    top: 2px;
    right: 31px;
  }
  60% {
    opacity: 0;
    top: 2px;
    right: 31px;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sweat2 {
  0% {
    opacity: 0;
    top: 10px;
    right: 38px;
    transform: rotate(-47deg);
  }
  40% {
    opacity: 1;
    top: 2px;
    right: 31px;
  }
  60% {
    opacity: 0;
    top: 2px;
    right: 31px;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes santa-hand-left {
  0% {
    transform: rotate(14deg);
  }
  100% {
    transform: rotate(8deg);
  }
}
@keyframes santa-hand-left {
  0% {
    transform: rotate(14deg);
  }
  100% {
    transform: rotate(8deg);
  }
}


/* * {
  position: relative;
}
*:before, *:after {
  position: absolute;
  content: "";
} */