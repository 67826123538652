.twoFAContainer {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
  padding: 53px 29px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 690px;
  position: relative;
  padding-top: 34px !important;
}
  
  .qrCodeImage {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }
  
  .linkButton {
    color: #007bff;
    text-decoration: none;
    margin-right: 10px;
  }
  
  .linkButton:hover {
    text-decoration: underline;
  }
  .outer-twofactor{
    background-image: url('../../images/background/two-fa-bg.png');
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .logo {
    position: absolute;
    top: 34.71px;
    left: 38px;
}
.content-two-factor h3{
  color: #282828;
  text-align: center;
  /* font-family: Halyard Display; */
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0.4px;
}
.content-two-factor p {
  color: #78828D;
  text-align: center;
  /* font-family: Halyard Display; */
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 122%;
  margin-top: 22px;
}
.content-two-factor p:last-child{
  margin-bottom: 0;
}
button.add-method {
  border-radius: 6px;
  background: #6479F8;
  color: #FFF;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
  letter-spacing: 0.5px;
  width: 217px;
  height: 44px;
}
.two-factor-btns {
  margin-top: 37px;
  display: flex;
  justify-content: center;
  grid-gap: 20px;
}
button.skip-btn {
  color: #282828;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
  letter-spacing: 0.5px;
  border-radius: 6px;
  border: 0.2px solid #282828;
  background: #F5F6F8;
  width: 174px;
  height: 44px;
}
.choose-inner {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
  padding: 45px 68px;
  padding-bottom: 50px !important;
  width: 70%;
  margin: 0 auto;
}
.qr-title h2 {
  color: #282828;
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  margin-top: 9px !important;
}
.qr-des p {
  color: #78828D;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 122%;
  margin-bottom: 16px;
}
.qr-image-sec img {
  width: 136px;
  height: 136px;
}
.qr-bottom-sec p {
  color: #78828D;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 122%;
  margin: 0 auto;
}
.qr-image-sec {
  display: inline-flex;
}
.skip-btn a {
  position: absolute !important;
  right: 15px;
  top: 15px;
  font-size: 30px;
  color: #78828D;
}
.qr-btn a.linkButton {
  margin-right: 0;
  font-size: 14px;
  margin-bottom: 20px;
  display: inline-block;
  text-decoration: underline !important;
}
.qr-code-title {
  color: #282828;
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
}
.qr-code-des {
  color: #78828D;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 122%;
  margin-bottom: 45px;
}
.qr-code-btn button{
  border-radius: 6px;
  background: #6479F8;
  width: 217px;
  height: 44px;
  color: #FFF;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
  letter-spacing: 0.5px;
  margin-top: 51px;
  border: none;
  margin-bottom: 17px;
}
.code-input-container input {
  width: 45px;
  height: 51px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 11px 3px rgb(0 6 0 / 6%);
  border: 0.5px solid #B4B4B4;
  color: #282828;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
}
.code-input-container {
  display: flex;
  grid-gap: 29px;
  position: relative;
  justify-content: center;
}
.success-message-reset{
  text-align: left;
}
.tick-icon-reset img {
  width: 34px;
  height: 34px;
  margin-bottom: 14px;
}
@media only screen and (max-width: 480px) {
  .code-input-container input{
    width: 40px;
  }
  .qr-code-btn {
    width: 100%;
  }
  .qr-code-btn button{
    width: 100%;
  }
}
@media only screen and (max-width: 985px) {
  .twoFAContainer {
    width: 85% !important;
    margin-bottom: 100px;
    padding: 75px 10px 54px 10px;
    padding-top: 26px !important;
  }
  .logo {
    position: initial !important;
    padding-bottom: 80px;
    padding-top: 24px;
}
.outer-twofactor{
  flex-direction: column;
  justify-content: flex-start;
}
.two-factor-btns {
  flex-direction: column;
}
.choose-inner{
  padding: 32px 32px 46px 32px;
  width: 90%;
  margin-bottom: 100px;
}
.two-factor-btns button {
  width: 100% !important;
}
.qr-title h2{
  margin-bottom: 42px;
}
.qr-des p{
  margin-bottom: 20px;
}

.code-input-container{
  grid-gap: 8px;
}
.qr-title h2{
  margin-top: 0 !important;
}
.success-message-reset {
  text-align: center;
  margin-top: 50px;
}
}