.rs-picker-toggle.rs-btn.rs-btn-default {
    align-items: center;
    background: #fff;
    border: 0.2px solid rgba(106, 121, 136, .7);
    border-radius: 5px;
    color: #78828d;
    cursor: pointer;
    display: flex;
    font-size: 17px;
    font-weight: 300;
    height: 43px;
    letter-spacing: .5px;
    line-height: 21px;
    padding: 12px 18px;
    width: 154px;
}

.rs-picker-toggle-read-only {
    opacity: 1 !important;
}

input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
    padding-left: 52px !important;
}

input.rs-picker-toggle-textbox {
    padding-left: 52px !important;
}

.rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-has-value.rs-picker-cleanable input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
    /* padding-left: 10px !important; */
}

.filter-sec {
    /* margin-top: 26px; */
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}

svg.rs-picker-toggle-caret.rs-icon {
    position: absolute;
    left: 18px;
    width: 20px;
    height: 20px;
    margin-top: 4px;
    fill: #405BFF;
    z-index: 9;
}

.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-menu {
    /* right: 35px !important;
    left: auto !important; */
    filter: drop-shadow(0px 0px 7px rgba(40, 40, 40, 0.06));
    border: 0.2px solid #6A7988;
    margin-top: 8px !important;
}

.rs-picker-daterange-panel.rs-picker-daterange-panel-show-one-calendar {
    width: 352px;
    padding: 33.75px 30px;
}

.rs-stack {
    display: block !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
    width: 100% !important;
    padding: 0 !important;
}

.rs-picker-daterange-header {
    display: none;
}

button.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
    order: 3;
    position: absolute;
    right: 0;
    top: 6px;
    width: 24px;
    /* border: 0.2px solid #6A7988; */
    border-radius: 0;
    /* border-left: 0.8px solid #6A7988 !important; */
}

button.rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
    order: 1;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    color: #282828;
    padding: 0 !important;
    margin: 0;
}

button.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
    order: 2;
    position: absolute;
    right: 45px;
    top: 6px;
    width: 24px;
    /* border: 0.2px solid #6A7988; */
    /* border-left: 0.8px solid #6A7988 !important; */
    border-radius: 0;
}

.rs-calendar-header-month-toolbar {
    display: flex !important;
    position: relative;
}

button.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs svg,
button.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs svg {
    fill: #405BFF;
    width: 6px;
    height: 19px;
    padding-left: 0.3px;

}

.rs-btn-icon {
    position: relative;
}

.rs-btn-icon::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #405BFF;
    border-radius: 50%;
}

.rs-calendar-body {
    padding: 40px 10px 0px 10px !important;

}

span.rs-calendar-table-header-cell-content {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #96A8BA;
    margin-bottom: 10px;
    overflow: hidden;
}

span.rs-calendar-table-header-cell-content::first-letter {
    visibility: visible;
}

.rs-picker-toolbar-ranges.rs-stack {
    display: none !important;
}

.rs-picker-toolbar.rs-stack {
    max-width: 100% !important;
    border: none !important;
    display: flex !important;
    justify-content: flex-end;
    /* margin-top: 40px; */
}

.rs-calendar-table-cell-content {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.464571px;
    color: #96A8BA;
}

.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month {
    /* visibility: hidden; */
    opacity: 0.6;
}

.rs-picker-daterange-menu .rs-calendar {
    height: 100% !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
    padding-bottom: 0 !important;
}

.rs-picker-toolbar.rs-stack {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

button.rs-btn.rs-btn-primary.rs-btn-sm {
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    background: transparent;
    color: #282828;
}

.rs-calendar-table-cell-content span {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.464571px;
    color: #6A7988;
    ;
}

.rs-calendar-table-cell.rs-calendar-table-cell-selected .rs-calendar-table-cell-content span {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.464571px;
    color: #FFFFFF;
}

.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-selected:before {
    height: 40px !important;
    margin-top: 0 !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    height: 40px !important;
    width: 40px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background: #405BFF !important;
    border-radius: 30px;
}

.rs-calendar-table-cell-in-range:before {
    background: #E9ECFD !important;
    /* border-top: 0.2px solid #405BFF;
    border-bottom: 0.2px solid #405BFF; */
    box-shadow: 0 0.1px 0 0 #405BFF, 0 -0.1px 0 0 #405BFF;
}

.rs-calendar-table-cell-in-range span.rs-calendar-table-cell-day {
    color: #405BFF;
}

.rs-btn-close .rs-icon {
    margin-top: 10px;
}

.rs-btn-icon.rs-btn-xs {
    padding: 2px 6px !important;
}

.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../images/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: right 18px center;
    padding-right: 25px !important;
    padding: 10px 15px;
    width: 251px;
    height: 43px;
    background-color: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 10px;
    font-weight: 300;
    font-size: 17px;
    line-height: 20.74px;
    letter-spacing: 0.5px;
    color: #78828D;
}

.custom-select::-ms-expand {
    display: none;
}

.currency-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26.84px;
    letter-spacing: 0.2px;
    color: #454F59;
    text-align: left;
}

.curency-dropdown-inner {
    display: flex;
    align-items: center;
    grid-gap: 18px;
}

.curency-dropdown {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.custom-dropdown {
    position: relative;
}

.dropdown-toggle {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.dropdown-toggle:hover {
    border-color: #999;
}

.dropdown-toggle:focus {
    outline: none;
    border-color: #999;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    margin: 4px 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.custom-dropdown.open .dropdown-menu {
    display: block;
}

.dropdown-menu li {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f4f4f4;
}

/* .currency-title {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
  } */
.curency-dropdown-inner .css-qbdosj-Input input,
.curency-dropdown-inner .dropdown-text {
    color: #78828D !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 20.74px !important;
    letter-spacing: 0.5px !important;
}

.css-1dimb5e-singleValue span.currency-symbol {
    display: none;
}

.rs-calendar-body .rs-calendar-table-row:last-child {
    /* display: none; */
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
    height: 400px !important;
}

#react-select-3-listbox {
    z-index: 9999;
}
.curency-dropdown-inner p {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.date-picker-outer.date-picker-outer-all input {
    background-color: #ffffff !important;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    padding: 0px 16px 0px 16px;
    color: #78828d;
    font-size: 17px;
    max-width: 100%;
    font-weight: 300;
}
.curency-dropdown.filter-dashbord-calendar .date-picker-outer.date-picker-outer-all {
    width: auto !important;
}
/* Add additional styling as desired */
@media screen and (max-width: 560px) {
    .curency-dropdown {
        /* display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
        grid-gap: 25px; */
        grid-gap: 7px;
    }

}

@media screen and (max-width: 580px) {

    /* .curency-dropdown {
        flex-wrap: wrap;
    } */
    .curency-dropdown .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-end.rs-picker-cleanable {
        width: 48%;
        margin-top: 32px;
    }

    .curency-dropdown-inner {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 5px;
        width: 50%;
    }

    .curency-dropdown-inner .css-b62m3t-container,
    .curency-dropdown-inner .css-ifwzzj-control,
    .curency-dropdown-inner .css-1dkdkro-control,
    .curency-dropdown-inner .css-1hu5bb-control,
    .curency-dropdown-inner .css-m8hgk9-control {
        width: 100%;
    }

    .curency-dropdown .rs-picker-default .rs-picker-toggle.rs-btn {
        width: 100%;
        border-radius: 6%;
    }

    .currency-option {
        align-items: center !important;
    }
}

@media screen and (max-width: 991px) {
    .curency-dropdown {
        grid-gap: 7px;
        align-items: flex-start;
        /* flex-wrap: wrap; */
    }
    .curency-dropdown-inner{
        flex-wrap: wrap;
        width: 100%;
        grid-gap: 10px;
    }
    .curency-dropdown-inner .css-1i27g2n-indicatorContainer {
        padding-right: 18px;
    }
}

@media screen and (max-width: 991px) {
    .rs-picker-daterange-panel.rs-picker-daterange-panel-show-one-calendar {
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-menu {
        right: 15px !important;
    }

    .rs-calendar-body {
        padding-top: 20px !important;
    }
}
/* try */
.custom-select {
    appearance: none;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    width: 200px;
    cursor: pointer;
}

.custom-select option {
    padding: 8px 16px;
}

/* Example styles for the selected option */
.custom-select option:checked {
    background-color: #e2e2e2;
    color: #333;
}

/* Example styles for the hover effect on options */
.custom-select option:hover {
    background-color: #f7f7f7;
}

.currency-title select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(http://localhost:3000/static/media/down-arrow.9b8fbde….svg);
    background-repeat: no-repeat;
    background-position: right 18px center;
    padding-right: 25px !important;
    padding: 10px 15px;
    width: 251px;
    height: 43px;
    background-color: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 10px;
    font-weight: 300;
    font-size: 17px;
    line-height: 20.74px;
    letter-spacing: 0.5px;
    color: #78828D;
}

span.css-1u9des2-indicatorSeparator {
    display: none;
}

.currency-symbol {
    border-radius: 50%;
    padding: 30px;

    background: #D8DDE1;
}

.currency-symbol {
    border-radius: 50%;
    padding: 7px 13px;
    background: #D8DDE1;
    width: max-content;
    max-height: 37px;
    align-items: center;
    display: flex;
    color: #78828D;
    font-weight: 500;
    margin-right: 13px;

}

.currency-option {
    display: flex;
    align-items: baseline;
}
.curency-dropdown-inner p {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}