.alert-section.for-time-outer{
    margin-bottom: 23px;
    padding-bottom: 29px;
    border-bottom: 0.5px solid #D8DDE1;
}
.alert-section.for-budget-outer {
    margin-bottom: 44px;
}
.project-alert-outer {
    margin-top: 19px;
}
label.alert-label {
    display: inline-flex;
    align-items: center;
    grid-gap: 9px;
    margin-bottom: 23px;
}
label.alert-label span{
     color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    
}
label.alert-label input[type="checkbox"]{
    width: 19px;
    height: 19px;
}
.outer-for-section .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    flex-wrap: wrap;
}
.outer-for-section .input-group span{
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
}
.outer-for-section .input-group input{
    width: 74px;
    height: 43px;
    margin-left: 16px;
    margin-right: 13px;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 7px;
    padding: 10px;
    text-align: center;
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
}
.dropdown-group{
    display: flex;
    grid-gap: 18px;
    align-items: center;
}
.dropdown-group span{
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
}
.dropdown-group .custom-dropdown1 {
    min-width: calc(100% - 60px);
}
.dropdown-group .custom-dropdown1 .placeholder,
.dropdown-group .custom-dropdown1 .selected-option1{
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
}
.dropdown-group .custom-dropdown1 .selected-options1{
    min-width: 100%;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal.project-threshhold{
    height: 75vh !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal.project-threshhold form{
    height: 75vh !important;
    max-height: calc(75vh - 100px ) !important;
}
.outer-for-section.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal.project-threshhold input#date-range{
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
}
.dropdown-group .custom-dropdown1 .selected-option-count {
    min-width: 30px;
    min-height: 30px;
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 768px) {
    .outer-for-section  .dropdown-group .custom-dropdown1{
        position: relative !important;
    }
    .outer-for-section  .dropdown-group .custom-dropdown1 .dropdown-list1 {
        top: 53px !important;
        left: 0px !important;
        width: 100% !important;
    }
}