
.onboarding-page-outer .company-detail-title{
  color: #282828;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.66px;
  letter-spacing: 0.4px;
  text-align: left;
}
.onboarding-page-outer .company-detail-sub-title {
  color: #78828D;
  font-size: 16px;
  font-weight: 300;
  line-height: 122%;
  text-align: left;
  margin-top: 8px;
}
.onboarding-page-outer .form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: auto; /* Centering the form */
  }
  
  .onboarding-page-outer .form-group {
    margin-bottom: 27px;
    text-align: left;
  }
  .onboarding-page-outer .form-group.date-of-birth {
    margin-bottom: 7px;
}
.onboarding-page-outer .date-picker-outer.date-picker-outer-all {
  margin-bottom: 27px;
}
.onboarding-page-outer .date-overview-sec.date-overview-edit {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.onboarding-page-outer form{
margin-top: 22px;
}
  
.onboarding-page-outer label {
    color: #6A7988;
    font-size: 17px;
    font-weight: 300;
    line-height: 122%;
    margin-bottom: 9px;
    display: block;
}
  
.onboarding-page-outer input[type="text"],
.onboarding-page-outer input[type="email"],
.onboarding-page-outer input[type="password"],
.onboarding-page-outer input{
    width: 100%;
    padding: 10.5px;
    font-size: 14px;
    border: 0.5px solid rgba(106, 121, 136, 0.70);
    border-radius: 7px;
    margin-top: 9px;
}
.onboarding-page-outer .custoom-icon-calender.custoom-icon-calender-all input{
  margin-top: 0px !important;
}
.onboarding-page-outer .custoom-icon-calender.custoom-icon-calender-all{
  margin-top: 16px;
}
.onboarding-page-outer .PhoneInputCountry{
  margin-top: 9px;
}
.onboarding-page-outer .continue-button {
text-align: end;
}
.onboarding-page-outer .form-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.thank-you-form-submit{
  color: #78828D;
  font-size: 19px;
  text-align: left;
  font-weight: 300;
  line-height: 23.18px;
  margin-bottom: 35px;
}
.onboarding-page-outer .continue-button button {
    padding: 10.079px 38.6px;
    cursor: pointer;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    border-radius: 6px;
    border: 0.2px solid #7CC273;
    background: #6479F8;
    min-width: 151px;
}
.onboarding-page-outer .continue-button a{
  color: #FFF;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.74px;
  letter-spacing: 0.5px;
  border-radius: 6px;
  background: #6479F8;
  padding: 10.75px 31px;
}
.onboarding-page-outer .form-back-button button {
  border-radius: 6px;
  border: 0.2px solid #282828;
  background: #F5F6F8;
  color: #282828;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
  letter-spacing: 0.5px;
  width: 86px;
  height: 44px;
}
.onboarding-page-outer .form-back-button button:hover {
opacity: 0.8;
border-radius: 6px;
  border: 0.2px solid #282828;
  background: #F5F6F8;
  color: #282828;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
  letter-spacing: 0.5px;
  width: 86px;
  height: 44px;
}
.onboarding-page-outer button:hover {
    background-color: #344ede;
  }

  .onboarding-page-outer .id-brwosing {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.onboarding-page-outer .browsing-outer label {
  margin-top: 9px;
  margin-bottom: 0;
  border-radius: 5px;
  color: #282828;
  font-size: 14px;
  font-weight: 300;
  line-height: 122%;
  letter-spacing: 0.5px;
}
.onboarding-page-outer .id-detail {
  width: 100%;
}
.detail-form-section-outer {
  flex: 1;
  padding: 20px; /* Adjust as needed */
}
@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
 .onboarding-page-outer .detail-form-section-outer {
        padding-top: 56px;
        padding-left: 20px;
        padding-right: 20px;
        height: 100vh;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 1200px) and (min-width: 768px) {
  .onboarding-page-outer .detail-form-section-outer {
    padding-top: 86px;
    padding-left: 45px;
    padding-right: 45px;
    height: 100vh;
    max-width: -webkit-fill-available;
    width: calc(100% - 45.5%);
}
}