.pie-outer {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 19px 22px 18px 18px;
    height: 100%;
}

.pie-outer .graph-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #454F59;
}

.pie-outer .icon-graph {
    font-weight: 600;
    font-size: 20px;
    line-height: 24.4px;
    letter-spacing: 1px;
    color: #282828;
}

.pie-chart-top {
    margin-bottom: 10px;
}
.apexcharts-canvas{
    text-align: center !important;
    margin: 0 auto !important;
}

.export-icon.pie-export {
    display: flex;
    grid-gap: 15px;
    align-items: center;
    justify-content: right;
}
.pie-export-title{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #6479f8;
}
.export-icon.pie-export a span svg{
    fill: #6479f8;
}
.export-icon.pie-export a span {
    background: transparent;
    display: flex;
}
.export-icon.pie-export a span svg path{
    fill: #6479f8;
    stroke: #6479f8;
    transition: 0.5s;
}
/* .export-icon.pie-export span.icon-export{
    border-radius: 41px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(120, 130, 141, 0.39);
    padding: 10px 10px;
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
} */
.export-icon.pie-export a{
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
/* .export-icon.pie-export a:hover span.icon-export{
    background: #6479f8;
}
.export-icon.pie-export a:hover span svg path{
    fill: #fff;
    stroke: #fff;
    transition: 0.5s;
} */