.div-outter-for-department {
  text-align: initial;
  padding-top: 44px;
  padding-left: 58px;
  padding-right: 58px;
}

.add-department-section input {
  background: #EAEBEC;
  border-radius: 10px;
  max-width: 361px;
  width: 100%;
  height: 45px;
  padding-left: 19px;
  border: none;
  font-weight: 300;
  font-size: 19px;
  line-height: 23px;
}

button.add-btn-in-department-b1 {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background: #6479F8;
  margin-left: 13px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}




.department-section-listing {
  grid-gap: 20px;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 39px;
  padding-bottom: 125px;
}

.department-section-listing input {
  background: #FFFFFF;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
  border-radius: 10px;
  padding-left: 15px;
  color: #282828;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  /* max-width: 347px; */
}

.grid-item {
  position: relative;
  /* max-width: 347px; */
}

.input-tag {
  width: 100%;
  height: 40px;
  padding: 5px;
}

.edit-button {
  position: absolute;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  color: #405BFF;
  font-weight: 500;
  /* background: #F5F6F8; */
  border-radius: 6px;
  font-size: 14px;
  background: rgba(242, 243, 245, 0.82);
  width: 100%;
  height: 100%;
  left: 0;
}

button.edit-button img {
  margin-right: 8px;
}

.grid-item:hover .edit-button {
  display: flex;
}

.grid-item:hover .edit-button {
  display: block;
  /* max-width: 347px; */

}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.leave {
  width: 70% !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.department-modal {
  height: max-content !important;
}
.bulk-import img {
  transform: rotate(180deg);
  margin-left: 0 !important;
  margin-right: 10px !important;
}
.user-outer-timeoff .button-sec-invoice a:last-child span.icon-invoice img{
  margin-right: 5px !important;
}
.content-outer.Equipment .user-outer-timeoff .button-sec-invoice a:last-child span.icon-invoice img {
  margin-right: 0 !important;
}
@media screen and (max-width: 703px) {
  .department-section-listing {
    grid-auto-flow: dense;
    grid-template-columns: 1fr;
  }
  .ReactModal__Content.ReactModal__Content--after-open.client-modal.leave {
    width: 100% !important;
  }
  .department-section-listing input {
    text-align: center;
  }

  .add-department-section {
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .div-outter-for-department {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ReactModal__Content.ReactModal__Content--after-open.client-modal.department-modal {
    width: 90% !important;
  }
}
@media screen and (max-width: 1100px) {
  .div-outter-for-department,
  .member-main-outter,
  .time-client-outer {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}