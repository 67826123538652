.recent-inv-title{
    color: #454F59;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.84px;
    text-align: left;
    padding-top: 16px;
    padding-left: 4px;
}
.client-report-outer .graph-main-outer-time.graph-main-outer-time-report {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 39px;
}
.client-report-outer .button-sec-invoice a {
    background: #6479F8;
    border-radius: 6px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 16.58px 15px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 123px;
}
.client-report-outer .button-sec-invoice{
    display: flex;
    justify-content: right;
    margin-top: 24px;
}
.client-report-outer .datatable-team-maintb {
    margin-bottom: 0;
}
.outer-report-graph-piechart.client-report-inner {
    grid-template-columns: 1fr;
}
.content-outer.Clients.Report {
    padding-left: 0;
    padding-right: 0;
}