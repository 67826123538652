.account-details .profile-photo-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 38px 40px 0px 35px;
    padding-bottom: 37px;
}

.account-details a.edit-profile {
    display: flex;
    width: 132px;
    height: 44px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background: #6479F8;
    border-radius: 6px;
    grid-gap: 12.75px;
}

.account-details a.edit-profile img {
    Width: 21.23px;
    Height: 21.36px;
}

.account-details a.delete-profile img {
    width: 18.67px;
    height: 20px;
}

.account-details a.delete-profile {
    display: flex;
    width: 110px;
    height: 44px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08;
    letter-spacing: 0.5px;
    color: #282828;
    background: #F5F6F8;
    border: 0.2px solid #282828;
    border-radius: 6px;
    grid-gap: 14.33px;
}

.account-details .profile-button a:hover {
    opacity: 0.8;
}

.account-details .profile-button {
    display: flex;
    flex-direction: row;
    grid-gap: 19px;
}


.account-details .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.account-details .confirmation {
    background-color: #fff;
    padding: 50px;
    border-radius: 4px;
    text-align: center;
}

.account-details .confirmation p {
    margin-bottom: 30px;
    font-size: 18px;
}

.account-details .confirmation button {
    margin-right: 10px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #6479F8;
    color: #fff;
    cursor: pointer;
}

.account-details .success-message {
    background-color: #6479F8;
    color: #fff;
    padding: 20px;
    text-align: center;
    margin-top: 10px;
    border-radius: 4px;
    position: absolute;
    right: 42px;
    top: 15%;
}

.account-details .information-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 0.2px;
    color: #454F59;
    display: flex;
    margin-bottom: 28px;
}

.account-details .information-outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
}

.account-details .information {
    padding: 0 39px 0px 44px;
}

.account-details .inner-detail label {
    font-weight: 300;
    font-size: 17px;
    line-height: 20.74px;
    display: flex;
    align-items: center;
    color: #6A7988;
}

.account-details .inner-detail-outer {
    margin-bottom: 23px;
}

.account-details .inner-detail label img {
    margin-right: 10px;
}

.account-details .inner-detail {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.account-details .inner-detail .icon {
    width: 26px;
}

.account-details .inner-detail .icon {
    display: inline-grid;
    justify-content: center;
    margin-top: 5px;
}

.account-details .value-field {
    font-weight: 300;
    font-size: 19px;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    color: #282828;
}

.account-details .information-inner {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 7px;
    margin-bottom: 28px;
    margin-left: 35px;
    margin-right: 40px;
}

.account-details .information-inner:last-child {
    border: 0;
}

.account-details .profile-photo h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26.84px;
    color: #454F59;
}

.account-details a.cancel-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    color: #282828;
    width: 86px;
    height: 44px;
    border: 0.2px solid #282828;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F6F8;
}

@media only screen and (max-width: 767px) and (min-width: 220px) {
    .account-details .profile-photo-outer {
        flex-direction: column;
        margin: 29px 15px 24px 15px;
    }

    .account-details .tab-outer ul.tabs li {
        font-size: 17px !important;
        margin-right: 27px !important;
    }

    .account-details .tab-outer ul.tabs li:last-child {
        margin-right: 0 !important;
    }

    .account-details .tab-outer ul.tabs {
        padding: 28px 17px 0 17px !important;
    }

    .account-details a.delete-profile {
        width: 132px;
    }

    .account-details .information {
        padding: 0 !important;
    }

    .account-details .information-inner {
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 4px;
    }

    .account-details .information-outer {
        grid-template-columns: 1fr;
    }

    .account-details .inner-detail {
        margin-bottom: 9px;
    }

    .account-details .information-right .inner-detail:last-child {
        margin-bottom: 20px;
    }

    .account-details .profile-button {
        margin-top: 52px;
    }
}

@media only screen and (max-width: 479px) and (min-width: 220px) {
    .account-details .tab-outer ul.tabs {
        justify-content: space-between;
    }

    .account-details .profile-button {
        margin-top: 52px;
        ;
    }
}