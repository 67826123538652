.security-outer.noti-outer.security-2fa {
    padding: 48px 62px 48px 59px;
    /* margin-top: 10px !important; */
    display: inline-block;
    width: 100%;
}
.security-outer.noti-outer.security-2fa .security-inner.noti-enable.noti-head-noti {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.security-outer.noti-outer.security-2fa .security-inner.noti-enable.noti-head-noti span.custom-switch-description{
    margin-left: 0 !important;
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}
@media screen and (max-width: 991px) {
    .security-outer.noti-outer.security-2fa .security-inner.noti-enable.noti-head-noti{
        padding: 0 20px !important;
    }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
    .security-outer.noti-outer.security-2fa{
        padding: 48px 40px !important;
    }
}