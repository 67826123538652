.login-outer-forget .login-inner {
display: flex;
flex-wrap: wrap;
background: #FBFBFD;
}

.login-outer-forget .login-inner .lg-lft,
.login-outer-forget .login-inner .lg-rgt {
width: 50%;
}
.login-outer-forget .login-inner .lg-lft{
    min-height: 200px;
    transition: transform 0.3s ease;
}
.login-outer-forget .login-inner .lg-lft img {
width: 100%;
height: 100vh !important;
object-fit: cover !important;
}

.login-outer-forget .login-form-sec {
height: 100%;
display: flex;
width: 100%;
align-items: center;
}

.login-outer-forget .login-inner .lg-rgt {
position: relative;
padding-left: 90px;
}

.login-outer-forget .lg-rgt .top-logo {
position: absolute;
top: 53px;
right: 58px;
}

.login-outer-forget .login-form-sec h3 {
color: #282828;
font-size: 33px;
font-style: normal;
font-weight: 500;
line-height: 34px;
letter-spacing: 0.4px;
margin-bottom: 15px;
}
.login-outer-forget .login-form-sec h3.reset-title{
margin-bottom: 37px;
}
.login-outer-forget .login-form-sec p{
color: #78828D;
font-size: 19px;
font-style: normal;
font-weight: 300;
line-height: 122%;
margin-bottom: 42px;
}
.login-outer-forget .login-form-sec form label {
color: #282828;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
margin-bottom: 6px;
}

.login-outer-forget .login-form-sec form {
text-align: left;
}

.login-outer-forget .login-form-sec .form-outer {
display: flex;
flex-direction: column;
margin-bottom: 32px;
}

.login-outer-forget .login-form-sec .form-outer.email {
margin-bottom: 35px;
}

.login-outer-forget .login-form-sec .form-outer.password {
margin-bottom: 15px;
}

.login-outer-forget .forget-password {
text-align: right;
}

.login-outer-forget .login-form-sec .form-outer input::placeholder {
color: #78828D;
font-size: 20px;
font-style: normal;
font-weight: 200;
line-height: normal;
}

.login-outer-forget .login-form-sec .form-outer input {
width: 100% ;
height: 60px ;
border: 1px solid #6A7988;
background-color: #fff;
color: #000000 !important;
border-radius: 5px;
padding: 15px 20px ;
font-size: 20px ;
padding-right: 50px;
}
.login-outer-forget .login-form-sec form button {
border-radius: 6px;
background: #6479F8;
border: none;
color: #FFF;
text-align: center;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.5px;
width: 452px;
height: 44px;
}

.login-outer-forget .login-form-sec form button:hover {
opacity: 0.8;
}

.login-outer-forget .mobile-lg-img {
display: none;
}

.top-logo-mobile {
display: none;
}

.login-outer-forget .error {
border-color: red !important;
background-color: #fdf4f4 !important;
}

.divider {
display: flex;
align-items: center;
justify-content: center;
margin: 18px 0 36px;
color: #282828;
font-size: 20px;
font-weight: 300;
line-height: normal;
}
.divider:after {
content: "";
flex: 1 1;
height: 1px;
width: 61.5px;
background-color: #282828;
margin: 3px 0px 0 23px;
}
.divider:before{
content: "";
flex: 1 1;
height: 1px;
width: 61.5px;
background-color: #282828;
margin: 3px 23px 0 0;
}
.dnt-acnt-chek-email{
display: flex;
align-items: center;
justify-content: center;
margin: 90px 0 16px;
color: #282828;
font-size: 16px;
font-weight: 300;
line-height: normal;
}
.dnt-acnt-chek-email a{
padding-left: 4px;
}

.auth-divide .g-btn-outer .google-btn svg {
width: 26px;
height: 26px;
margin-right: 12px;
}
.auth-divide .g-btn-outer .google-btn{
display: inline-flex;
align-items: center;
justify-content: center;
padding: 16px 0;
border-radius: 62px;
border: 1px solid #78828D !important;
background: #FFF !important;
color: #282828;
text-align: center;
font-size: 17px;
font-weight: 400;
line-height: 122%;
letter-spacing: 0.5px;
width: 100%;
height: 60px;
}
.auth-divide .g-btn-outer .google-btn .google-icon {
background-image: url('../../images/icons/Google-logo.svg'); 
background-size: cover;
width: 26px;
height: 26px;
margin-right: 13px;
}


@media only screen and (max-width: 985px) {
.login-outer-forget .desktop-lg-img {
display: none;
}
.auth-divide .lg-lft img{
    height: auto !important;
}
.login-outer-forget .login-inner .lg-lft img {
height: 100% !important;
}
.login-outer-forget .login-inner .mobile-lg-img{
height: 100% !important;
}
.login-outer-forget .top-logo-mobile {
display: block;
}

.login-outer-forget .top-logo {
display: none;
}

.login-outer-forget .mobile-lg-img {
display: block;
}

.login-outer-forget .top-logo-mobile {
width: 100%;
padding: 24px 0;
}

.login-outer-forget .login-inner .lg-lft,
.login-outer-forget .login-inner .lg-rgt {
width: 100%;
}

.login-outer-forget .login-inner .lg-rgt {
position: relative;
padding-left: 30px;
padding-right: 30px;
padding-bottom: 50px;
}

.login-outer-forget .top-logo {
display: none;
}

.login-outer-forget .top-logo-mobile img {
width: 197.792px;
height: 32px;
}

}

@media only screen and (max-width: 767px) {
.login-outer-forget .login-form-sec .form-outer input {
width: 100% !important;
}

.login-outer-forget .login-form-sec form button {
width: 100%;
}

.login-outer-forget .login-form-sec h3 {
text-align: center;
margin-top: 18px;
}

.login-outer-forget .login-form-sec {
display: inline-block;
}

.login-outer-forget .login-form-sec {
justify-content: center;

}
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
.login-outer-forget .login-inner .lg-rgt {
padding: 0 50px;
}

.login-outer-forget .login-form-sec {
justify-content: center;
padding: 80px;
}
}