.response-title {
    color: #282828;
    font-size: 33px;
    font-weight: 500;
    line-height: 102%;
    letter-spacing: 0.4px;
    margin-bottom: 22px;
    text-align: left;
}
.response-sub-text {
    color: #78828D;
    font-size: 19px;
    font-weight: 300;
    line-height: 122%;
    margin-bottom: 35px;
    text-align: left;
}
.form-backto-menu {
    border-radius: 6px;
    background: #6479F8;
    text-align: center;
    padding: 10px 23px 13px 20px;
    width: fit-content;
}
.form-backto-menu a {
    color: #FFF;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    line-height: 122%;
    letter-spacing: 0.5px;
}