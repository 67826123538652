.bank-add-form .form-field-client .form-select {
    background-position: right 14px center;
}

.bank-add-form button {
    width: 100% !important;
    margin-top: 8px;
}

.hide-this {
    display: none;
}

.form-field-client-radio-outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0px;
}

.form-field-client-radio-outer .form-field-client {
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.bank-add-form {
    max-height: 70vh;
}

@media only screen and (max-width: 991px) {}

/*************Responsive Styles*****************/
/***************iPhone Portrait**************/
@media only screen and (max-width: 479px) and (min-width: 220px) {
    .ReactModal__Content.ReactModal__Content--after-open.userAccount-modal.bank-account-mod {
        max-width: 100% !important;
        width: 91% !important;
        /* transform: translate(-61%, -50%) !important; */
    }
}

/**********************iPhone Landscape*************/
@media only screen and (max-width: 767px) and (min-width: 480px) {
    .ReactModal__Content.ReactModal__Content--after-open.userAccount-modal.bank-account-mod {
        max-width: 100% !important;
        width: 91% !important;
        /* transform: translate(-61%, -35%) !important; */
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open {
        z-index: 9999;
    }

    .bank-add-form {
        max-height: 300px;
        padding-bottom: 70px;
    }
}

/*************************iPad Portrait***************/
@media only screen and (max-width: 985px) and (min-width: 768px) {
    .ReactModal__Content.ReactModal__Content--after-open.userAccount-modal.bank-account-mod {
        /* transform: translate(-61%, -35%) !important; */
    }

    .bank-add-form {
        max-height: 300px;
        padding-bottom: 70px;
    }
}

/**************iPad Landscape***************/
@media only screen and (max-width: 1024px) and (min-width: 986px) {}