@media screen and (max-width: 1600px){
    .graph-main-outer-time.graph-main-outer-time-report{
        width: 100%;
    }
    .pie-chart-right{
        width: 100%;
    }
    .outer-report-graph-piechart{
        flex-wrap: wrap;
    }
}
.custoom-icon-calender.custoom-icon-calender-all.custom-date-range .react-datepicker-popper .react-datepicker__navigation:hover *::before{
    border-color: #405BFF;
}
@media screen and (max-width: 1350px){
    .outer-report-graph-piechart{
        grid-template-columns: 1fr !important;
        grid-gap: 20px !important;
    }
    .react-outer.report-expense .pie-chart-right,
    .react-outer.report-expense .pie-chart-right-no{
        width: 100% !important;
        margin-left: 0 !important;
    }
    .react-outer.report-expense .graph-main-outer-time.graph-main-outer-time-report{
        width: 100% !important;
    }
    .pie-chart-right{
        width: 100% !important;
        margin-left: 0 !important;
    }
  
}
@media screen and (max-width: 699px){
    .content-outer.Clients.Report .repot-tatal-box-sec > div:nth-child(3),
    .content-outer.Clients.Report .repot-tatal-box-sec > div:last-child{
        display: none;
    }
}